import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccionesDialog, RangoFechasValores } from '@models/base/identificadores.model';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { IndiceAjusteContratoService } from '@services/indice-ajuste-contrato/indiceAjusteContrato.service';
import { CreateIndiceAjusteContratoRequest } from '@models/indice-ajuste-contrato/createIndiceAjusteContratoRequest.model';
import { TipoAjusteContratoDto } from '@models/tipo-ajuste-contrato/tipoAjusteContratoDto.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TipoAjusteContratoService } from '@services/tipo-ajuste-contrato/tipoAjusteContrato.service';
import { isFalsy } from 'utility-types';
import { DatePipe } from '@angular/common';
import { IndiceAjusteContratoFilter } from '@models/indice-ajuste-contrato/indiceAjusteContratoFilter.model';
import { Genericos } from '@models/base/genericos.model';
import { IndiceAjusteContratoDto } from '@models/indice-ajuste-contrato/indiceAjusteContratoDto.model';
import { DialogIndiceAjusteContratoComponent } from '@views/indices-ajuste-contrato/dialog-indice-ajuste-contrato/dialog-indice-ajuste-contrato.component';
import { DialogWebICLReferenciaComponent } from '@views/indices-ajuste-contrato/dialog-web-iclreferencia/dialog-web-iclreferencia.component';
import { UpdateIndiceAjusteContratoRequest } from '@models/indice-ajuste-contrato/updateIndiceAjusteContratoRequest.model';
import { TemplateDto } from '@models/template/templateDto.model';
import { TemplatesService } from '@services/templates/templates.service';
import { CreateTemplateRequest } from '@models/template/createTemplateRequest.model';
import { UpdateTemplateRequest } from '@models/template/updateTemplateRequest.model';
import { DialogTemplateComponent } from '../dialog-template/dialog-template.component';

@Component({
  selector: 'app-teplates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.css'],
  providers: [DatePipe],
})
export class TemplatesComponent implements OnInit {

  dataSource = new MatTableDataSource<TemplateDto>();
  obj!: undefined
  displayedColumns: string[] = [];
  data = false
  lstRangoFecha = Genericos.lstRangoFecha
  form: FormGroup;
  formBuilder = new FormBuilder
  rangoFecha: number = RangoFechasValores.Hoy // Hoy

  @ViewChild(MatTable, { static: true }) table!: MatTable<TemplateDto>;

  private paginator!: MatPaginator;
  private sort!: MatSort;
  lstTiposAjusteContrato!: TipoAjusteContratoDto[];
  indiceAjusteContratoFilter!: IndiceAjusteContratoFilter

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    if (this.paginator && this.sort) {
      this.applyFilter(null);
    }
  }

  constructor(
    public dialog: MatDialog,
    private templatesService: TemplatesService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private breakpointObserver: BreakpointObserver,
    private datepipe: DatePipe
  ) {

    this.form = this.formBuilder.group({
      FechaDesde: [new Date((new Date().getTime() - RangoFechasValores.Hoy)), Validators.compose([Validators.required,])],
      FechaHasta: [new Date(), Validators.compose([Validators.required,])],
    })

    breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.displayedColumns = result.matches ?
        ['nombre', 'accion'] :
        ['nombre', 'descripcion', 'accion']
    });
  }


  ngOnInit() {
    this.getAllTemplates()
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getAllTemplates() {
    this.spinner.show()
    this.templatesService.getAll()
      .subscribe(
        data => {
          this.spinner.hide();
          this.dataSource.data = data
          this.data = this.dataSource.data.length >= 1 ? true : false
        },
        error => {
          this.spinner.hide();
          this.snackBar.showError(error, "Error");
        }
      )
  }

  openDialog(action: any, obj: { action?: any; }) {
    obj.action = action;

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.data = obj
    dialogConfig.width = "70%"
    dialogConfig.height = "70%"

    const dialogRef = this.dialog.open(DialogTemplateComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == AccionesDialog.Agregar) {
        this.addRowData(result.data);
      } else if (result.event == AccionesDialog.Modificar) {
        this.updateRowData(result.data);
      } else if (result.event == AccionesDialog.Eliminar) {
        this.deleteRowData(result.data);
      }
    });
  }

  applyFilter(event: any) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


  filtrar() {
    this.getAllTemplates()
  }


  addRowData(row_obj: { nombre: string, descripcion: string, contenido: string }) {
    this.spinner.show()
    let createTemplateRequest = new CreateTemplateRequest
    createTemplateRequest.nombre = row_obj.nombre
    createTemplateRequest.descripcion = row_obj.descripcion
    createTemplateRequest.content = row_obj.contenido

    if (createTemplateRequest) {
      this.spinner.show()
      this.templatesService.create(createTemplateRequest)
        .subscribe(
          data => {
            if (data.id >= 0) {
              this.snackBar.showSuccess("Registro agregado correctamente.", "Exito");
            }
            this.spinner.hide()
          },
          error => {
            this.spinner.hide()
            this.snackBar.showError(error, "Error");
          }
        )
    }
    else {
      this.spinner.hide()
    }

  }
  updateRowData(row_obj: { id: number,nombre: string, descripcion: string, contenido: string }) {
    this.spinner.show()
    let updateTemplateRequest = new UpdateTemplateRequest
    updateTemplateRequest.nombre = row_obj.nombre
    updateTemplateRequest.descripcion = row_obj.descripcion
    updateTemplateRequest.content = row_obj.contenido
    updateTemplateRequest.id = row_obj.id

    if (updateTemplateRequest) {
      this.spinner.show()
      this.templatesService.update(updateTemplateRequest)
        .subscribe(
          data => {
            this.spinner.hide()
            this.snackBar.showSuccess("Registro actualizado correctamente.", "Exito");
          },
          error => {
            this.spinner.hide()
            this.snackBar.showError(error, "Error");
          })
    }
    else {
      this.spinner.hide()
    }
  }

  deleteRowData(row_obj: { id: number; }) {
    this.spinner.show()
    this.templatesService.delete(row_obj.id)
      .subscribe(
        data => {
          this.spinner.hide()
          this.snackBar.showSuccess("Registro eliminado correctamente.", "Exito");
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        })

  }


  //#region Helper

  isFalsy(valor: any) {
    return isFalsy(valor)
  }

  trackByItems(index: number, item: any): any { return item; }
  //#endregion
}
