<button mat-button [matMenuTriggerFor]="accionesContrato" *ngIf="contratoDetailDto || idContrato" ><mat-icon>menu</mat-icon> Acciones contrato</button>
<mat-menu #accionesContrato="matMenu">
    <!-- <button mat-menu-item [routerLink]="['/contratos',contratoDetailDto.id]">
        <mat-icon>visibility</mat-icon>
        <span>Ver</span>
    </button> -->
    <button mat-menu-item *appRole="['Contratos.GenerarResumen']" (click)="openDialogGenerarResumen(contratoDetailDto.id)">
        <mat-icon>picture_as_pdf</mat-icon>
        <span>Generar Resumen</span>
    </button>
    <button mat-menu-item *appRole="['Contratos.GenerarRecibo']" (click)="openDialogNuevoRecibo(contratoDetailDto.id)"
        [disabled]="contratoDetailDto.estadoContrato.id != estadosContrato.Activo">
        <mat-icon>add_circle</mat-icon>
        <span>Nuevo recibo</span>

    </button>
    <button mat-menu-item *appRole="['Contratos.GeneraLiquidacion']" (click)="openDialogNuevaLiquidacion(contratoDetailDto.id)"
        [disabled]="contratoDetailDto.estadoContrato.id != estadosContrato.Activo">
        <mat-icon>add_circle_outline</mat-icon>
        <span>Nueva liquidación</span>
    </button>
    <button mat-menu-item *appRole="['Movimientos.Crear']"
        (click)="openDialogImputacionPropietario(contratoDetailDto)"
        [disabled]="contratoDetailDto.estadoContrato.id != estadosContrato.Activo">
        <mat-icon>add_shopping_cart</mat-icon>
        <span>Imputación a propietario</span>
    </button>
    <button mat-menu-item *appRole="['Contratos.Rescindir']" (click)="openDialogRescisionContrato(contratoDetailDto.id)"
        [disabled]="contratoDetailDto.estadoContrato.id != estadosContrato.Activo">
        <mat-icon>cancel</mat-icon>
        <span>Rescisión de contrato</span>
    </button>
</mat-menu>
