import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError, retry, take } from 'rxjs/operators';
import { LoginService } from '@services/login/login.service';
import { isFalsy } from 'utility-types';

@Injectable({
  providedIn: 'root'
})
export class GlobalHttpErrorInterceptorService {
  contador500 = 0;
  constructor
    (
      private loginService: LoginService,
      private router: Router,
    ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req).pipe(
      //retry(1), //en caso de error (errores de red incluidos) repite una solicitud 1 veces
      catchError((error) => {
        switch (error.status) {
          case 400: //400 Bad Request
            error.message = "El servidor no puede o no procesará la petición debido a algo que es percibido como un error."
            break;
          case 401: //401 Unauthorized
            error.message = "Es necesario autenticar para obtener la respuesta solicitada."
            this.loginService.logout()
            this.router.navigate(['login']);
            break;
          case 403: //403 Forbidden
            this.loginService.logout()
            this.router.navigate(['login']);
            break;
          case 404: //404 Not Found
            error.message = "El servidor no pudo encontrar el contenido solicitado."
            break;
          case 500: //500 Internal Server Error
            //retry(1)
            error.message = "El servidor ha encontrado una situación que no sabe cómo manejarla."
            //this.router.navigate(['500']);
            break;
          case 503: //503 Service Unavailable
           // retry(1)
            error.message = "El servidor no está listo para manejar la petición. Causas comunes puede ser que el servidor está caído por mantenimiento o está sobrecargado."
            //this.router.navigate(['500']);
            break;
          case 504: //504 Gateway Timeout
            error.message = "Error de conexión. HTTP 504 Gateway Timeout indica que el servidor, mientras actuaba como una puerta de enlace o proxy, no pudo obtener una respuesta a tiempo"
            break;
        }
        if (error.error) {

          error.message = !isFalsy(error.error.failureReason) ? error.error.failureReason : isFalsy(error.message) ? "" : error.message

          if (isFalsy(error.message)) {
            const err = JSON.parse(error.error)
            error.message = err.failureReason
          }

          if (error.message.includes("REFERENCE 'FK"))
            error.message = "El registro no se puede eliminar debido a que contiene referencias asociadas."

          if (error.message.includes("Http failure response"))
            error.message = "Error de conexión."

          if (error.message.includes("A connection attempt failed because the connected party did not properly respond after a period of time"))
            error.message = "Un intento de conexión falló porque la parte conectada no respondió correctamente después de un período de tiempo, o la conexión establecida falló porque el host conectado no respondió."

          if (error.message.includes("Sequence contains no elements"))
            error.message = "La consulta no contiene elementos."

          if (error.message.includes("Year, Month, and Day parameters describe an un-representable DateTime."))
            error.message = "Los parámetros de año, mes y día describen una fecha y hora que no se puede representar."

          if (error.message.includes("Execution Timeout Expired.The timeout period elapsed prior to completion of the operation or the server is not responding."))
            error.message = "Tiempo de espera de ejecución caducado. El tiempo de espera transcurrido antes de completar la operación o el servidor no responde."

          if (error.message.includes("An error occurred while updating the entries. See the inner exception for details."))
            error.message = "Ocurrió un error al actualizar las entradas. Vea la excepción interna para más detalles."



        }
        else {
          if (error.message == "Unauthorized") {
            this.loginService.logout()
            this.router.navigate(['login']);
          }
          else
            error.message = "Ups... hubo un problema en el sistema."

        }
        return throwError(error.message);
      })
    )
  }
}
