import { trigger, state, style, transition, animate } from '@angular/animations';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { BreakpointObserver } from '@angular/cdk/layout';
import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ComprobantesController } from '@controllers/comprobantes.controller';
import { ArchivoTemporalDto } from '@models/archivos-temporales/archivoTemporalDto.model';
import { BaseDto } from '@models/base/baseDto.model';
import { Genericos } from '@models/base/genericos.model';
import { AccionesDialog, EstadosComprobantes, FormasPagos, RangoFechasValores, SnackbarClass, TiposComprobantes, TiposMovimiento } from '@models/base/identificadores.model';
import { ComprobanteDetailDto } from '@models/comprobantes/comprobanteDetailDto.model';
import { DetalleComprobanteDetailDto } from '@models/comprobantes/detalleComprobanteDetailDto.model';
import { ContratoDetailDto } from '@models/contrato/contratoDetailDto.model';
import { EmailDto } from '@models/email/emailDto.model';
import { ReceiverDto } from '@models/email/receiverDto.model';
import { SendEmailRequest } from '@models/email/sendEmailRequest.model';
import { InquilinoDto } from '@models/inquilino/inquilinoDto.model';
import { PagoBasicDto } from '@models/pago/pagoBasicDto.model';
import { PersonaPropietarioDto } from '@models/propietarios/personaPropietarioDto.model';
import { HotToastService } from '@ngneat/hot-toast';
import { ArchivosTemporalesService } from '@services/archivos-temporales/archivos-temporales.service';
import { ComprobantesService } from '@services/comprobantes/comprobantes.service';
import { ContratosService } from '@services/contratos/contratos.service';
import { EmailService } from '@services/email/email.service';
import { EstadosComprobanteService } from '@services/estados-comprobante/estados-comprobante.service';
import { PersonasService } from '@services/personas/personas.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { TiposComprobantesService } from '@services/tipos-comprobantes/tipos-comprobantes.service';
import { DialogDeleteComponent } from '@views/genericos/dialog-delete/dialog-delete.component';
import { DialogNuevoEmailComponent } from '@views/genericos/dialog-nuevo-email/dialog-nuevo-email.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, merge } from 'rxjs';
import { isFalsy } from 'utility-types';
import { DialogComprobanteComponent } from '../dialog-comprobante/dialog-comprobante.component';
import { VistaComprobanteDetalle } from '@models/comprobantes/vistaComprobanteDetalle.model';
import { ConceptoSimpleComprobanteDto } from '@models/comprobantes/conceptoSiempleComprobanteDto.model';
import { Helper } from '@helpers/helper';


export class VistaSimple {
  public estado!: string
  public tipoComprobante!: string
  public fechaEmision!: string
  public contrato!: number
  public numeroComprobante!: string
  public descripcion!: string
  public propiedad!: string
  public propietario!: string
  public inquilino!: string
  public fechaAcreditacion!: string
  public totalDebe: number = 0
  public totalHaber: number = 0
  public totalPago: number = 0
  public total: number = 0
  public comprobanteDetailDto!: ComprobanteDetailDto
}

@Component({
  selector: 'app-listado-comprobantes-recibos',
  templateUrl: './listado-comprobantes-recibos.component.html',
  styleUrls: ['./listado-comprobantes-recibos.component.css'],
  providers: [DatePipe],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ListadoComprobantesRecibosComponent implements OnInit {

  //Vista Detalle
  columnsToDisplay: string[] = ["vdestado", "vdfechaemision", "vdnumerocomprobante", "vdpropiedad", "vdinquilino", "vdpropietario", "vdaccion"]
  columnsToDisplayDetalle: string[] = ["vdconcepto", "vdperiodo", "vdcuenta", "vddebe", "vdhaber", "vdtotal"]
  columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  expandedElement: VistaComprobanteDetalle | null = null;
  dataSourceComprobanteDetalle = new MatTableDataSource<VistaComprobanteDetalle>();
  showDataVistaComprobanteDetalle: boolean = false;
  dataVistaComprobanteDetalle: boolean = false;

  //#region Variables Globales
  personaPropietarioDto!: PersonaPropietarioDto | null;
  propiedadBaseDto!: BaseDto | null;
  inquilinoDto!: InquilinoDto | null;
  formBuilder: FormBuilder = new FormBuilder;
  form!: FormGroup;
  data: boolean = false;
  lstRangoFecha = Genericos.lstRangoFecha
  lstTiposComprobantes!: BaseDto[];
  lstEstadosComprobante!: BaseDto[];

  rangoFecha: number = RangoFechasValores.Trimestral
  dataSource = new MatTableDataSource<ComprobanteDetailDto>();
  dataSourceVistaSimple = new MatTableDataSource<VistaSimple>();
  columns: string[] = [];
  columnDefinitions!: { def: string; label: string; hide: any; }[];
  contratoDetailDto!: ContratoDetailDto | null;
  @Input() numeroContrato!: number
  @ViewChild(MatTable, { static: true }) table!: MatTable<any>;

  private paginator!: MatPaginator;
  private sort!: MatSort;
  allRowsExpanded: boolean = false;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSourceVistaSimple.paginator = this.paginator;
    this.dataSourceVistaSimple.sort = this.sort;

    if (this.paginator && this.sort) {
      this.applyFilter(null);
    }
  }


  formColumnas: FormGroup = this.formBuilder.group({
    tipoComprobante: [false],
    estado: [false],
    numeroComprobante: [false],
    fechaEmision: [false],
    contrato: [false],
    // propiedad: [false],
    propietario: [false],
    inquilino: [false],
    totalDebe: [false],
    totalHaber: [false],
    totalPago: [false],
    total: [false],
    accion: [false]
  })
  destinatarios!: string;
  receiverDto!: ReceiverDto;

  vistaSimple: boolean = false
  vistaDetalle: boolean = true
  columnDefinitionsVistaCaja: string[] = ["vctipoComprobante", "vcestado", "vcfechaEmision", "vcnumeroComprobante", "vcdescripcion", "vcefectivo", "vccheques", "vcdepositos", "vctransferencias", "vctotal", "vcfechaAcreditacion", "vcaccion"]
  columnDefinitionsVistaCajaCompleta: string[] = ["vcctipoComprobante", "vccestado", "vccfechaEmision", "vccnumeroComprobante", "vccdescripcion", "vccimporteComprobante", "vccformaPago", "vccimportePago", "vccfechaAcreditacion", "vccaccion"]
  dataVistaCaja: boolean = false;
  dataVistaSimple: boolean = false;
  dataVistaCajaCompleta: boolean = false;
  checkedFiltroAvanzado: boolean = false




  constructor(
    private snackBarService: SnackBarService,
    private snackbar: MatSnackBar,
    private spinner: NgxSpinnerService,
    private _liveAnnouncer: LiveAnnouncer,
    public dialog: MatDialog,
    private datepipe: DatePipe,
    private comprobantesService: ComprobantesService,
    private tiposComprobantesService: TiposComprobantesService,
    private estadosComprobanteService: EstadosComprobanteService,
    private breakpointObserver: BreakpointObserver,
    private emailService: EmailService,
    private archivosTemporalesService: ArchivosTemporalesService,
    private personaService: PersonasService,
    private toastService: HotToastService,
    private contratoService: ContratosService
  ) {
    this.form = this.formBuilder.group({
      FechaDesde: [new Date((new Date().getTime() - RangoFechasValores.Bimestral)), Validators.compose([Validators.required,])],
      FechaHasta: [new Date(), Validators.compose([Validators.required,])],
      RangoFecha: [''],
      TiposComprobantes: [''],
      NumeroContrato: [''],
      EstadosComprobante: ['']
    })

    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.columnDefinitions = result.matches ?
        [
          { def: 'tipoComprobante', label: 'Tipo Comprobante', hide: this.formColumnas.controls['tipoComprobante'].value },
          { def: 'numeroComprobante', label: 'Comprobante', hide: this.formColumnas.controls['numeroComprobante'].value },
          { def: 'contrato', label: 'Contrato', hide: this.formColumnas.controls['contrato'].value },
          { def: 'accion', label: 'Acción', hide: this.formColumnas.controls['accion'].value }
        ] :
        [
          { def: 'tipoComprobante', label: 'Tipo Comprobante', hide: this.formColumnas.controls['tipoComprobante'].value },
          { def: 'estado', label: 'Estado', hide: this.formColumnas.controls['estado'].value },
          { def: 'numeroComprobante', label: 'Comprobante', hide: this.formColumnas.controls['numeroComprobante'].value },
          { def: 'fechaEmision', label: 'Fecha Emision', hide: this.formColumnas.controls['fechaEmision'].value },
          { def: 'contrato', label: 'Contrato', hide: this.formColumnas.controls['contrato'].value },
          // { def: 'propiedad', label: 'Propiedad', hide: this.formColumnas.controls['propiedad'].value },
          { def: 'propietario', label: 'Propietario', hide: this.formColumnas.controls['propietario'].value },
          { def: 'inquilino', label: 'Inquilino', hide: this.formColumnas.controls['inquilino'].value },
          { def: 'totalDebe', label: 'Total Debe.', hide: this.formColumnas.controls['totalDebe'].value },
          { def: 'totalHaber', label: 'Total Haber', hide: this.formColumnas.controls['totalHaber'].value },
          { def: 'totalPago', label: 'Total Pago', hide: this.formColumnas.controls['totalPago'].value },
          { def: 'total', label: 'Total', hide: this.formColumnas.controls['total'].value },
          { def: 'accion', label: 'Acción', hide: this.formColumnas.controls['accion'].value }
        ];
    });

  }
  //#region ng

  ngOnInit(): void {

    if (!isFalsy(this.numeroContrato)) {
      this.form.controls["NumeroContrato"].setValue(this.numeroContrato)
      this.buscarComprobantes(this.numeroContrato)
    }

    let o0: Observable<boolean> = this.formColumnas.controls['tipoComprobante'].valueChanges as Observable<boolean>;
    let o1: Observable<boolean> = this.formColumnas.controls['estado'].valueChanges as Observable<boolean>;
    let o2: Observable<boolean> = this.formColumnas.controls['numeroComprobante'].valueChanges as Observable<boolean>;
    let o3: Observable<boolean> = this.formColumnas.controls['fechaEmision'].valueChanges as Observable<boolean>;
    let o4: Observable<boolean> = this.formColumnas.controls['contrato'].valueChanges as Observable<boolean>;
    let o5: Observable<boolean> = this.formColumnas.controls['propietario'].valueChanges as Observable<boolean>;
    let o6: Observable<boolean> = this.formColumnas.controls['inquilino'].valueChanges as Observable<boolean>;
    let o7: Observable<boolean> = this.formColumnas.controls['totalDebe'].valueChanges as Observable<boolean>;
    let o8: Observable<boolean> = this.formColumnas.controls['totalHaber'].valueChanges as Observable<boolean>;
    let o9: Observable<boolean> = this.formColumnas.controls['totalPago'].valueChanges as Observable<boolean>;
    let o10: Observable<boolean> = this.formColumnas.controls['total'].valueChanges as Observable<boolean>;
    let o11: Observable<boolean> = this.formColumnas.controls['accion'].valueChanges as Observable<boolean>;

    merge(o0, o1, o2, o3, o4, o5, o6, o7, o8, o9, o10, o11).subscribe(v => {
      this.columnDefinitions[0].hide = this.formColumnas.controls['tipoComprobante'].value
      this.columnDefinitions[1].hide = this.formColumnas.controls['estado'].value
      this.columnDefinitions[2].hide = this.formColumnas.controls['numeroComprobante'].value
      this.columnDefinitions[3].hide = this.formColumnas.controls['fechaEmision'].value
      this.columnDefinitions[4].hide = this.formColumnas.controls['contrato'].value
      this.columnDefinitions[5].hide = this.formColumnas.controls['propietario'].value
      this.columnDefinitions[6].hide = this.formColumnas.controls['inquilino'].value
      this.columnDefinitions[7].hide = this.formColumnas.controls['totalDebe'].value
      this.columnDefinitions[8].hide = this.formColumnas.controls['totalHaber'].value
      this.columnDefinitions[9].hide = this.formColumnas.controls['totalPago'].value
      this.columnDefinitions[10].hide = this.formColumnas.controls['total'].value
      this.columnDefinitions[11].hide = this.formColumnas.controls['accion'].value
    });

    this.getDisplayedColumns();

  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  //#endregion

  //#region Acciones Tablas
  /** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  getDisplayedColumns() {
    this.columns = this.columnDefinitions.filter(cd => !cd.hide).map(cd => cd.def);
  }
  //#endregion

  //#region Vistas Formulario
  generaVistaSimple(comprobanteDetailDto: ComprobanteDetailDto[]) {

    var lstVistaCaja: VistaSimple[] = []
    comprobanteDetailDto.filter(x =>
      x.cabeceraComprobante.tipoComprobante.id == TiposComprobantes.Recibo)
      .forEach(comprobante => {
        var vistaSimple: VistaSimple = new VistaSimple
        vistaSimple.estado = isFalsy(comprobante.cabeceraComprobante.estadoComprobante.descripcion) ? "" : comprobante.cabeceraComprobante.estadoComprobante.descripcion
        vistaSimple.tipoComprobante = isFalsy(comprobante.cabeceraComprobante.tipoComprobante.descripcion) ? "" : comprobante.cabeceraComprobante.tipoComprobante.descripcion
        vistaSimple.fechaEmision = !isFalsy(comprobante.cabeceraComprobante.fechaEmision) ? comprobante.cabeceraComprobante.fechaEmision : ""
        vistaSimple.numeroComprobante = !isFalsy(comprobante.cabeceraComprobante.numeroComprobante) ? comprobante.cabeceraComprobante.numeroComprobante : ""
        vistaSimple.comprobanteDetailDto = comprobante
        vistaSimple.propiedad = !isFalsy(comprobante.cabeceraComprobante.propiedad) ? comprobante.cabeceraComprobante.propiedad.ubicacion : ""
        vistaSimple.contrato = isFalsy(comprobante.cabeceraComprobante.contrato) ? 0 : comprobante.cabeceraComprobante.contrato.numeroContrato

        //Detalles Comprobante
        comprobante.detalleComprobante
          .forEach(element => {
            //Movimientos
            if (!isFalsy(element.movimiento) &&
              element.movimiento.tipoMovimiento.id != TiposMovimiento.EgresoCaja &&
              element.movimiento.tipoMovimiento.id != TiposMovimiento.IngresoCaja &&
              element.movimiento.tipoMovimiento.id != TiposMovimiento.CobroParcialAlquiler &&
              element.movimiento.tipoMovimiento.id != TiposMovimiento.DeudaBonificacion) {
              if (element.movimiento.tipoMovimiento.resta == true)
                vistaSimple.totalDebe += isFalsy(element.movimiento.importe) ? 0 : element.movimiento.importe
              else
                vistaSimple.totalHaber += isFalsy(element.movimiento.importe) ? 0 : element.movimiento.importe

            }
            //DSP
            vistaSimple.totalDebe += !isFalsy(element.detalleServicioPropiedad) ? !isFalsy(element.detalleServicioPropiedad.importeATenerEnCuenta) ? element.detalleServicioPropiedad.importeATenerEnCuenta : 0 : 0

            //Periodos
            if (comprobante.cabeceraComprobante.tipoComprobante.id == TiposComprobantes.Liquidación) {
              vistaSimple.totalHaber += !isFalsy(element.periodoContrato) ? element.periodoContrato.importeAlquiler : 0
            }
            else {
              vistaSimple.totalDebe += !isFalsy(element.periodoContrato) ? element.importeTotal : 0
            }


          });
        //Pagos

        if (!isFalsy(comprobante.cabeceraComprobante.pagos))

          comprobante.cabeceraComprobante.pagos.forEach(pago => {
            vistaSimple.totalPago += pago.importe
          });


        lstVistaCaja.push(vistaSimple)
      });
    this.dataSourceVistaSimple.data = lstVistaCaja
    this.dataVistaSimple = this.dataSourceVistaSimple.data.length >= 1 ? true : false
  }

  generaVistaDetalle(comprobanteDetailDto: ComprobanteDetailDto[]) {
    var lstVistaCajaDetalle: VistaComprobanteDetalle[] = []
    this.showDataVistaComprobanteDetalle = comprobanteDetailDto.length >= 1 ? true : false

    comprobanteDetailDto.forEach(comprobante => {

      if (comprobante.cabeceraComprobante.tipoComprobante.id == TiposComprobantes.Recibo) {
        var vistaCajaDetalle: VistaComprobanteDetalle = new VistaComprobanteDetalle
        let signoSaldo = 1
        vistaCajaDetalle.id = comprobante.cabeceraComprobante.idComprobante
        vistaCajaDetalle.estado = isFalsy(comprobante.cabeceraComprobante.estadoComprobante.descripcion) ? "" : comprobante.cabeceraComprobante.estadoComprobante.descripcion
        vistaCajaDetalle.tipoComprobante = isFalsy(comprobante.cabeceraComprobante.tipoComprobante.descripcion) ? "" : comprobante.cabeceraComprobante.tipoComprobante.descripcion
        vistaCajaDetalle.fechaEmision = !isFalsy(comprobante.cabeceraComprobante.fechaEmision) ? comprobante.cabeceraComprobante.fechaEmision : ""
        vistaCajaDetalle.inquilino = isFalsy(comprobante.cabeceraComprobante.inquilino) ? "" : comprobante.cabeceraComprobante.inquilino.persona.descripcion
        vistaCajaDetalle.propietario = isFalsy(comprobante.cabeceraComprobante.propietario) ? "" : comprobante.cabeceraComprobante.propietario.persona.descripcion
        vistaCajaDetalle.numeroComprobante = !isFalsy(comprobante.cabeceraComprobante.numeroComprobante) ? comprobante.cabeceraComprobante.numeroComprobante : ""
        vistaCajaDetalle.comprobanteDetailDto = comprobante
        let ubicacion = !isFalsy(comprobante.cabeceraComprobante.propiedad) ? comprobante.cabeceraComprobante.propiedad.ubicacion : ""
        let localidad = !isFalsy(comprobante.cabeceraComprobante.propiedad) ? !isFalsy(comprobante.cabeceraComprobante.propiedad.localidad) ? "(" + comprobante.cabeceraComprobante.propiedad.localidad.descripcion + ")" : "" : ""
        vistaCajaDetalle.importeComprobante = Math.round(this.getTotalDetalleComprobante(comprobante.detalleComprobante) * 100) / 100

        vistaCajaDetalle.propiedad = isFalsy(ubicacion) ? "Sin Propiedad" : ubicacion + " " + localidad

        let conceptos: ConceptoSimpleComprobanteDto[] = []
        comprobante.detalleComprobante.forEach(element => {
          let concepto = new ConceptoSimpleComprobanteDto
          if (!isFalsy(element.movimiento) &&
            element.movimiento.tipoMovimiento.id != TiposMovimiento.EgresoCaja &&
            element.movimiento.tipoMovimiento.id != TiposMovimiento.IngresoCaja &&
            element.movimiento.tipoMovimiento.id != TiposMovimiento.CobroParcialAlquiler &&
            element.movimiento.tipoMovimiento.id != TiposMovimiento.DeudaBonificacion) {
            if (element.movimiento.anulado)
              concepto.descripcion = element.movimiento.tipoMovimiento.descripcion + " - Anulado"
            else
              concepto.descripcion = element.movimiento.tipoMovimiento.descripcion

            if (element.movimiento.tipoMovimiento.id === TiposMovimiento.Bonificaciones ||
              element.movimiento.tipoMovimiento.id === TiposMovimiento.SaldoAFavor)
              concepto.haber = Math.round(element.movimiento.importe * 100) / 100
            else
              concepto.debe = Math.round(element.movimiento.importe * 100) / 100

            concepto.periodo = element.movimiento.periodoMes + "/" + element.movimiento.periodoAnio + "-" + element.movimiento.periodo
            conceptos.push(concepto)
          }
          if (!isFalsy(element.periodoContrato)) {
            concepto.descripcion = "Alquiler"
            concepto.debe = Math.round(element.periodoContrato.importeAlquiler * 100) / 100
            concepto.periodo = element.periodoContrato.periodoMes + "/" + element.periodoContrato.periodoAnio + "-" + element.periodoContrato.periodo
            conceptos.push(concepto)
          }
          if (!isFalsy(element.detalleServicioPropiedad)) {
            concepto.descripcion = element.detalleServicioPropiedad.tipoServicio
            concepto.debe = Math.round(element.detalleServicioPropiedad.importeATenerEnCuenta * 100) / 100
            concepto.periodo = element.detalleServicioPropiedad.periodoMes + "/" + element.detalleServicioPropiedad.periodoAnio + "-" + element.detalleServicioPropiedad.periodo
            conceptos.push(concepto)
          }
        });

        if (!isFalsy(comprobante.cabeceraComprobante.pagos)) {
          comprobante.cabeceraComprobante.pagos.forEach(pago => {
            let concepto = new ConceptoSimpleComprobanteDto
            concepto.haber = !isFalsy(pago.importe) ? (Math.round(pago.importe * 100) / 100) * signoSaldo : 0
            concepto.descripcion = !isFalsy(pago.formaPago) ? pago.formaPago.descripcion : ""
            if (!isFalsy(pago.fechaAcreditacion))
              concepto.descripcion = concepto.descripcion + "  - Ref: Fecha Acred.: " + Helper.formatDateDDMMYYYY(new Date(pago.fechaAcreditacion))
            conceptos.push(concepto)
          });
        }
        vistaCajaDetalle.conceptos = conceptos

        lstVistaCajaDetalle.push(vistaCajaDetalle)
      }



    });
    this.dataSourceComprobanteDetalle.data = lstVistaCajaDetalle.sort((b, a) => a.id - b.id)
    this.dataVistaComprobanteDetalle = this.dataSourceComprobanteDetalle.data.length >= 1 ? true : false

  }

  //#endregion

  //#region Filtros
  applyFilter(event: any) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  filtrosAvanzados(event: any) {
    if (event.checked == true)
      this.checkedFiltroAvanzado = true
    else
      this.checkedFiltroAvanzado = false

    this.getAllTiposComprobantes();
    this.getAllEstadosComprobante();

  }

  selectRangoFecha(event: any) {
    this.rangoFecha = event.value
    if (this.rangoFecha == RangoFechasValores.Todos) {
      this.form.controls["FechaDesde"].setValue(new Date((new Date().getTime() - this.rangoFecha)))
      this.form.controls["FechaHasta"].setValue(new Date((new Date().getTime() + this.rangoFecha)))
    }
    else {
      this.form.controls["FechaDesde"].setValue(new Date((new Date().getTime() - this.rangoFecha)))
      this.form.controls["FechaHasta"].setValue(new Date((new Date().getTime())))
    }
  }

  selectPropietario(personaPropietarioDto: PersonaPropietarioDto) {
    this.personaPropietarioDto = personaPropietarioDto
  }

  selectContrato(baseDto: BaseDto) {
    if (isFalsy(baseDto))
      this.contratoDetailDto = null
    else
      this.getContrato(baseDto.id)
  }

  selectPropiedad(propiedadBaseDto: BaseDto) {
    this.propiedadBaseDto = propiedadBaseDto
  }

  selectInquilino(inquilinoDto: InquilinoDto) {
    this.inquilinoDto = inquilinoDto
  }

  //#endregion

  //#region Calculos tabla

  getTotalPagos(item: PagoBasicDto[]): number {
    let totalPagos = item.reduce((sum, element) => (Math.round(sum * 100) / 100) + (Math.round(element.importe * 100) / 100), 0);
    return (Math.round(totalPagos * 100) / 100)
  }

  getTotalDetalleComprobante(item: DetalleComprobanteDetailDto[]): number {
    let totalComprobante = item.reduce((sum, element) => sum + element.importeTotal, 0);
    return totalComprobante
  }
  //#endregion

  //#region  Peticiones Back-end
  cancelVoucher(id: number) {
    this.spinner.show()
    this.comprobantesService.cancelVoucher(id)
      .subscribe(
        data => {
          this.spinner.hide()
          this.dataSource.data = this.dataSource.data.filter((value: { id: number; }, key: any) => {
            return value.id != id;
          });
          this.data = this.dataSource.data.length >= 1 ? true : false
          if (isFalsy(data)) {
            this.snackBarService.showSuccess("Comprobante anulado correctamente.", "Exito");
            this.buscarComprobantes(this.numeroContrato)
          }

          else {
            this.spinner.hide()
            this.snackBarService.showTemplate(data.cancelVoucherError.descripcion, "/comprobante/" + data.cancelVoucherError.idComprobante, "", "Error", 0, SnackbarClass.error);

          }

        },
        error => {
          this.spinner.hide()
          this.snackBarService.showError(error, "Error");
        })
  }

  buscarComprobantes(nroContrato: number) {
    let numeroContrato = null
    //Fijos
    let fechaDesde = this.datepipe.transform(this.form.controls["FechaDesde"].value, 'yyyy-MM-dd') as string;
    let fechaHasta = this.datepipe.transform(this.form.controls["FechaHasta"].value, 'yyyy-MM-dd') as string;

    // Ajustar la hora para la fecha desde
    fechaDesde = fechaDesde + ' 00:00:00';

    // Ajustar la hora para la fecha hasta
    fechaHasta = fechaHasta + ' 23:59:59';

    //Variables
    let contratoId = isFalsy(this.contratoDetailDto) ? null : this.contratoDetailDto.id
    let idInquilino = isFalsy(this.inquilinoDto) ? null : this.inquilinoDto.id
    let idPropiedad = isFalsy(this.propiedadBaseDto) ? null : this.propiedadBaseDto.id
    let idPropietario = isFalsy(this.personaPropietarioDto) ? null : this.personaPropietarioDto.id_Propietario

    if (!isFalsy(nroContrato))
      numeroContrato = nroContrato
    else
      numeroContrato = isFalsy(this.contratoDetailDto) ? null : this.contratoDetailDto.numeroContrato

    let idTipoComprobante = this.form.controls["TiposComprobantes"].value
    let idEstadoComprobante = this.form.controls["EstadosComprobante"].value

    this.spinner.show("spBusquedaComprobantes")
    this.comprobantesService.getAllByFilter(fechaDesde, fechaHasta, idTipoComprobante, idEstadoComprobante, numeroContrato, idPropiedad, idInquilino, idPropietario)
      .subscribe(
        data => {
          this.spinner.hide("spBusquedaComprobantes");
          this.dataSource.data = data.sort((b, a) => a.cabeceraComprobante.numeroComprobante.toString().localeCompare(b.cabeceraComprobante.numeroComprobante.toString()))
          this.data = this.dataSource.data.length >= 1 ? true : false
          this.generaVistaSimple(data)
          this.generaVistaDetalle(data);
        },
        error => {
          this.spinner.hide("spBusquedaComprobantes");
          this.snackBarService.showError(error, "Error");
        }
      )
  }

  getContrato(id: number) {
    this.spinner.show("spMovimiento")
    this.contratoService.getById(id)
      .subscribe(
        data => {
          this.spinner.hide("spMovimiento")
          this.contratoDetailDto = data
        },
        error => {
          this.spinner.hide("spMovimiento")
          this.snackBarService.showError(error, "Error");
        })
  }

  getArchivoTemporalByIdComprobante(comprobante: ComprobanteDetailDto, enviaEmail: boolean, download: boolean) {

    const toastId = this.toastService.loading('Por favor, aguarde', {
      icon: '⏳',  // Puedes usar un emoji o un ícono de librería
      autoClose: false  // Mantener el loading hasta que se actualice manualmente
    });

    if (comprobante)
      this.archivosTemporalesService.getAllByIdComprobante(comprobante.cabeceraComprobante.idComprobante).
        subscribe(
          async data => {
            if (data.length >= 1)
              await data.forEach(element => {
                if (enviaEmail)
                  this.getFileContentById(element, true, false, comprobante)
                else if (download)
                  this.getFileContentById(element, false, true, comprobante)
                else {
                  this.toastService.error("No existe archivo de comprobante.")
                  toastId.close()
                }

              });
            else
              this.toastService.error("No existe archivo de comprobante.")
            toastId.close()
          },
          error => {
            this.toastService.error("Error en el proceso.")
            toastId.close()
          }
        )
  }



  public toggle() {
    this.allRowsExpanded = !this.allRowsExpanded;
    this.expandedElement = null;
  }

  //#region Calculos tabla

  getTotalVistaDetalle(item: DetalleComprobanteDetailDto[]): number {
    let total = item.reduce((sum, element) => (Math.round(sum * 100) / 100) + (Math.round(element.importeTotal * 100) / 100), 0);
    return Math.round(total * 100) / 100
  }
  getTotalDebeDetalle(item: ConceptoSimpleComprobanteDto[]): number {
    let total = item.reduce((sum, element) => (Math.round(sum * 100) / 100) + (Math.round(element.debe * 100) / 100), 0);
    return Math.round(total * 100) / 100
  }

  getTotalHaberDetalle(item: ConceptoSimpleComprobanteDto[]): number {
    let total = item.reduce((sum, element) => (Math.round(sum * 100) / 100) + (Math.round(element.haber * 100) / 100), 0);
    return Math.round(total * 100) / 100
  }

  getTotalDetalle(item: ConceptoSimpleComprobanteDto[]): number {
    let totalDebe = item.reduce((sum, element) => (Math.round(sum * 100) / 100) + (Math.round(element.debe * 100) / 100), 0);
    let totalHaber = item.reduce((sum, element) => (Math.round(sum * 100) / 100) + (Math.round(element.haber * 100) / 100), 0);
    let total = totalHaber - totalDebe
    return Math.round(total * 100) / 100
  }
  //#endregion

  //#region Calculos tabla

  // getTotalDetalleComprobanteLiquidacion(item: DetalleComprobanteDetailDto[]): number {
  //   let total = item.reduce((sum, element) => sum + element.importeTotal, 0);
  //   return total
  // }
  getTotalDebe(item: ConceptoSimpleComprobanteDto[]): number {
    let total = item.reduce((sum, element) => (Math.round(sum * 100) / 100) + (Math.round(element.debe * 100) / 100), 0);
    return Math.round(total * 100) / 100
  }

  getTotalHaber(item: ConceptoSimpleComprobanteDto[]): number {
    let total = item.reduce((sum, element) => (Math.round(sum * 100) / 100) + (Math.round(element.haber * 100) / 100), 0);
    return Math.round(total * 100) / 100
  }

  getTotalLiquidacionConcepto(item: ConceptoSimpleComprobanteDto[]): number {
    let totalDebe = item.reduce((sum, element) => (Math.round(sum * 100) / 100) + (Math.round(element.debe * 100) / 100), 0);
    let totalHaber = item.reduce((sum, element) => (Math.round(sum * 100) / 100) + (Math.round(element.haber * 100) / 100), 0);
    let total = totalHaber - totalDebe
    return Math.round(total * 100) / 100
  }
  //#endregion

  sendEmail(sendEmailRequest: SendEmailRequest) {
    if (sendEmailRequest) {
      const toastId = this.toastService.loading('Aguarde por favor, enviando correo.', {
        icon: '⏳',  // Puedes usar un emoji o un ícono de librería
        autoClose: false  // Mantener el loading hasta que se actualice manualmente
      });
      this.emailService.send(sendEmailRequest).subscribe(
        data => {
          this.toastService.show('Correo enviado con éxito.')
          toastId.close()
          //this.snackBarService.showSuccess("Correo enviado correctamente.", "Exito");
        },
        error => {
          this.toastService.error('Error al enviar correo.')
          toastId.close()
        }
      )
    }

  }

  getFileContentById(archivoTemporalDto: ArchivoTemporalDto, enviaEmail: boolean, download: boolean, comprobante: ComprobanteDetailDto): string {
    let contenido = ""

    const toastId = this.toastService.loading('Por favor, aguarde', {
      icon: '⏳',  // Puedes usar un emoji o un ícono de librería
      autoClose: false  // Mantener el loading hasta que se actualice manualmente
    });


    this.archivosTemporalesService.getFileContentById(archivoTemporalDto.id)
      .subscribe
      (
        async data => {
          this.toastService.success('Proceso finalizado con éxito.');
          toastId.close()
          if (!isFalsy(data.content)) {
            archivoTemporalDto.contenidoArchivo = data.content
            if (download)
              await ComprobantesController.generarDocumentoPdf(archivoTemporalDto)
            if (enviaEmail) {
              await this.createEmail(archivoTemporalDto, comprobante)
            }

          }
        },
        error => {
          this.toastService.error('Error al obtener el archivo temporal.');
          toastId.close()
        }
      )
    return contenido
  }

  getAllTiposComprobantes() {
    this.spinner.show("spBusquedaTiposComprobantes")
    this.tiposComprobantesService.getAll()
      .subscribe(
        async data => {
          this.spinner.hide("spBusquedaTiposComprobantes")
          this.lstTiposComprobantes = data
        },
        error => {
          this.spinner.hide("spBusquedaTiposComprobantes")
          this.snackBarService.showError(error, "Error");
        }
      )
  }

  getAllEstadosComprobante() {
    this.spinner.show("spBusquedaEstadosComprobante")
    this.estadosComprobanteService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spBusquedaEstadosComprobante")
          this.lstEstadosComprobante = data
        },
        error => {
          this.spinner.hide("spBusquedaEstadosComprobante")
          this.snackBarService.showError(error, "Error");
        }
      )
  }

  //#endregion

  //#region Acciones

  descargarComprobante(element: ComprobanteDetailDto) {
    this.getArchivoTemporalByIdComprobante(element, false, true)
  }

  createEmail(archivotemporal: ArchivoTemporalDto, comprobante: ComprobanteDetailDto) {
    var sendEmailRequest: SendEmailRequest = new SendEmailRequest
    let nroComprobante = isFalsy(comprobante.cabeceraComprobante.numeroComprobante) ? "" : "Comprobante nro. " + comprobante.cabeceraComprobante.numeroComprobante
    let propiedad = isFalsy(comprobante.cabeceraComprobante.propiedad) ? "" : isFalsy(comprobante.cabeceraComprobante.propiedad.ubicacion) ? "" : " - Propiedad: " + comprobante.cabeceraComprobante.propiedad.ubicacion.toUpperCase()

    //Asunto
    sendEmailRequest.asunto = nroComprobante + propiedad
    //Body
    sendEmailRequest.body = ""

    //Destinatarios
    var destinatarios: ReceiverDto[] = []

    destinatarios.push(this.receiverDto);

    sendEmailRequest.destinatarios = destinatarios;

    //Adjuntos
    var listaArchivos: EmailDto[] = []
    var archivo = new EmailDto()
    archivo.fileContent = archivotemporal.contenidoArchivo
    archivo.extension = ".pdf"
    archivo.fileName = archivotemporal.nombreArchivo + archivo.extension
    listaArchivos.push(archivo)
    sendEmailRequest.files = listaArchivos

    if (sendEmailRequest)
      this.sendEmail(sendEmailRequest)
  }


  clearForm() {
    this.form.controls["EstadosComprobante"].setValue(0)
    this.form.controls["NumeroContrato"].setValue("")
    this.form.controls["TiposComprobantes"].setValue(0)
    this.inquilinoDto = null
    this.propiedadBaseDto = null
    this.personaPropietarioDto = null
    this.contratoDetailDto = new ContratoDetailDto

  }

  //#endregion

  //#region Dialogs

  openDialogEnviarCorreoComprobante(comprobante: ComprobanteDetailDto) {

    this.receiverDto = new ReceiverDto()

    if (comprobante.cabeceraComprobante.tipoComprobante.id == TiposComprobantes.Recibo) {
      if (comprobante.cabeceraComprobante.inquilino != null) {
        this.receiverDto.receiverAddress = isFalsy(comprobante.cabeceraComprobante.inquilino.persona.correo) ? "" : comprobante.cabeceraComprobante.inquilino.persona.correo
        this.receiverDto.receiverName = isFalsy(comprobante.cabeceraComprobante.inquilino.persona.nombreCompleto) ? "" : comprobante.cabeceraComprobante.inquilino.persona.nombreCompleto
      }
      else {
        this.snackBarService.showInfo("No existe inquilino vinculado al comprobante.", "Info");
      }

    }

    if (comprobante.cabeceraComprobante.tipoComprobante.id == TiposComprobantes.Liquidación) {
      if (comprobante.cabeceraComprobante.propietario != null) {
        this.receiverDto.receiverAddress = isFalsy(comprobante.cabeceraComprobante.propietario.persona.correo) ? "" : comprobante.cabeceraComprobante.propietario.persona.correo
        this.receiverDto.receiverName = isFalsy(comprobante.cabeceraComprobante.propietario.persona.nombreCompleto) ? "" : comprobante.cabeceraComprobante.propietario.persona.nombreCompleto
      }
      else {
        this.snackBarService.showInfo("No existe propietario vinculado al comprobante.", "Info");
      }

    }


    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = "30%"
    dialogConfig.maxWidth = "25%"
    dialogConfig.data = this.receiverDto


    const dialogRef = this.dialog.open(DialogNuevoEmailComponent,
      dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.event != AccionesDialog.Cancelar) {
        if (result.data != null) {
          this.receiverDto.receiverAddress = result.data
          this.receiverDto.receiverName = result.data
          this.getArchivoTemporalByIdComprobante(comprobante, true, false)
        }
      }

    });
  }


  openDialog(element: ComprobanteDetailDto) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = "100%"
    dialogConfig.data = element as ComprobanteDetailDto

    const dialogRef = this.dialog.open(DialogComprobanteComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  openDialogCancelVoucher(element: ComprobanteDetailDto) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = "30%"
    dialogConfig.data = { descripcion: element.cabeceraComprobante.numeroComprobante, id: element.cabeceraComprobante.idComprobante }

    const dialogRef = this.dialog.open(DialogDeleteComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == AccionesDialog.Eliminar)
        this.cancelVoucher(result.data)
    });
  }

  //#endregion

  //#region Helper


  isFalsy(valor: any) {
    return isFalsy(valor)
  }

  trackByItems(index: number, item: any): any { return item; }
  //#endregion


}

