<div class="row" *appRole="['Propietarios.Ver']">
    <div class="form-group col-md-5">
        <app-buscar-persona-propietaria (eventEntity)="selectPropietario($event)"></app-buscar-persona-propietaria>
    </div>
    <div *appRole="['Propietarios.Crear']" class="form-group col-md-2">
        <button mat-raised-button color="primary" [disabled]="!agregaPropietario" (click)="agregarPropietario()">
        <mat-icon>add</mat-icon>
        Agregar propietario
    </button>
    </div>
    <div *appRole="['Propietarios.Crear']" class="form-group col-md-2">
        <button mat-raised-button (click)="openDialogNuevoPropietario()">
        <mat-icon>add</mat-icon>
        Nuevo  propietario
      </button>
    </div>
</div>
<div class="row" *appRole="['Propietarios.Ver']">
    <div class="col-lg-12">
        <ngx-spinner name="spAddPropietarioPropiedad" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false"></ngx-spinner>

        <table mat-table [dataSource]="dataSource" [hidden]=!data matSort matSortStart="asc" class="mat-elevation-z8">
            <!-- <ng-container matColumnDef="id">
                <mat-header-cell mat-header-cell *matHeaderCellDef> # </mat-header-cell>
                <mat-cell mat-cell *matCellDef="let propietario"> {{propietario.id}} </mat-cell>
            </ng-container> -->
            <ng-container matColumnDef="nombreCompleto">
                <mat-header-cell mat-header-cell *matHeaderCellDef> Nombre Completo </mat-header-cell>
                <mat-cell mat-cell *matCellDef="let propietario"> <span>{{propietario.persona.descripcion}}

                </span>
                    <button mat-icon-button matTooltip="Editar persona" *ngIf="propietario.persona.descripcion != ''" (click)="editarPersona(propietario.persona.id)" color="primary">
                  <mat-icon matListIcon >rate_review</mat-icon>
                  </button>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="comision">
                <mat-header-cell mat-header-cell *matHeaderCellDef> Comision </mat-header-cell>
                <mat-cell mat-cell *matCellDef="let propietario"> {{propietario.comision}} % </mat-cell>
            </ng-container>
            <ng-container matColumnDef="titular">
                <mat-header-cell mat-header-cell *matHeaderCellDef> Titular </mat-header-cell>
                <mat-cell mat-cell *matCellDef="let propietario">
                    <mat-icon color="primary" *ngIf="propietario.titular == true">done</mat-icon>
                    <mat-icon color="primary" *ngIf="propietario.titular == false">close</mat-icon>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="accion">
                <mat-header-cell mat-header-cell *matHeaderCellDef> Acción </mat-header-cell>
                <mat-cell mat-cell *matCellDef="let propietario">
                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="removeAssignmentPropiedadPropietario(propietario)">
                          <mat-icon>close</mat-icon>Desvincular propietario
                      </button>
                        <button mat-menu-item (click)="assignmentPropietarioTitular(propietario)">
                        <mat-icon>check</mat-icon>Asignar como titular
                    </button>
                    </mat-menu>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </table>
        <div class="no-data-table">
            <span class="with-icon" *ngIf="dataSource.data.length == 0"><mat-icon>search_off</mat-icon>No se encontraron datos para mostrar.</span>
        </div>
        <mat-paginator showFirstLastButtons [pageSize]="10" [hidden]=!data [pageSizeOptions]="[5, 10, 20,50,100,200]">
        </mat-paginator>
    </div>
</div>
