// src/app/error.service.ts
import * as Rollbar from 'rollbar';
import {
  Injectable,
  Inject,
  InjectionToken,
  ErrorHandler
} from '@angular/core';

import { environment } from 'src/environments/environment';
import { LoginService } from '@services/login/login.service';

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable()
export class RollbarErrorHandlerService implements ErrorHandler {

  constructor(
    @Inject(RollbarService) private rollbar: Rollbar,
    private loginService: LoginService
  ) {
  }
  handleError(err: any): void {
    var entorno = `${environment.entorno}`;
    var userSession = this.loginService.getCurrentUser()
    if (entorno != "Desarrollo") {
      this.rollbar.configure({
        payload: {
          usuario: userSession.name,
          idPersona: userSession.persona.id,
          empresa: userSession.empresa,
          rol: userSession.rol.id,
        }
      })
      this.rollbar.configure({
        payload: {
          code_version: '1.0.0',
          custom_data: '12/08/2022'
        }
      });
      this.rollbar.error(err.originalError || err);
    }

  }


}
export function rollbarFactory() {

  var rollbar = new Rollbar();

  // Token
  rollbar.configure({ accessToken: '98974e1779eb45b39fed2296c26f2178', autoInstrument: true });

  // Telemetria
  rollbar.configure({ autoInstrument: true });

  // Capture uncaught exceptions and unhandled rejections
  rollbar.configure({ captureUncaught: true, captureUnhandledRejections: true });

  // Send the config
  rollbar.configure({ sendConfig: true });

  // Anonymize IP addresses
  rollbar.configure({ captureIp: true });

  // Set the environment, default log level and the context
  var entorno = `${environment.entorno}`;
  if (entorno == "Desarrollo")
    rollbar.configure({ payload: { environment: 'develop' } });
  if (entorno == "Testing")
    rollbar.configure({ payload: { environment: 'staging' } });
  if (entorno == "Produccion")
    rollbar.configure({ payload: { environment: 'production' } });


  return rollbar;
  //return new Rollbar(rollbarConfig);
}
