<ngx-spinner bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false"></ngx-spinner>

<mat-accordion class="example-headers-align" multi>

    <mat-expansion-panel [disabled]="false" [expanded]='true' [hideToggle]="false">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <b>Datos servicio</b>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
            <form [formGroup]="servicioForm">
                <div class="row">
                    <div class="form-group col-md-3">
                        <mat-form-field class="example-full-width">
                            <ngx-spinner [zIndex]=100 name="spBusqueda" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
                            <mat-label>Tipo Servicios</mat-label>
                            <mat-select required placeholder="Seleccione opcion" formControlName="TiposServicio">
                                <mat-option [value]="tipoServicio.id" *ngFor="let tipoServicio of lstTiposServicio ;let i=index;trackBy:trackByItems">
                                    {{tipoServicio.descripcion}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="f.TiposServicio.errors?.required">Este campo es <strong>requerido</strong>.
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="form-group col-md-3">
                        <section class="example-section">
                            <mat-checkbox (change)="habilitaNroBroche($event.checked)" labelPosition="before" formControlName="Broche">Broche</mat-checkbox>
                        </section>
                    </div>

                    <mat-form-field class="example-full-width">
                        <mat-label>Nro. Broche</mat-label>
                        <input matInput type="text" maxlength="50" [disabled]="disableNroBroche" formControlName="NroBroche" />
                    </mat-form-field>

                </div>
            </form>
            <br>
            <div class="col-auto my-1">
                <button mat-raised-button color="primary" style="margin-right:3px;" name="Agregar" mat-dialog-close [disabled]="!servicioForm.valid"  *appRole="['Servicios.Crear']" (click)="onSubmit()">Agregar</button>
                <button mat-button color="warn" style="margin-right:3px;" name="Cancelar" (click)="goBack()" mat-dialog-close>Cancelar</button>
            </div>
        </ng-template>
    </mat-expansion-panel>
</mat-accordion>
