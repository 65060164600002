<div class="position-relative">
  <button mat-icon-button class="close-button" [mat-dialog-close]="true">
    <mat-icon class="close-icon" color="warn">close</mat-icon>
  </button>
  <div class="row">
      <div class="col-lg-11">
          <h1 mat-dialog-title>
              <strong>Nuevo</strong> movimiento de caja</h1>
      </div>
  </div>
  <div mat-dialog-content cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
    <div class="row">
      <div class="col-lg-7">
          <mat-card>
              <mat-card-subtitle>Movimientos</mat-card-subtitle>
              <mat-card-subtitle>
                  <button mat-raised-button (click)="openDialogNuevoMovimiento()" color="primary">
                <mat-icon>add</mat-icon>
                Nuevo movimiento
              </button>
              </mat-card-subtitle>
              <mat-card-content>
                  <table mat-table class="example-table" [hidden]=!dataIngresoEgreso [dataSource]="dataSourceIngresoEgreso">
                      <!-- Periodo Column -->
                      <ng-container matColumnDef="periodo">
                          <th mat-header-cell *matHeaderCellDef> Periodo </th>
                          <td mat-cell *matCellDef="let element"> {{element.periodoMes}}/ {{element.periodoAnio}}- {{element.periodo}}</td>
                      </ng-container>

                      <!-- Tipo Movimiento Column -->
                      <ng-container matColumnDef="tipoMovimiento">
                          <th mat-header-cell *matHeaderCellDef> Tipo Movimiento </th>
                          <td mat-cell *matCellDef="let element"> {{element.tipoMovimiento != undefined ? element.tipoMovimiento.descripcion : "" | titlecase}} </td>
                      </ng-container>

                      <!-- Importe Column -->
                      <ng-container matColumnDef="importe">
                          <th mat-header-cell *matHeaderCellDef> Importe </th>
                          <td mat-cell *matCellDef="let element"> {{element.importe | currency}} </td>
                      </ng-container>

                      <!-- Descripcion Column -->
                      <ng-container matColumnDef="descripcion">
                          <th mat-header-cell *matHeaderCellDef> Descripción </th>
                          <td mat-cell *matCellDef="let element">
                              <span [matTooltip]="element.descripcion">{{element.descripcion}}</span>
                          </td>
                      </ng-container>

                      <!-- Accion Column -->
                      <ng-container matColumnDef="accion">
                          <th mat-header-cell *matHeaderCellDef> Acción</th>
                          <td mat-cell *matCellDef="let element">
                              <button mat-icon-button color="primary" (click)="eliminarMovimiento(element)" matTooltip="Eliminar" matTooltipPosition="right">
                          <mat-icon >delete</mat-icon>
                        </button>
                          </td>
                      </ng-container>


                      <tr mat-header-row *matHeaderRowDef="displayedColumnsIngresoEgreso; sticky: true"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumnsIngresoEgreso;" (click)="selectionIngresoEgreso.toggle(row)">
                      </tr>
                  </table>

                  <div class="no-data-table">
                      <span class="with-icon" *ngIf="dataSourceIngresoEgreso.data.length == 0"><mat-icon>search_off</mat-icon>No se encontraron datos para mostrar.</span>
                  </div>
              </mat-card-content>
          </mat-card>
      </div>
      <div class="col-lg-5">
          <!-- Pagos -->
          <mat-card>
              <mat-card-subtitle>Pagos</mat-card-subtitle>
              <mat-card-subtitle>
                  <button mat-raised-button (click)="agregarPago()" color="primary">
                  <mat-icon>add</mat-icon>
                  Nuevo pago
                </button>
              </mat-card-subtitle>
              <mat-card-content>

                  <table mat-table [dataSource]="dataSourcePagos" [hidden]=!dataPagos>
                      <!-- Forma de Pago Column -->
                      <ng-container matColumnDef="formaPago">
                          <th mat-header-cell *matHeaderCellDef> Forma de Pago </th>
                          <td mat-cell *matCellDef="let pago"> {{pago.formaPago.descripcion}} </td>
                          <td mat-footer-cell *matFooterCellDef> Total </td>
                      </ng-container>

                      <!-- Fecha Column -->
                      <ng-container matColumnDef="fecha">
                          <th mat-header-cell *matHeaderCellDef> Fecha </th>
                          <td mat-cell *matCellDef="let pago"> {{pago.fechaPago | date:('dd/MM/yyyy')}} </td>
                          <td mat-footer-cell *matFooterCellDef> </td>
                      </ng-container>

                      <!-- Importe Column -->
                      <ng-container matColumnDef="importe">
                          <th mat-header-cell *matHeaderCellDef> Importe </th>
                          <td mat-cell *matCellDef="let pago"> {{pago.importe | currency}} </td>
                          <td mat-footer-cell *matFooterCellDef> {{totalPagos | currency}} </td>
                      </ng-container>

                      <!-- Importe Column -->
                      <ng-container matColumnDef="detalle">
                          <th mat-header-cell *matHeaderCellDef> Detalle </th>
                          <td mat-cell *matCellDef="let pago">
                              <mat-icon matTooltip='Fecha Vencimiento: {{pago.fechaVencimiento | date}}&#13;Descripcion: {{pago.descripcion}}'>info</mat-icon>
                          </td>
                          <td mat-footer-cell *matFooterCellDef> </td>
                      </ng-container>

                      <!-- Action Column -->
                      <ng-container matColumnDef="accion">
                          <th mat-header-cell *matHeaderCellDef> Acción</th>
                          <td mat-cell *matCellDef="let pago" class="action-link">
                              <button mat-icon-button color="primary" (click)="eliminarPago(pago)" aria-label="Example icon-button with a menu" matTooltip="Eliminar">
          <mat-icon>delete</mat-icon>
          </button>
                          </td>
                          <td mat-footer-cell *matFooterCellDef> </td>
                      </ng-container>

                      <tr mat-header-row *matHeaderRowDef="displayedColumnsPagos" class="example-first-header-row">
                      </tr>

                      <tr mat-row *matRowDef="let row; columns: displayedColumnsPagos;"></tr>
                      <tr mat-footer-row *matFooterRowDef="displayedColumnsPagos" class="example-first-footer-row"></tr> -->
                  </table>

                  <div class="no-data-table">
                      <span class="with-icon" *ngIf="dataSourcePagos.data.length == 0"><mat-icon>search_off</mat-icon>No se encontraron datos para mostrar.</span>
                  </div>
              </mat-card-content>
          </mat-card>
      </div>
  </div>
  </div>

  <mat-divider></mat-divider>
  <div mat-dialog-actions align="end">
      <button mat-stroked-button *appRole="['Movimientos.Crear']" (click)="agregar()" style="text-align: left;" [disabled]="showProgressBar"  color="primary">
      <mat-icon> description </mat-icon>
      <span *ngIf="showProgressBar" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      Agregar movimiento</button>
      <button mat-button style="float: right;" mat-dialog-close color="warn">Cancelar</button>
  </div>
</div>
