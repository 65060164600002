import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AccionesDialog, EstadosContrato } from '@models/base/identificadores.model';
import { ContratoDetailDto } from '@models/contrato/contratoDetailDto.model';
import { ContratoDto } from '@models/contrato/contratoDto.model';
import { ResindirContratoRequest } from '@models/contrato/resindirContratoRequest.model';
import { ContratosService } from '@services/contratos/contratos.service';
import { PersonasService } from '@services/personas/personas.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { DialogImputacionPropietarioComponent } from '@views/propietarios/dialog-imputacion-propietario/dialog-imputacion-propietario.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { isFalsy } from 'utility-types';
import { DialogGenerarResumenComponent } from '../form-editar-contrato/dialog-generar-resumen/dialog-generar-resumen.component';
import { DialogNuevaLiquidacionComponent } from '../form-editar-contrato/dialog-nueva-liquidacion/dialog-nueva-liquidacion.component';
import { DialogRescisionContratoComponent } from '../form-editar-contrato/dialog-rescision-contrato/dialog-rescision-contrato.component';
import { DialogNuevoReciboComponent } from '../dialog-nuevo-recibo/dialog-nuevo-recibo/dialog-nuevo-recibo.component';

@Component({
  selector: 'app-acciones-contrato',
  templateUrl: './acciones-contrato.component.html',
  styleUrls: ['./acciones-contrato.component.css']
})
export class AccionesContratoComponent implements OnInit {
  @Input() contratoDetailDto!: ContratoDetailDto
  @Input() idContrato!: number
  estadosContrato!: typeof EstadosContrato;

  constructor(
    private dialog: MatDialog,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private contratosService: ContratosService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.estadosContrato = EstadosContrato
    if (isFalsy(this.contratoDetailDto) && !isFalsy(this.idContrato))
      this.getById(this.idContrato)
  }

  getById(id: number) {
    this.spinner.show()
    this.contratosService.getById(id)
      .subscribe(
        data => {
          this.spinner.hide()
          this.contratoDetailDto = data
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        }
      )
  }

  openDialogGenerarResumen(id: number) {
    this.spinner.show()
    this.contratosService.getById(id)
      .subscribe(
        data => {
          this.spinner.hide()
          const dialogConfig = new MatDialogConfig();
          dialogConfig.width = "400px"
          dialogConfig.autoFocus = false
          dialogConfig.disableClose = true

          dialogConfig.data = { event: AccionesDialog.Agregar, contrato: data }
          const dialogRef = this.dialog.open(DialogGenerarResumenComponent,
            dialogConfig);

          dialogRef.afterClosed().subscribe(result => {
          });
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        }
      )
  }

  openDialogNuevaLiquidacion(id: number) {
    this.spinner.show()
    this.contratosService.getById(id)
      .subscribe(
        data => {
          this.spinner.hide()
          const dialogConfig = new MatDialogConfig();
          dialogConfig.width = "1080px"
          dialogConfig.data = data
          dialogConfig.autoFocus = false
          dialogConfig.disableClose = true

          const dialogRef = this.dialog.open(DialogNuevaLiquidacionComponent,
            dialogConfig);
          dialogRef.afterClosed().subscribe(result => {
          });
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        }
      )
  }

  openDialogRescisionContrato(id: number) {
    this.spinner.show()
    this.contratosService.getById(id)
      .subscribe(
        data => {
          this.spinner.hide()
          const dialogConfig = new MatDialogConfig();
          dialogConfig.autoFocus = false
          dialogConfig.disableClose = false
          dialogConfig.data = data

          const dialogRef = this.dialog.open(DialogRescisionContratoComponent,
            dialogConfig);

          dialogRef.afterClosed().subscribe(result => {
            if (result.event == AccionesDialog.Aceptar) {
              this.router.navigate(["/contratos"])
            }
          });
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        }
      )
  }

  openDialogImputacionPropietario(contratoDetailDto: ContratoDetailDto) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false
    dialogConfig.disableClose = false
    dialogConfig.width = "70%"
    dialogConfig.data = contratoDetailDto

    const dialogRef = this.dialog.open(DialogImputacionPropietarioComponent,
      dialogConfig);

    dialogRef.afterClosed().subscribe(result => {

    });
  }


  rescisionContrato(resindirContratoRequest: ResindirContratoRequest) {
    this.spinner.show()
    this.contratosService.rescisionContrato(resindirContratoRequest)
      .subscribe(
        data => {
          this.spinner.hide()
          this.snackBar.showSuccess("Contrato resindido de manera correcta.", "Exito");
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError("Ups.. hubo un problema al resindir contrato.", "Error");
        }
      )
  }


  openDialogNuevoRecibo(id: number) {
    this.spinner.show()
    this.contratosService.getById(id)
      .subscribe(
        data => {
          this.spinner.hide()
          const dialogConfig = new MatDialogConfig();
          dialogConfig.width = "100%"
          dialogConfig.data = { event: AccionesDialog.Agregar, contrato: data }
          dialogConfig.autoFocus = false
          dialogConfig.disableClose = true

          const dialogRef = this.dialog.open(DialogNuevoReciboComponent,
            dialogConfig);

          dialogRef.afterClosed().subscribe(result => {
          });
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        }
      )
  }

  isFalsy(valor: any) {
    return isFalsy(valor)
  }

  trackByItems(index: number, item: any): any { return item; }

}
