import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccionesDialog } from '@models/base/identificadores.model';
import { ServicioPropiedadDto } from '@models/servicios/servicio-propiedad/servicioPropiedadDto.model';
import { ServiciosPropiedadService } from '@services/servicios/servicios-propiedad/servicios-propiedad.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { DialogDeleteComponent } from 'src/app/views/genericos/dialog-delete/dialog-delete.component';



@Component({
  selector: 'app-listado-servicios-propiedad',
  templateUrl: './listado-servicios-propiedad.component.html',
  styleUrls: ['./listado-servicios-propiedad.component.css']
})
export class ListadoServiciosPropiedadComponent implements OnInit {
  data=false
  displayedColumns: string[] = [];
  public servicioPropiedadDto: ServicioPropiedadDto[] = [];
  dataSource = new MatTableDataSource<ServicioPropiedadDto>();
  errorMessage: string = "";
  @Input() idPropiedad: any

  constructor(
    private serviciosPropiedadService:ServiciosPropiedadService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private aRoute:ActivatedRoute,
    private dialog: MatDialog,
    breakpointObserver: BreakpointObserver
  ) {
    breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.displayedColumns = result.matches ?
      ["servicio", "nroCuenta","accion"] :
      ["servicio", "nroCuenta", "nroGestionPersonal", "referenciaPago","accion"];
    });
  }

  private paginator!: MatPaginator;
  private sort!: MatSort;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    if (this.paginator && this.sort) {
      this.applyFilter(null);
    }
  }

  ngOnInit(){
    const routeParams = this.aRoute.snapshot.paramMap;
    this.idPropiedad = Number(routeParams.get('id'));
    this.getServiciosPropiedadByIdPropiedad(this.idPropiedad);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

  }

  applyFilter(event: any) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


  getServiciosPropiedadByIdPropiedad(idPropiedad:number){
    this.spinner.show();
    this.serviciosPropiedadService.getServiciosPropiedadByIdPropiedad(idPropiedad).subscribe(
      data => {
        this.spinner.hide();
        this.dataSource.data = data.sort((a, b) => a.servicio.descripcion.toString().localeCompare(b.servicio.descripcion.toString())) as ServicioPropiedadDto[]
        this.data = this.dataSource.data.length >= 1 ?  true : false
      },
      error => {
        this.spinner.hide();
        this.snackBar.showError( error, "Error");
      })
  }

  openDialogDelete(action: any, id: number) {
    let obj = { action: action, id: id };
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "30%",
    dialogConfig.autoFocus = false;
    dialogConfig.data = obj

    const dialogRef = this.dialog.open(DialogDeleteComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == AccionesDialog.Eliminar) {
        this.deleteServicioPropiedad(result.data);
      }
    });
  }

  deleteServicioPropiedad(id: number) {
    this.spinner.show();
    this.serviciosPropiedadService.deleteServicioPropiedad(id)
      .subscribe(
        data => {
          this.spinner.hide();
          this.snackBar.showSuccess("Registro eliminado correctamente.", "Exito");
          this.getServiciosPropiedadByIdPropiedad(this.idPropiedad);
        },
        error => {
          this.spinner.hide();
          this.snackBar.showError(error, "Error");
        })
  }
}
