import { animate, query, state, style, transition, trigger } from '@angular/animations';
import { BreakpointObserver } from '@angular/cdk/layout';
import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { BaseDto } from '@models/base/baseDto.model';
import { AccionesDialog, TiposMontos } from '@models/base/identificadores.model';
import { AdendarContratoRequest } from '@models/contrato/adendarContratoRequest.model';
import { ContratoDetailDto } from '@models/contrato/contratoDetailDto.model';
import { ContratoGarantiaDto } from '@models/contrato/contratoGarantiaDto.model';
import { PeriodoContratoDetailDto } from '@models/periodos-contrato/periodoContratoDetailDto.model';
import { PeriodoContratoSummaryDto } from '@models/periodos-contrato/periodoContratoSummaryDto.model';
import { ResindirContratoRequest } from '@models/contrato/resindirContratoRequest.model';
import { UpdateContratoRequest } from '@models/contrato/updateContratoRequest.model';
import { GarantiaSummaryDto } from '@models/garantia/garantiaSummaryDto.model';
import { InquilinoDto } from '@models/inquilino/inquilinoDto.model';
import { CreatePeriodoContratoRequest } from '@models/periodos-contrato/createPeriodoContratoRequest.model';
import { PropiedadDto } from '@models/propiedades/propiedadDto.model';
import { PropietarioDto } from '@models/propietarios/propietarioDto.model';
import { TipoMonedaDto } from '@models/tipo-moneda/tipoMonedaDto.model';
import { ContratosService } from '@services/contratos/contratos.service';
import { GarantiasService } from '@services/garantias/garantias.service';
import { PeriodosContratoService } from '@services/periodos-contrato/periodos-contrato.service';
import { PersonasService } from '@services/personas/personas.service';
import { PropiedadesService } from '@services/propiedades/propiedades.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { isFalsy } from 'utility-types';
import { DialogNuevoPeriodoComponent } from '../form-nuevo-contrato/dialog-nuevo-periodo/dialog-nuevo-periodo.component';
import { Periodo } from '../form-nuevo-contrato/form-nuevo-contrato.component';
import { DialogAdendaContratoComponent } from './dialog-adenda-contrato/dialog-adenda-contrato.component';
import { DialogGenerarResumenComponent } from './dialog-generar-resumen/dialog-generar-resumen.component';
import { DialogNuevaLiquidacionComponent } from './dialog-nueva-liquidacion/dialog-nueva-liquidacion.component';
import { DialogRescisionContratoComponent } from './dialog-rescision-contrato/dialog-rescision-contrato.component';
import { PersonaDetailDto } from '@models/personas/personaDetailDto.model';
import { DialogVerPeriodoContratoDetailDtoComponent } from '@views/periodos-contrato/dialog-ver-periodo-contrato-detail-dto/dialog-ver-periodo-contrato-detail-dto.component';
import { DialogNuevoReciboComponent } from '../dialog-nuevo-recibo/dialog-nuevo-recibo/dialog-nuevo-recibo.component';

// Animations

@Component({
  selector: 'app-form-editar-contrato',
  templateUrl: './form-editar-contrato.component.html',
  styleUrls: ['./form-editar-contrato.component.css'],
})

export class FormEditarContratoComponent implements OnInit,AfterViewInit  {


  data = false
  @ViewChild(MatTable, { static: true }) table!: MatTable<any>;
  private paginator!: MatPaginator;
  private sort!: MatSort;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    if (this.paginator && this.sort) {
      this.applyFilter(null);
    }
  }
  applyFilter(event: any) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  @Input() idContrato!: number

  //Contrato
  updateContratoRequest = new UpdateContratoRequest
  contratoGarantiaDto = new ContratoGarantiaDto

  //Form Group
  datosPropiedadFormGroup!: FormGroup;
  datosInquilinoFormGroup!: FormGroup;
  datosContratoFormGroup!: FormGroup;
  montosContratoFormGroup!: FormGroup;
  validatorFormGroup!: FormGroup;
  detalleMontosContratorFormGroup!: FormGroup
  isEditable = false;
  //Propiedad
  propiedad: PropiedadDto = new PropiedadDto
  inquilinoDto: InquilinoDto = new InquilinoDto;
  personaInquilino: PersonaDetailDto = new PersonaDetailDto;
  persona!: PersonaDetailDto
  propietariosPropiedad: PropietarioDto[] = []
  garantiasInquilino: GarantiaSummaryDto[] = []
  tieneGarantias: boolean = true;
  dataSource = new MatTableDataSource<PeriodoContratoDetailDto>();
  displayedColumns: string[] = []
  //Contrato

  //Totales
  totalMontoAlquiler: number = 0
  totalComision: number = 0
  totalDescuento: number = 0
  totalIncrementos: number = 0
  totalSellado: number = 0
  total: number = 0
  contratoOk!: boolean;
  ultimoPeriodo: any;
  tipoMontoSellado: any;
  tipoSellado: any;
  tipoPunitorio: any;
  tipoMontoAdministrativo: any;
  tipoMontoPunitorio: any;
  lstTiposMoneda: TipoMonedaDto[] = [];
  lstTiposMonto: BaseDto[] = [];
  lstTiposPunitorio: BaseDto[] = [];
  cantMesesContrato: any;
  mostrarFechaRescincion: boolean = false;
  contratoDetailDto: ContratoDetailDto = new ContratoDetailDto
  rescincion: boolean = false;
  public dialogRef!: MatDialogRef<DialogNuevoPeriodoComponent>


  get datCon() { return this.datosContratoFormGroup.controls; }
  get datMon() { return this.montosContratoFormGroup.controls; }
  get datDetMon() { return this.detalleMontosContratorFormGroup.controls; }

  constructor(
    private _formBuilder: FormBuilder,
    private personasService: PersonasService,
    private propiedadService: PropiedadesService,
    private garantiasService: GarantiasService,
    private spinner: NgxSpinnerService,
    private snackBar: SnackBarService,
    private aRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    // private ref: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver,
    private contratosService: ContratosService,
    private periodoContratoService: PeriodosContratoService
  ) {

    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.displayedColumns = result.matches ?
        ["periodo", "importe", "indiceAjusteContrato", "accion"] :
        ["periodo", "periodoFecha",  "fechaPago", "fechaLiquidacion","fechaAjuste", "importe", "indiceAjusteContrato","valorAjuste", "ajusteProcesado",  "accion"];
    });


    this.datosContratoFormGroup = this._formBuilder.group({
      FechaRescicion: [''],
      AlarmaRescindidoContrato: ['']
    });

    this.detalleMontosContratorFormGroup = this._formBuilder.group({
    });

    this.dataSource.data = []


  }

  permitirSalidaDeRuta(): boolean | Observable<boolean> | Promise<boolean> {
    if (this.contratoOk == true)
      return true

    const confirmacion = window.confirm("¿Quieres salir del formulario y perder los cambios realizados?")
    return confirmacion
  };

  ngOnInit() {
    const routeParams = this.aRoute.snapshot.paramMap;
    this.idContrato = Number(routeParams.get('id'));
    if (this.idContrato > 0)
      this.getContrato(this.idContrato)
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }


  //#region Get
  getContrato(id: number) {
    this.spinner.show("spContrato");
    this.contratosService.getById(id).subscribe(
      async data => {
        this.spinner.hide("spContrato");
        this.contratoDetailDto = data
        await this.setFormContrato()
        await this.contratosService.setContratoDto(data);
        await this.getPropiedadById(this.contratoDetailDto.propiedad.id)
        await this.getPersonaInquilinoById(this.contratoDetailDto.inquilino.persona.id)
        this.spinner.hide('spContratoMain');
      },
      error => {
        this.spinner.hide("spContrato");
        this.snackBar.showError(error, "Error");
      }
    )
  }

  editarPersona(idPersona: number) {
    this.getPersonaByIdAndRoute(idPersona)
  }

  editarPropiedad(idPropiedad: number) {
    this.router.navigate(["/propiedades/", idPropiedad])
  }

  getPersonaByIdAndRoute(idPersona: number) {
    this.spinner.show("spPersona")
    this.personasService.getPersonaById(idPersona)
      .subscribe(
        data => {
          this.spinner.hide("spPersona")
          this.personasService.setPersona(data)
          this.router.navigate(["/personas/editar"])
        },
        error => {
          this.spinner.hide("spPersona")
          this.snackBar.showError(error, "Error");
        });
  }

  getPersonaInquilinoById(id: any) {
    this.spinner.show("spInquilino");
    this.personasService.getPersonaById(id)
      .subscribe(
        data => {
          this.spinner.hide("spInquilino");
          this.personaInquilino = data
        },
        error => {
          this.snackBar.showError(error, "Error");
          this.spinner.hide("spInquilino");
        });
  }

  getAllPropietariosPropiedad(idPropiedad: number) {
    this.spinner.show("spDatosPropietarios");
    this.propiedadService.getAllPropietariosPropiedad(idPropiedad)
      .subscribe(
        data => {
          this.spinner.hide("spDatosPropietarios");
          this.propietariosPropiedad = data.filter(x => x.titular == true)
        },
        error => {
          this.propietariosPropiedad = []
          this.snackBar.showError(error, "Error");
          this.spinner.hide("spDatosPropietarios");
        });
  }

  getPropiedadById(idPropiedad: number) {
    this.spinner.show("spDatosPropiedad");
    this.propiedadService.getById(idPropiedad)
      .subscribe(
        async data => {
          this.spinner.hide("spDatosPropiedad");
          this.propiedad = data
          await this.getAllPropietariosPropiedad(this.propiedad.id)
        },
        error => {
          this.snackBar.showError(error, "Error");
          this.spinner.hide("spDatosPropiedad");
        });
  }

  getAllGarantiasByIdInquilino(idPersonaInquilino: number) {
    this.spinner.show("spGarantia");
    this.garantiasService.getAllGarantiasByInquilino(idPersonaInquilino)
      .subscribe(
        data => {
          this.spinner.hide("spGarantia");
          this.garantiasInquilino = data.filter(element => { return element.activo == true })
        },
        error => {
          this.spinner.hide("spGarantia");
          this.garantiasInquilino = []
          this.snackBar.showError(error, "Error");
        });
  }

  getPeriodosContratoByIdContrato(idContrato: number) {
    this.spinner.show("spPeriodosConstrato");

    this.contratosService.getPeriodosContratoByIdContrato(idContrato).subscribe(
      data => {
        this.dataSource.data = []
        this.spinner.hide("spPeriodosConstrato");
        const sortedData = data.sort((a, b) => {
          return parseInt(a.periodo.toString(), 10) - parseInt(b.periodo.toString(), 10);
        });
        this.dataSource.data = sortedData

        this.calcularTotalesActuales();
        this.data = this.dataSource.data.length >= 1;
      },
      error => {
        this.spinner.hide("spPeriodosConstrato");
        this.snackBar.showError(error, "Error");
      }
    );
}


  //#endregion

  //#region Acciones

  checkAlarmaRescicion(event: any) {
    this.contratoDetailDto.fechaResicion = ""
    if (event == true) {
      this.mostrarFechaRescincion = true
    }
    else {
      this.mostrarFechaRescincion = false
    }
  }

  rescisionContrato(resindirContratoRequest: ResindirContratoRequest) {
    this.contratosService.rescisionContrato(resindirContratoRequest)
      .subscribe(
        data => {
          this.snackBar.showSuccess("Contrato resindido de manera correcta.", "Exito");
          this.rescincion = true
        },
        error => {
          this.snackBar.showError("Ups.. hubo un problema al resindir contrato.", "Error");
        }
      )
  }

  crearContrato() {
    this.router.navigate(["/contratos"])
  }

  obtenerUltimoMonto(): number {
    let importeAlquiler: number = 0
    this.dataSource.data.forEach(periodo => {
      if (periodo.importeAlquiler > importeAlquiler) {
        importeAlquiler = periodo.importeAlquiler
      }
    })
    return importeAlquiler
  }

  obtenerUltimoPeriodo(): number {
    let ultimoPeriodo: number = 0
    this.dataSource.data.forEach(periodo => {
      if (periodo.periodo > ultimoPeriodo)
        ultimoPeriodo = periodo.periodo
    })
    ultimoPeriodo++
    return ultimoPeriodo
  }

  agregarPeriodo(periodo: Periodo, adendar: boolean) {

    let fechaDesde = this.contratoDetailDto.fechaComienzo
    let fechaVencimiento = this.contratoDetailDto.fechaVencimiento
    const cantMesesContrato = moment(fechaVencimiento).diff(fechaDesde, "months") + 1;

    if (adendar) {
      if (periodo.periodoDesde <= periodo.periodoHasta) {
        let periodosContratoSummaryDto = this.agregarPeriodoSummery(periodo)
        let adendarContratoRequest: AdendarContratoRequest = new AdendarContratoRequest

        adendarContratoRequest.idContrato = this.idContrato
        adendarContratoRequest.periodoContrato = periodosContratoSummaryDto

        this.adendarContrato(adendarContratoRequest);
      }
      else {
        this.snackBar.showWarn("Ingrese periodos validos.", "Error");
        this.openDialogNuevoPeriodoComponent(true)
      }
    }
    else {
      if (periodo.periodoDesde <= periodo.periodoHasta && periodo.periodoHasta <= cantMesesContrato) {
        let periodosContratoSummaryDto = this.agregarPeriodoSummery(periodo)
        let createPeriodoContratoRequest: CreatePeriodoContratoRequest = new CreatePeriodoContratoRequest

        createPeriodoContratoRequest.idContrato = this.idContrato
        createPeriodoContratoRequest.periodoContrato = periodosContratoSummaryDto

        this.createCollectionPeriodoContrato(createPeriodoContratoRequest)
      }
      else {
        this.snackBar.showWarn("Ingrese periodos validos.", "Error");
        this.openDialogNuevoPeriodoComponent(false)
      }
    }

  }

  agregarPeriodoSummery(periodo: Periodo) {
    let periodosContratoSummaryDto: PeriodoContratoSummaryDto[] = []

    for (periodo.periodoDesde; periodo.periodoDesde <= periodo.periodoHasta; periodo.periodoDesde++) {
      let periodoContratoSummaryDto = new PeriodoContratoSummaryDto
      periodoContratoSummaryDto.descuento = periodo.descuento
      periodoContratoSummaryDto.importeAlquiler = periodo.montoAlquiler
      periodoContratoSummaryDto.incremento = periodo.incremento
      periodoContratoSummaryDto.valorAjuste = periodo.valorAjuste
      periodoContratoSummaryDto.periodo = periodo.periodoDesde
      periodosContratoSummaryDto.push(periodoContratoSummaryDto)
    }
    return periodosContratoSummaryDto
  }


  adendarContrato(adendarContratoRequest: AdendarContratoRequest) {
    this.spinner.show("spPeriodosConstrato");
    this.contratosService.adendar(adendarContratoRequest).subscribe(
      async data => {
        this.spinner.hide("spPeriodosConstrato");
        await this.getPeriodosContratoByIdContrato(this.idContrato);
        await this.getContrato(this.idContrato);
      },
      error => {
        this.spinner.hide("spPeriodosConstrato");
        this.snackBar.showError(error, "Error");
      }
    )
  }

  createCollectionPeriodoContrato(createPeriodoContratoRequest: CreatePeriodoContratoRequest) {
    this.spinner.show("spPeriodosConstrato");
    this.periodoContratoService.createCollection(createPeriodoContratoRequest).subscribe(
      data => {
        this.spinner.hide("spPeriodosConstrato");
        this.getPeriodosContratoByIdContrato(this.idContrato);
      },
      error => {
        this.spinner.hide("spPeriodosConstrato");
        this.snackBar.showError(error, "Error");
      }
    )
  }

  calcularTotalesActuales() {
    let tipoMontoHonorarios = this.contratoDetailDto.tipoMontoAdministrativo
    let tipoMontoSellado = this.contratoDetailDto.tipoMontoSellado
    let montoHonorarios = this.contratoDetailDto.montoAdministrativo
    let montoSellado = this.contratoDetailDto.montoSellado


    this.totalMontoAlquiler = 0
    this.totalDescuento = 0
    this.totalIncrementos = 0
    this.dataSource.data.forEach(
      periodo => {
        this.totalMontoAlquiler += periodo.importeAlquiler
        this.totalDescuento += periodo.descuento
        this.totalIncrementos += periodo.incremento
      }
    )

    //Calculo Honorarios
    if (tipoMontoHonorarios.id == TiposMontos.PorcentajeAlquiler) {
      //Porcentaje
      this.totalComision = ((this.totalMontoAlquiler * montoHonorarios) / 100)
    }
    else {
      this.totalComision = montoHonorarios
    }

    //Calculo Sellado
    if (tipoMontoSellado.id == TiposMontos.PorcentajeAlquiler) {
      //Porcentaje
      this.totalSellado = ((this.totalMontoAlquiler * montoSellado) / 100)
    }
    else {
      this.totalSellado = montoSellado
    }
    this.total = this.totalMontoAlquiler - this.totalDescuento + this.totalIncrementos + this.totalSellado + this.totalComision
  }

  calcularTotalesIniciales(key: string) {
    // let tipoMontoHonorarios = this.contratoDetailDto.tipoMontoAdministrativo
    // let montoHonorarios = this.contratoDetailDto.montoAdministrativo
    // let montoTotalComision = this.contratoDetailDto.montoTotalHonorario
    // //Calculo Honorarios
    // if (tipoMontoHonorarios.id == TiposMontos.PorcentajeAlquiler) {
    //   //Porcentaje
    //   montoTotalComision = ((this.contratoDetailDto.montoTotalContrato * montoHonorarios) / 100)
    // }
    // else {
    //   montoTotalComision = montoHonorarios
    // }

    let montoTotalSellado = this.contratoDetailDto.montoTotalSellado
    let montoTotalHonorario = this.contratoDetailDto.montoTotalHonorario
    let montoTotalContrato = this.contratoDetailDto.montoTotalContrato - montoTotalSellado - montoTotalHonorario

    let montoTotal = montoTotalContrato + montoTotalHonorario + montoTotalSellado

    if(!isFalsy(key))
    switch (key) {
      case 'montoTotalContrato':
        return montoTotalContrato
      case 'montoTotalSellado':
        return montoTotalSellado
      case 'montoTotalComision':
        return montoTotalHonorario
      case 'montoTotal':
        return montoTotal
      default:
        return 0
    }
    else
    return 0




  }


  setFormContrato() {
    let contrato = this.contratoDetailDto
    this.dataSource.data = this.contratoDetailDto.periodosContratos.sort((a, b) => a.periodo - b.periodo)

    this.data = this.dataSource.data.length >= 1 ? true : false
    this.dataSource = new MatTableDataSource<PeriodoContratoDetailDto>(this.dataSource.data)
    this.calcularTotalesActuales()
  }

  eliminarPeriodo(periodo: any) {
    if (this.dataSource.data.length == periodo.periodo) {
      this.spinner.show("spPeriodosConstrato");
      this.periodoContratoService.delete(periodo.id).subscribe(
        data => {
          this.spinner.hide("spPeriodosConstrato");
          this.getPeriodosContratoByIdContrato(this.idContrato);
        },
        error => {
          this.spinner.hide("spPeriodosConstrato");
          this.snackBar.showError(error, "Error");
        }
      )
    }
    else {
      this.snackBar.showWarn("Borre los periodos de manera ordenada.", "Error");
    }
  }

  //#endregion Acciones

  //#region Dialog

  openDialogNuevoPeriodoComponent(adendar: boolean) {
    let ultimoPeriodo = this.obtenerUltimoPeriodo()
    let ultimoMonto = this.obtenerUltimoMonto()
    let totalPeriodos = this.calcularDuracionPeriodo(this.contratoDetailDto.fechaComienzo, this.contratoDetailDto.fechaVencimiento)
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.data = {
      event: AccionesDialog.Agregar,
      ultimoPeriodo: ultimoPeriodo,
      cantidadPeriodos: totalPeriodos,
      tipoContrato: this.contratoDetailDto.tipoContrato.id,
      ultimoMonto: ultimoMonto,
      adendar: adendar
    }

    const dialogRef = this.dialog.open(DialogNuevoPeriodoComponent,
      dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == AccionesDialog.Agregar) {
        this.agregarPeriodo(result.data, adendar);
      }
    });
  }

  calcularDuracionPeriodo(fechaDesde: any, fechaHasta: any) {
    const fechaComienzo = moment(new Date(fechaDesde))
    const fechaVencimiento = moment(new Date(fechaHasta))
    const meses = fechaVencimiento.diff(fechaComienzo, 'months')
    return meses + 1
  }

  openDialogGenerarResumen() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "400px"
    dialogConfig.data = { event: AccionesDialog.Agregar, contrato: this.contratoDetailDto }
    dialogConfig.autoFocus = false
    dialogConfig.disableClose = true

    const dialogRef = this.dialog.open(DialogGenerarResumenComponent,
      dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
    });
  }


  openDialogAdendaContratoComponent(adendar: boolean) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(DialogAdendaContratoComponent,
      dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      result.event == AccionesDialog.Aceptar ? this.openDialogNuevoPeriodoComponent(adendar) : false
    });
  }

  openDialogNuevaLiquidacion() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "1080px"
    dialogConfig.data = this.contratoDetailDto
    dialogConfig.autoFocus = false
    dialogConfig.disableClose = true

    const dialogRef = this.dialog.open(DialogNuevaLiquidacionComponent,
      dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  openDialogRescisionContrato() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false
    dialogConfig.disableClose = false
    dialogConfig.data = this.contratoDetailDto

    const dialogRef = this.dialog.open(DialogRescisionContratoComponent,
      dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == AccionesDialog.Aceptar) { }
    });
  }

  openDialogNuevoRecibo() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "100%"
    dialogConfig.data = { event: AccionesDialog.Agregar, contrato: this.contratoDetailDto }
    dialogConfig.autoFocus = false
    dialogConfig.disableClose = true

    const dialogRef = this.dialog.open(DialogNuevoReciboComponent,
      dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  openDialog(periodoContratoDetail: PeriodoContratoDetailDto) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.data = periodoContratoDetail,
      dialogConfig.width = "50%"

    const dialogRef = this.dialog.open(DialogVerPeriodoContratoDetailDtoComponent, dialogConfig);
  }

  //#endregion

  isFalsy(valor: any) {
    return isFalsy(valor)
  }

  trackByItems(index: number, item: any): any { return item; }




}
