<div *appRole="['GruposGarantias.Ver']">
  <button *appRole="['GruposGarantias.Crear']" mat-raised-button (click)="openDialog('Agregar', {})" color="primary">
    <mat-icon>add</mat-icon>
    Grupos de Garantias
  </button>
  <br /><br />
  <mat-card *appRole="['GruposGarantias.Ver']">
    <ngx-spinner bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
    </ngx-spinner>
    <mat-form-field class="example-full-width">
      <mat-label>Buscar</mat-label>
      <input matInput type="search" (keyup)="applyFilter($event)" />
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
    <br />
    <table mat-table [dataSource]="dataSource" [hidden]="!data" table matSort matSortStart="asc"
      class="mat-elevation-z8">
      <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

      <!-- Id Column -->
      <!-- <ng-container matColumnDef="id">
              <mat-header-cell mat-header-cell *matHeaderCellDef> # </mat-header-cell>
              <mat-cell mat-cell *matCellDef="let element"> {{element.id}} </mat-cell>
          </ng-container> -->

      <!-- Tipo Garantia Column -->
      <ng-container matColumnDef="tipoGarantia">
        <mat-header-cell mat-header-cell *matHeaderCellDef>
          Cantidad de Garantias
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let element">
          {{ element.cantidadTipoGarantia.tipoGarantia.descripcion }} x
          {{ element.cantidadTipoGarantia.cantidad }}
        </mat-cell>
      </ng-container>

      <!-- Descripcion Column -->
      <ng-container matColumnDef="idGrupo">
        <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header>
          Grupo
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let element">
          {{ element.idGrupo }}
        </mat-cell>
      </ng-container>

      <!-- Accion Column -->
      <ng-container matColumnDef="accion">
        <mat-header-cell mat-header-cell *matHeaderCellDef>
          Acción
        </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let element" class="action-link">

          <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button *appRole="['GruposGarantias.Modificar']" mat-menu-item (click)="openDialog('Modificar',element)">
              <mat-icon>edit</mat-icon>
              <span>Editar</span>
            </button>
            <button *appRole="['GruposGarantias.Eliminar']" mat-menu-item (click)="openDialog('Eliminar',element)">
              <mat-icon>delete</mat-icon>
              <span>Eliminar</span>
            </button>
          </mat-menu>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row matRipple *matRowDef="let row; columns: displayedColumns"></mat-row>
    </table>
    <div class="no-data-table">
      <span class="with-icon" *ngIf="dataSource.data.length == 0">
        <mat-icon>search_off</mat-icon>No se encontraron datos para
        mostrar.
      </span>
    </div>
    <mat-paginator showFirstLastButtons class="paginator" [hidden]="!data" [pageSize]="10"
      [pageSizeOptions]="[5, 10, 20, 50, 100, 200]">
    </mat-paginator>
  </mat-card>
</div>
