import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccionesDialog } from '@models/base/identificadores.model';
import { GarantiaDto } from '@models/garantia/garantiaDto.model';
import { GarantiaSummaryDto } from '@models/garantia/garantiaSummaryDto.model';
import { GarantiasService } from '@services/garantias/garantias.service';
import { PersonasService } from '@services/personas/personas.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { DialogDeleteComponent } from '@views/genericos/dialog-delete/dialog-delete.component';
import { PersonaDetailDto } from '@models/personas/personaDetailDto.model';
import { DisableGarantiaRequest } from '@models/garantia/disableGarantiaRequest.model';
import { EnableGarantiaRequest } from '@models/garantia/enableGarantiaRequest.model';

@Component({
  selector: 'app-listado-garantias',
  templateUrl: './listado-garantias.component.html',
  styleUrls: ['./listado-garantias.component.css']
})
export class ListadoGarantiasComponent implements OnInit {

  data = false
  dataSource = new MatTableDataSource<GarantiaSummaryDto>();
  garantiaDto: GarantiaDto = new GarantiaDto
  validador: boolean = false;
  @ViewChild(MatTable, { static: true }) table!: MatTable<any>;

  idGarantia: number = 0
  garantiaSummaryDtoTable: GarantiaSummaryDto = new GarantiaSummaryDto
  displayedColumns!: string[];

  private paginator!: MatPaginator;
  private sort!: MatSort;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    if (this.paginator && this.sort) {
      this.applyFilter(null);
    }
  }
  applyFilter(event: any) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  personaDetailDto!: PersonaDetailDto

  constructor(
    private garantiaService: GarantiasService,
    private snackBar: SnackBarService,
    private router: Router,
    private garantiasService: GarantiasService,
    private spinner: NgxSpinnerService,
    private breakpointObserver: BreakpointObserver,
    private personasService: PersonasService,
    private dialog: MatDialog
  ) {

    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.displayedColumns = result.matches ?
        ["tipoGarantia", "toggle", "accion"] :
        ["tipoGarantia", "legajo", "razonSocial", "cuitCuil", "sueldo", "toggle", "accion"];
    });

  }

  ngOnInit(): void {
    this.personaDetailDto = this.personasService.getPersona()
    this.getAllGarantias(this.personaDetailDto.id)
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.data = this.dataSource.data.length >= 1 ? true : false
  }

  editarGarantia(garantia: GarantiaSummaryDto) {
    this.garantiaService.garantia = garantia
    this.idGarantia = garantia.id
    this.router.navigate(["/garantia/editar", this.idGarantia])
  }

  openDialog(action: any, obj: { action?: any; }) {
    obj.action = action;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "30%",
      dialogConfig.autoFocus = false;
    dialogConfig.data = obj

    const dialogRef = this.dialog.open(DialogDeleteComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == AccionesDialog.Eliminar) {
        this.deleteRowData(result.data);
      }
    });
  }

  deleteRowData(id: number) {
    this.spinner.show()
    this.garantiaService.delete(id)
      .subscribe(
        data => {
          this.spinner.hide()
          this.dataSource.data = this.dataSource.data.filter((value: { id: number; }, key: any) => {
            return value.id != id;
          });
          this.data = this.dataSource.data.length >= 1 ? true : false
          this.snackBar.showSuccess("Registro dado de baja correctamente.", "Exito");
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError("El registro no se puede eliminar porque tiene referencias asignadas.", "Error");
        });
  }

  getAllGarantias(idPersona: number) {
    this.spinner.show("spListadoGarantias")
    this.garantiaService.getAllGarantiasByPersona(idPersona)
      .subscribe(
        data => {
          this.spinner.hide("spListadoGarantias")
          this.dataSource.data = data
          this.data = this.dataSource.data.length >= 1 ? true : false
        },
        error => {
          this.spinner.hide("spListadoGarantias")
          this.snackBar.showError(error, "Error");
        }
      )
  }


  actualizaActivo(garantia: any) {
    garantia.activo = !garantia.activo;
    if (garantia.activo == true) {
      let enableGarantiaRequest:EnableGarantiaRequest = new EnableGarantiaRequest;
      enableGarantiaRequest.id_Garantia = garantia.id
      this.enableGarantia(enableGarantiaRequest)
    }
    else {
      let disableGarantiaRequest: DisableGarantiaRequest =  new DisableGarantiaRequest;
      disableGarantiaRequest.id_Garantia = garantia.id
      this.disableGarantia(disableGarantiaRequest)
    }
  }

  enableGarantia(enableGarantiaRequest: EnableGarantiaRequest) {
    this.spinner.show("spListadoGarantias")
    this.garantiaService.enableGarantia(enableGarantiaRequest)
      .subscribe(
        data => {
          this.spinner.hide("spListadoGarantias")
          this.snackBar.showSuccess(data.text, "Exito");
        },
        error => {
          this.spinner.hide("spListadoGarantias")
          this.snackBar.showError(error, "Error");
        }
      )
  }

  disableGarantia(disableGarantiaRequest: DisableGarantiaRequest) {
    this.spinner.show("spListadoGarantias")
    this.garantiaService.disableGarantia(disableGarantiaRequest)
      .subscribe(
        data => {
          this.spinner.hide("spListadoGarantias")
          this.snackBar.showSuccess(data.text, "Exito");
        },
        error => {
          this.spinner.hide("spListadoGarantias")
          this.snackBar.showError(error, "Error");
        }
      )
  }

  getGarantiaByForTable(idGarantia: number, accion: string) {
    this.spinner.show("spFromGarantia")
    this.garantiasService.getGarantiaById(idGarantia)
      .subscribe(
        async data => {

          this.spinner.hide("spFromGarantia")
          this.garantiaDto = data
          await this.setGarantiaSummaryDtoTable(this.garantiaDto)
        },
        error => {
          this.spinner.hide("spFromGarantia")
          this.snackBar.showError(error, "Error");
        })
  }


  setGarantiaSummaryDtoTable(garantia: GarantiaDto) {
    this.garantiaSummaryDtoTable.id = garantia.id
    this.garantiaSummaryDtoTable.activo = garantia.activo;
    this.garantiaSummaryDtoTable.cuitCuil = garantia.cuitCuil
    this.garantiaSummaryDtoTable.descripcion = garantia.descripcion
    this.garantiaSummaryDtoTable.nroFolio = garantia.nroFolio
    this.garantiaSummaryDtoTable.numero = garantia.numero
    this.garantiaSummaryDtoTable.tomo = garantia.tomo
    this.garantiaSummaryDtoTable.ubicacion = garantia.ubicacion
    this.garantiaSummaryDtoTable.idTipoFolio = garantia.idTipoFolio
    this.garantiaSummaryDtoTable.tipoGarantia = garantia.tipoGarantia
    this.garantiaSummaryDtoTable.razonSocial = garantia.razonSocial
    this.garantiaSummaryDtoTable.sueldo = garantia.sueldo
    this.dataSource.data.push({ ...this.garantiaSummaryDtoTable })
    this.dataSource.data = this.dataSource.data.map(o => {
      return o;
    })
    this.data = this.dataSource.data.length >= 1 ? true : false

  }

}
