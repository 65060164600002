<mat-form-field>
    <ngx-spinner [zIndex]="1" name="spBusqueda" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
    <mat-label>Persona</mat-label>
    <input [disabled]="disable" type="search" placeholder="Buscar por nombre o doc." matInput [matAutocomplete]="auto" [formControl]="entityControl" />
    <button disabled mat-icon-button matSuffix>
            <mat-icon>search</mat-icon>
        </button>
    <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let persona of filteredPersonas | async ;let i=index;trackBy:trackByItems" [value]="persona.nombreCompleto" (click)="selectValue(persona)" (onSelectionChange)="selectValue(persona)">
            <span #tooltip="matTooltip" matTooltipPosition="right" [matTooltipDisabled]="!disabledDocumento.value" matTooltip={{persona.nroDocumento}}>{{persona.nombreCompleto | titlecase}}</span>
        </mat-option>
    </mat-autocomplete>
    <button mat-icon-button matSuffix *ngIf="personaDetailDto.nombreCompleto!= ''" (click)="editarPersona(personaDetailDto.id)" matTooltip="Editar persona" matTooltipPosition="below">
      <mat-icon color="primary">info</mat-icon>
      </button>
    <button mat-icon-button matSuffix matTooltip="Limpiar" *ngIf="entitySelect" (click)="selectValue(null)">
      <mat-icon>clear</mat-icon>
      </button>
</mat-form-field>
<!-- <mat-checkbox [formControl]="disabledDocumento" class="example-disabled-checkbox">
    Mostrar Documento
</mat-checkbox> -->
