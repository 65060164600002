<div class="row" *appRole="['MisGarantias.Modificar']">
  <div class="col-lg-4">
    <ngx-spinner
      name="spInquilino"
      bdColor="rgba(255,255,255,0.8)"
      size="small"
      color="#009688"
      type="line-scale"
      [fullScreen]="false"
    ></ngx-spinner>

    <mat-card *ngIf="personaDetailDto">
      <mat-card-subtitle>Datos inquilino</mat-card-subtitle>
      <mat-label
        ><b>Nombre completo:</b> {{ personaDetailDto.nombreCompleto }}</mat-label
      >
      <br />
      <mat-label
        ><b>Nro. Documento:</b> {{ personaDetailDto.nroDocumento }}</mat-label
      >
    </mat-card>
  </div>
  <div class="col-lg-4">
    <ngx-spinner
      name="spGarante"
      bdColor="rgba(255,255,255,0.8)"
      size="small"
      color="#009688"
      type="line-scale"
      [fullScreen]="false"
    ></ngx-spinner>
    <mat-card *ngIf="garantiaDto">
      <mat-card-subtitle>Persona garante</mat-card-subtitle>
      <div class="row">
        <div class="col-9">
          <mat-label
            ><b>Nombre completo:</b>
            {{ garantiaDto.persona.nombreCompleto }}</mat-label
          >
          <br />
          <mat-label
            ><b>Nro. Documento:</b>
            {{ garantiaDto.persona.nroDocumento }}</mat-label
          >
        </div>
        <div class="col-3">
          <button
            mat-stroked-button
            color="primary"
            (click)="editaPersonaGarante(garantiaDto.persona.id)"
          >
            Editar
          </button>
        </div>
      </div>
    </mat-card>
  </div>
  <!-- <div class="col-lg-4">
    <ngx-spinner
      name="spGarante"
      bdColor="rgba(255,255,255,0.8)"
      size="small"
      color="#009688"
      type="line-scale"
      [fullScreen]="false"
    ></ngx-spinner>
    <div *appRole="['Documentos.Ver']">
      <mat-card *ngIf="garantiaDto">

        <mat-card-subtitle>Documentos Garantia</mat-card-subtitle>
        <button
          mat-raised-button
          color="primary"
          name="VerDocumento"
          (click)="editaDocumentosGarante(garantiaDto.persona.id)"
        >
          <mat-icon>folder</mat-icon>
          Ver Documentos
        </button>
      </mat-card>
    </div>

  </div> -->
</div>
<br />
<br />

<mat-card *appRole="['MisGarantias.Modificar']">
  <ngx-spinner
    name="spGarante"
    bdColor="rgba(255,255,255,0.8)"
    size="small"
    color="#009688"
    type="line-scale"
    [fullScreen]="false"
  ></ngx-spinner>
  <mat-card-subtitle>Datos garantia</mat-card-subtitle>
  <div class="row">
    <div class="form-group col-md-3">
      <form [formGroup]="formTipoGarantia">
        <mat-form-field>
          <mat-label>Tipo de Garantia</mat-label>
          <mat-select
            (selectionChange)="selectTipoGarantia($event.value)"
            formControlName="TipoGarantia"
          >
            <mat-option
              [value]="tipoGarantia.id"
              *ngFor="let tipoGarantia of lstTiposGarantiaModel;let i=index;trackBy:trackByItems"
            >
              {{ tipoGarantia.descripcion }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form>
    </div>
    <div class="form-group col-md-3" *ngIf="mostrarPersona">
      <form [formGroup]="formPersonaGarantia">
        <mat-form-field>
          <mat-label>Empleador</mat-label>
          <input
            matInput
            name="Empleador"
            type="text "
            formControlName="Empleador"
          />
          <mat-error *ngIf="fPer.Empleador.errors?.required"
            >Este campo es <strong>requerido</strong>.</mat-error
          >
        </mat-form-field>
      </form>
    </div>
    <div class="form-group col-md-3" *ngIf="mostrarPersona">
      <form [formGroup]="formPersonaGarantia">
        <mat-form-field class="example-full-width">
          <mat-label>Empleador CUIT/CUIL</mat-label>
          <input
            matInput
            name="CuitCuil"
            type="text"
            formControlName="CuitCuil"
            [mask]="'00-00000000-0'" placeholder="00-00000000-0"
          />
          <mat-error *ngIf="fPer.CuitCuil.errors?.pattern"
            >Formato invalido.</mat-error
          >
        </mat-form-field>
      </form>
    </div>
    <div class="form-group col-md-3" *ngIf="mostrarPersona">
      <form [formGroup]="formPersonaGarantia">
        <mat-form-field class="example-full-width">
          <mat-label>Sueldo</mat-label>
          <span matPrefix>$ &nbsp;</span>
          <input
            matInput
            name="Sueldo"
            placeholder="0.00"
            type="number"
            formControlName="Sueldo"
          />
          <mat-error *ngIf="fPer.Sueldo.errors?.pattern"
            >Formato invalido.</mat-error
          >
        </mat-form-field>
      </form>
    </div>
    <div class="form-group col-md-3" *ngIf="mostrarPropiedad">
      <form [formGroup]="formPropiedad">
        <mat-form-field>
          <mat-label>Tipo de Folio</mat-label>
          <mat-select
            (selectionChange)="selectTipoFolio($event.value)"
            formControlName="TipoFolio"
          >
            <mat-option
              [value]="tipoFolio.id"
              *ngFor="let tipoFolio of lstTiposFolios;let i=index;trackBy:trackByItems"
            >
              {{ tipoFolio.descripcion }}</mat-option
            >
          </mat-select>

          <mat-error *ngIf="fPro.TipoFolio.errors?.required"
            >Este campo es <strong>requerido</strong>.</mat-error
          >
        </mat-form-field>
      </form>
    </div>
    <div
      class="form-group col-md-3"
      *ngIf="mostrarPropiedad && idTipoFolio === 1"
    >
      <form [formGroup]="formPropiedad">
        <mat-form-field class="example-full-width">
          <mat-label>Tomo</mat-label>
          <input matInput name="Tomo" type="text" formControlName="Tomo" />
        </mat-form-field>
      </form>
    </div>

    <div
      class="form-group col-md-3"
      *ngIf="(mostrarPropiedad && idTipoFolio === 1) || idTipoFolio === 2"
    >
      <form [formGroup]="formPropiedad">
        <mat-form-field class="example-full-width">
          <mat-label>NroFolio</mat-label>
          <input
            matInput
            name="NroFolio"
            type="text"
            formControlName="NroFolio"
          />
          <mat-error *ngIf="fPro.NroFolio.errors?.required"
            >Este campo es <strong>requerido</strong>.</mat-error
          >
        </mat-form-field>
      </form>
    </div>

    <div
      class="form-group col-md-3"
      *ngIf="mostrarPropiedad && idTipoFolio === 1"
    >
      <form [formGroup]="formPropiedad">
        <mat-form-field class="example-full-width">
          <mat-label>Numero</mat-label>
          <input matInput name="Numero" type="text" formControlName="Numero" />
        </mat-form-field>
      </form>
    </div>

    <div class="form-group col-md-3" *ngIf="mostrarPropiedad">
      <form [formGroup]="formPropiedad">
        <mat-form-field class="example-full-width">
          <mat-label>Ubicacion</mat-label>
          <input
            matInput
            name="Ubicacion"
            type="text"
            formControlName="Ubicacion"
          />
        </mat-form-field>
      </form>
    </div>

    <div class="form-group col-md-3" *ngIf="mostrarPropiedad">
      <form [formGroup]="formPropiedad">
        <mat-form-field class="example-full-width">
          <mat-label>Localidad</mat-label>
          <input
            matInput
            name="Localidad"
            type="text"
            formControlName="Localidad"
          />
        </mat-form-field>
      </form>
    </div>
    <div class="form-group col-md-3" *ngIf="mostrarPropiedad">
      <form [formGroup]="formPropiedad">
        <mat-form-field class="example-full-width">
          <mat-label>Indivisa</mat-label>
          <span matPrefix>% &nbsp;</span>
          <input
            matInput
            appPercentageDirective
            min="1"
            max="100"
            [(ngModel)]="indivisa"
            placeholder="100"
            name="Indivisa"
            type="number"
            formControlName="Indivisa"
          />
          <mat-hint>Dueño de propiedad al %{{ indivisa }}</mat-hint>
        </mat-form-field>
      </form>
    </div>

    <div class="form-group col-md-3" *ngIf="mostrarFianza">
      <form [formGroup]="formFianza">
        <mat-form-field>
          <mat-label>Razon Social</mat-label>
          <input
            matInput
            name="RazonSocial"
            type="text "
            formControlName="RazonSocial"
          />
          <mat-error *ngIf="fTL.RazonSocial.errors?.required"
            >Este campo es <strong>requerido</strong>.</mat-error
          >
        </mat-form-field>
      </form>
    </div>
    <div class="form-group col-md-3" *ngIf="mostrarFianza">
      <form [formGroup]="formFianza">
        <mat-form-field class="example-full-width">
          <mat-label>CUIT</mat-label>
          <input
            matInput
            name="Cuit"
            type="text"
            placeholder="00-00000000-0"
            formControlName="Cuit"
          />
          <mat-error *ngIf="fTL.Cuit.errors?.pattern"
            >Formato invalido.</mat-error
          >
        </mat-form-field>
      </form>
    </div>
  </div>
</mat-card>

<br />
<div class="col-auto my-1" *appRole="['MisGarantias.Modificar']">
    <button
      mat-raised-button
      color="primary"
      style="margin-right: 3px"
      *ngIf="mostrarPropiedad"
      name="Guardar"
      [disabled]="!formPropiedad.valid"
      (click)="onSubmit()"
    >
      Guardar
    </button>
    <button
      mat-raised-button
      color="primary"
      style="margin-right: 3px"
      *ngIf="mostrarPersona"
      name="Guardar"
      [disabled]="!formPersonaGarantia.valid"
      (click)="onSubmit()"
    >
      Guardar
    </button>
    <button
      mat-raised-button
      color="primary"
      style="margin-right: 3px"
      *ngIf="mostrarFianza"
      name="Guardar"
      [disabled]="!formFianza.valid"
      (click)="onSubmit()"
    >
      Guardar
    </button>

  <button
    mat-button
    color="warn"
    style="margin-right: 3px"
    name="Cancelar"
    [routerLink]="['/garantias', 'misGarantias']"
  >
    Cancelar
  </button>
</div>
