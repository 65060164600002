<button mat-icon-button class="close-button" [mat-dialog-close]="true">
        <mat-icon class="close-icon" color="warn">close</mat-icon>
      </button>
<ngx-spinner name="spDetServ" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false"></ngx-spinner>

<mat-card-title>
    <h2>Nuevo detalle servicio
        <!-- <mat-icon matSuffix #tooltip="matTooltip" matTooltipPosition="below" matTooltip="Periocidad: Se realiza la carga de todos los periodos posteriores al periodo mes hasta finalizar el perido año.
            Las fechas de vencimiento para los meses correlativos se ajustan automaticamente.
            Las fechas de vencimiento siempre deben ir posteriores al periodo mes. Ej: Periodo mes 1, Periodo año 2021 y Fecha vencimiento 10/02/2021.">info</mat-icon> -->
    </h2>
    <!-- <div style="font-size:1rem; display: inline-flex; align-items: center;" *ngIf="lstservicioPropiedadDto.length ==0 " class="alert alert-warning alert-dismissible fade show" role="alert">
      <mat-icon>info </mat-icon>&nbsp;
      Recuerde cargar los servicios previamente.
      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> -->
</mat-card-title>

<mat-dialog-content cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
    <form [formGroup]="formDetalleServicioPriopidad">
        <div class="row">
            <div class="form-group col-md-6">

                <mat-form-field>
                    <ngx-spinner name="spBusquedaServicios" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>

                    <mat-label>Servicios</mat-label>
                    <mat-select placeholder="Seleccione opcion" formControlName="Servicios">
                        <mat-option cdkFocusInitial [value]="servicioPropiedadDto.id" *ngFor="let servicioPropiedadDto of lstservicioPropiedadDto ;let i=index;trackBy:trackByItems">
                            {{servicioPropiedadDto.servicio.descripcion}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="f.Servicios.errors?.required">Este campo es <strong>requerido</strong>.
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="form-group col-md-6">
                <mat-form-field>
                    <ngx-spinner name="spBusquedaQuienPaga" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>

                    <mat-label>Quien Paga</mat-label>
                    <mat-select formControlName="QuienPaga" (selectionChange)="selectQuienPaga($event)">
                        <mat-option [value]="quienPaga.id" *ngFor="let quienPaga of lstQuienPaga ;let i=index;trackBy:trackByItems">
                            {{quienPaga.descripcion}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="f.QuienPaga.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="form-group col-md-6">
                <mat-form-field class="example-full-width">
                    <mat-label>Comision</mat-label>
                    <span matPrefix>% &nbsp;</span>
                    <input matInput name="Comision" appPercentageDirective min="1" max="100" [(ngModel)]="comision" type="text" formControlName="Comision" />
                </mat-form-field>
            </div>
            <div class="form-group col-md-6">
                <mat-form-field>
                    <mat-label>Periocidad</mat-label>
                    <mat-select formControlName="Periocidad" (selectionChange)="selectPeriocidad($event)">
                        <mat-option [value]="periocidad.id" *ngFor="let periocidad of lstPeriocidad ;let i=index;trackBy:trackByItems">
                            {{periocidad.descripcion}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="f.Periocidad.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                    <mat-icon matSuffix #tooltip="matTooltip" matTooltipPosition="above" matTooltip="Solo aplica para montos fijos.">info</mat-icon>

                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-6">
                <div class="row">
                    <div class="form-group col-md-6">
                        <mat-form-field class="full-width-x0">
                            <mat-label>Periodo Mes</mat-label>
                            <input matInput name="Mes" type="number" maxlength="2" formControlName="Mes" />
                            <mat-error *ngIf="f.Mes.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="form-group col-md-6">
                        <mat-form-field class="full-width-x0">
                            <mat-label>Periodo Año</mat-label>
                            <input matInput name="Anio" type="number" maxlength="4" formControlName="Anio" />
                            <mat-error *ngIf="f.Anio.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="form-group col-md-6">
                <mat-form-field class="example-full-width">
                    <mat-label>Periodo/Cuota</mat-label>
                    <input matInput name="Periodo" type="number" maxlength="2" formControlName="Periodo" />
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-6">
                <mat-form-field class="example-full-width">
                    <mat-label>1er Vencimiento</mat-label>
                    <input matInput name="Vencimiento1" type="date" formControlName="Vencimiento1" (input)="onVencimiento1Change($event,1)" />
                    <mat-error *ngIf="f.Vencimiento1.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                </mat-form-field>
            </div>
            <div class="form-group col-md-6">
                <mat-form-field class="example-full-width">
                    <mat-label>Importe</mat-label>
                    <span matPrefix>$ &nbsp;</span>
                    <input matInput name="ImporteVencimiento1" type="text" mask="separator.2" thousandSeparator="," formControlName="ImporteVencimiento1" />
                    <mat-error *ngIf="f.ImporteVencimiento1.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="form-group col-md-6">
                <mat-form-field class="example-full-width">
                    <mat-label>2do Vencimiento</mat-label>
                    <input matInput name="Vencimiento2" type="date" formControlName="Vencimiento2" (input)="onVencimiento1Change($event,2)" />
                </mat-form-field>
            </div>
            <div class="form-group col-md-6">
                <mat-form-field class="example-full-width">
                    <mat-label>Importe</mat-label>
                    <span matPrefix>$ &nbsp;</span>
                    <input matInput name="ImporteVencimiento2" type="text" mask="separator.2" thousandSeparator="," formControlName="ImporteVencimiento2" />
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-6">
                <mat-form-field class="example-full-width">
                    <mat-label>3er Vencimiento</mat-label>
                    <input matInput name="Vencimiento3" type="date" formControlName="Vencimiento3" (input)="onVencimiento1Change($event,3)" />
                </mat-form-field>
            </div>
            <div class="form-group col-md-6">
                <mat-form-field class="example-full-width">
                    <mat-label>Importe</mat-label>
                    <span matPrefix>$ &nbsp;</span>
                    <input matInput name="ImporteVencimiento3" type="text" mask="separator.2" thousandSeparator="," formControlName="ImporteVencimiento3" />
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-6">
                <mat-form-field class="example-full-width">
                    <mat-label>4to Vencimiento</mat-label>
                    <input matInput name="Vencimiento4" type="date" formControlName="Vencimiento4" (input)="onVencimiento1Change($event,4)" />
                </mat-form-field>
            </div>
            <div class="form-group col-md-6">
                <mat-form-field class="example-full-width">
                    <mat-label>Importe</mat-label>
                    <span matPrefix>$ &nbsp;</span>
                    <input matInput name="ImporteVencimiento4" type="text" mask="separator.2" thousandSeparator="," formControlName="ImporteVencimiento4" />
                </mat-form-field>
            </div>
        </div>

    </form>
</mat-dialog-content>
<mat-divider></mat-divider>
<div mat-dialog-actions>
    <button mat-raised-button *appRole="['DetalleServicios.Crear']" (click)="agregarDetalle()" [disabled]="!formDetalleServicioPriopidad.valid" color="primary">Agregar</button>
    <button mat-button (click)="closeDialog()" mat-dialog-close color="warn">Cancelar</button>
</div>