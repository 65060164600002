<div class="position-relative">
  <button mat-icon-button class="close-button" [mat-dialog-close]="true">
    <mat-icon class="close-icon" color="warn">close</mat-icon>
  </button>
  <h1 mat-dialog-title>Actualiza montos por broche</h1>
  <div mat-dialog-content cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
    <ngx-spinner name="spEjecutaProceso" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale"
      [fullScreen]="false"></ngx-spinner>
    <form [formGroup]="formActualizaMontosBroches">
      <div class="row">
        <div class="form-group col-md-6">
          <mat-form-field>
            <ngx-spinner name="spServicios" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688"
              type="line-scale" [fullScreen]="false"></ngx-spinner>
            <mat-label>Servicios</mat-label>
            <mat-select required #SelectServicio placeholder="Seleccione opcion" formControlName="Servicios"
              (selectionChange)="selectServicio($event)">
              <mat-option cdkFocusInitial [value]="servicio.id"
                *ngFor="let servicio of lstServicios ;let i=index;trackBy:trackByItems">
                {{servicio.tipoServicio.descripcion}}-{{servicio.nroBroche}}</mat-option>
            </mat-select>
            <mat-error *ngIf="f.Servicios.errors?.required">Este campo es <strong>requerido</strong>.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form-group col-md-6">
          <mat-form-field class="example-full-width">
            <mat-label>Diferencia</mat-label>
            <span matPrefix>% &nbsp;</span>
            <input matInput name="Diferencia" type="text" mask="separator.4" formControlName="Diferencia" #tooltip="matTooltip"
              matTooltipPosition="above" matTooltip="Diferencia a aumentar para periodo destino." />
            <mat-error *ngIf="f.Diferencia.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-6">
          <mat-form-field class="example-full-width">
            <mat-label>Periodo Origen</mat-label>
            <input matInput name="PeriodoOrigen" placeholder="mm/aaaa" type="text" maxlength="7"
              formControlName="PeriodoOrigen" (change)="validaFecha()" />
            <mat-error *ngIf="f.PeriodoOrigen.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
            <mat-error *ngIf="f.PeriodoOrigen.errors?.pattern">Fecha con formato <strong>incorrecto</strong>.
            </mat-error>
            <mat-error *ngIf="f.PeriodoOrigen.errors?.fechaInvalida">Fecha invalida.
            </mat-error>
          </mat-form-field>
        </div>

        <div class="form-group col-md-6">
          <mat-form-field class="example-full-width">
            <mat-label>Periodo Destino</mat-label>
            <input matInput name="PeriodoDestino" type="text" (change)="validaFecha()" placeholder="mm/aaaa"
              maxlength="7" formControlName="PeriodoDestino" />
            <mat-error *ngIf="f.PeriodoDestino.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
            <mat-error *ngIf="f.PeriodoDestino.errors?.pattern">Fecha con formato <strong>incorrecto</strong>.
            </mat-error>
            <mat-error *ngIf="f.PeriodoDestino.errors?.fechaInvalida">Fecha invalida.
            </mat-error>
           <mat-error *ngIf="f.PeriodoDestino.errors?.fechaFueraRango">Fecha fuera de rango.
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-6">
          <mat-form-field class="example-full-width">
            <mat-label>1er Vencimiento</mat-label>
            <input matInput name="Vencimiento1" type="date" formControlName="Vencimiento1" />
            <mat-error *ngIf="f.Vencimiento1.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
          </mat-form-field>
        </div>
        <div class="form-group col-md-6">
          <mat-form-field class="example-full-width">
            <mat-label>2do Vencimiento</mat-label>
            <input matInput name="Vencimiento2" type="date" formControlName="Vencimiento2" />
            <mat-error *ngIf="f.Vencimiento1.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-6">
          <mat-form-field class="example-full-width">
            <mat-label>3er Vencimiento</mat-label>
            <input matInput name="Vencimiento3" type="date" formControlName="Vencimiento3" />
          </mat-form-field>
        </div>
        <div class="form-group col-md-6">
          <mat-form-field class="example-full-width">
            <mat-label>4to Vencimiento</mat-label>
            <input matInput name="Vencimiento4" type="date" formControlName="Vencimiento4" />
          </mat-form-field>
        </div>
      </div>

    </form>
  </div>
  <mat-divider></mat-divider>
  <div mat-dialog-actions>
    <button mat-raised-button *appRole="['DetalleServicios.ActualizarBroches']" (click)="agregarDetalle()"
      [disabled]="!formActualizaMontosBroches.valid || isLoading" color="primary">
      <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      Actualizar</button>
    <button mat-button (click)="closeDialog()" color="warn">Cancelar</button>
  </div>

</div>
