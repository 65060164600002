import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccionesDialog } from '@models/base/identificadores.model';
import { CreateCuentaContableRequest } from '@models/cuentas-contables/createCuentaContableRequest.model';
import { CuentaContableDto } from '@models/cuentas-contables/cuentaContableDto.model';
import { UpdateCuentaContableRequest } from '@models/cuentas-contables/updateCuentaContableRequest.model';
import { CuentasContablesService } from '@services/cuentas-contables/cuentas-contables.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { isFalsy } from 'utility-types';
import { DialogCuentaContableComponent } from '../dialog-cuenta-contable/dialog-cuenta-contable.component';

@Component({
  selector: 'app-cuentas-contables',
  templateUrl: './cuentas-contables.component.html',
  styleUrls: ['./cuentas-contables.component.css']
})
export class CuentasContablesComponent implements OnInit {
  data = false
  dataSource = new MatTableDataSource<CuentaContableDto>();
  cuentaContable: CuentaContableDto = new CuentaContableDto
  @Input() idPersonas: number = 0
  displayedColumns: string[] = [];

  @ViewChild(MatTable, { static: true }) table!: MatTable<any>;

  private paginator!: MatPaginator;
  private sort!: MatSort;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    if (this.paginator && this.sort) {
      this.applyFilter(null);
    }
  }

  constructor(
    public dialog: MatDialog,
    private cuentasContablesService: CuentasContablesService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.displayedColumns = result.matches ?
        ['descripcion', 'accion'] :
        ['descripcion', 'banco', 'numero', 'tipo', 'cbu', 'alias', 'accion'];
    });
  }


  ngOnInit() {

  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.getAllCuentasContables();
  }

  getAllCuentasContables() {
    this.spinner.show()
    this.cuentasContablesService.getAll()
      .subscribe(
        data => {
          this.spinner.hide()
          this.dataSource.data = data
          this.data = this.dataSource.data.length >= 1 ? true : false
        },
        error => {
          this.spinner.hide()
        }
      )
  }



  openDialog(action: any, cuentaContableDto: CuentaContableDto) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    switch (action) {
      case AccionesDialog.Agregar:
        dialogConfig.width = "80%"
        break;
      case AccionesDialog.Modificar:
        dialogConfig.width = "80%"
        break;
      case AccionesDialog.Eliminar:
        dialogConfig.width = "30%"
        break;
      default:
        break;
    }

    dialogConfig.data = { action, cuentaContableDto }


    const dialogRef = this.dialog.open(DialogCuentaContableComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event != AccionesDialog.Cancelar)
        this.getAllCuentasContables()
    });
  }

  applyFilter(event: any) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  addRowData(cuentaContableDto: CuentaContableDto) {
    this.spinner.show()
    let createCuentaContableRequest = new CreateCuentaContableRequest
    createCuentaContableRequest.descripcion = cuentaContableDto.descripcion
    createCuentaContableRequest.numero = isFalsy(cuentaContableDto.numero) ? null : cuentaContableDto.numero
    createCuentaContableRequest.tipo = isFalsy(cuentaContableDto.tipo)? null : cuentaContableDto.tipo
    createCuentaContableRequest.cbu = isFalsy(cuentaContableDto.cbu) ? null : cuentaContableDto.cbu
    createCuentaContableRequest.alias = isFalsy(cuentaContableDto.alias) ? null : cuentaContableDto.alias
    createCuentaContableRequest.idBanco = isFalsy(cuentaContableDto.banco) ? null : cuentaContableDto.banco.id
    createCuentaContableRequest.fechaBaja = isFalsy(cuentaContableDto.fechaBaja) ? null : cuentaContableDto.fechaBaja


    if (createCuentaContableRequest) {
      this.cuentasContablesService.create(createCuentaContableRequest)
        .subscribe(
          data => {
            this.spinner.hide()
            let cc = new CuentaContableDto
            cc.id = data.id
            cc.descripcion = cuentaContableDto.descripcion
            cc.alias = cuentaContableDto.alias
            cc.banco = cuentaContableDto.banco
            cc.cbu = cuentaContableDto.cbu
            cc.fechaBaja = cuentaContableDto.fechaBaja
            cc.numero = cuentaContableDto.numero
            cc.tipo = cuentaContableDto.tipo

            this.dataSource.data.push(cc);
            this.table.renderRows();
            this.dataSource.data = this.dataSource.data.filter((value: any, key: any) => {
              return true;
            });
            this.data = this.dataSource.data.length >= 1 ? true : false
            this.snackBar.showSuccess("Registro agregado correctamente.", "Exito");
            this.getById(data.id)
          },
          error => {
            this.spinner.hide()
            this.snackBar.showError(error, "Error");
          }
        )
    }

  }

  updateRowData(cuentaContableDto: CuentaContableDto) {
    this.spinner.show()
    let updateCuentaContableRequest = new UpdateCuentaContableRequest
    updateCuentaContableRequest.id = isFalsy(cuentaContableDto.id) ? 0 : cuentaContableDto.id
    updateCuentaContableRequest.descripcion = cuentaContableDto.descripcion
    updateCuentaContableRequest.numero = isFalsy(cuentaContableDto.numero) ? null : cuentaContableDto.numero
    updateCuentaContableRequest.tipo = isFalsy(cuentaContableDto.tipo) ? null : cuentaContableDto.tipo
    updateCuentaContableRequest.cbu = isFalsy(cuentaContableDto.cbu) ? null : cuentaContableDto.cbu
    updateCuentaContableRequest.alias = isFalsy(cuentaContableDto.alias)  ? null : cuentaContableDto.alias
    updateCuentaContableRequest.idBanco = isFalsy(cuentaContableDto.banco) ? null : cuentaContableDto.banco.id
    updateCuentaContableRequest.fechaBaja = isFalsy(cuentaContableDto.fechaBaja) ? null : cuentaContableDto.fechaBaja


    if (updateCuentaContableRequest) {
      this.cuentasContablesService.update(updateCuentaContableRequest)
        .subscribe(
          data => {
            this.spinner.hide()
            this.data = this.dataSource.data.length >= 1 ? true : false
            this.snackBar.showSuccess("Registro actualizado correctamente.", "Exito");
          },
          error => {
            this.spinner.hide()
            this.snackBar.showError(error, "Error");
          })
    }
  }


  deleteRowData(row_obj: { id: number; }) {
    this.cuentasContablesService.delete(row_obj.id)
      .subscribe(
        data => {
          this.spinner.hide()
          this.dataSource.data = this.dataSource.data.filter((value: { id: number; }, key: any) => {
            return value.id != row_obj.id;
          });
          this.data = this.dataSource.data.length >= 1 ? true : false
          this.snackBar.showSuccess("Registro dado de baja correctamente.", "Exito");
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError("El registro no se puede eliminar porque tiene referencias asignadas.", "Error");
        })

  }

  getById(id: number) {
    this.spinner.show()
    this.cuentasContablesService.getById(id)
      .subscribe(
        data => {
          this.spinner.hide()
          this.cuentasContablesService.enviarCuentaContable(data)
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        })
  }

  isFalsy(valor:any){
    return isFalsy(valor)
  }

}
