import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, Pipe, PipeTransform, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { MatSelectionList, MatSelectionListChange } from '@angular/material/list';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { BaseDto } from '@models/base/baseDto.model';
import { Genericos } from '@models/base/genericos.model';
import { AccionesDialog, EstadosDetalleServicioPropiedad, RangoFechasValores } from '@models/base/identificadores.model';
import { PropiedadDetalleServicioBasicDto } from '@models/comprobantes/propiedadDetalleServicioBasicDto.model';
import { DetalleServicioPropiedadGroupByServicioPropiedadResponse } from '@models/servicios/detalle-servicio-propiedad/detalleServicioPropiedadGroupByServicioPropiedadResponse.model';
import { IdDetalleServicioIdEstadoDto } from '@models/servicios/detalle-servicio-propiedad/idDetalleServicioIdEstadoDto.model';
import { UpdateStatusRequest } from '@models/servicios/detalle-servicio-propiedad/updateStatusRequest.model';
import { ServicioPropiedadDetailDto } from '@models/servicios/servicio-propiedad/servicioPropiedadDetailDto.model';
import { ServicioDto } from '@models/servicios/servicio/servicioDto.model';
import { DetalleServicioPropiedadService } from '@services/servicios/detalle-servicios-propiedad/detalle-servicio-propiedad.service';
import { EstadoDetalleServicioPropiedadService } from '@services/servicios/estadoDetalleServicioPropiedad/estado-detalle-servicio-propiedad.service';
import { ServiciosService } from '@services/servicios/servicios/servicios.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { isFalsy } from 'utility-types';
import { PropiedadServicio } from '@views/detalle-servicios-propiedad/listado-detalle-servicio-por-propiedad/listado-detalle-servicio-por-propiedad.component';
import { DialogEditarDetalleServicioComponent } from '@views/detalle-servicios-propiedad/dialog-editar-detalle-servicio/dialog-editar-detalle-servicio.component';
import { DialogDeleteComponent } from '@views/genericos/dialog-delete/dialog-delete.component';
import { QuienPagaService } from '@services/quien-paga/quien-paga.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Moment } from 'moment';
import { DateRangePickerComponent } from '@helpers/DateRangePicker/DateRangePicker.component';

export class VistaServicios {
  public id: number = 0
  public idServicio: number = 0
  public descripcionServicio: string = ""
  public nroBroche: string = ""
  public propiedadId: number = 0
  public propiedadPiso: string = ""
  public propiedadDepto: string = ""
  public propiedadUbicacion: string = ""
  public propiedadLocalidad: string = ""
  public propiedadProvincia: string = ""
  public dspId: number = 0
  public dspImporte: number = 0
  public dspPeriodoMes: number = 0
  public dspPeriodoAnio: number = 0
  public dspPeriodo: string = ""
  public dspCuenta: string = ""
  public dspEstadoId: number = 0
  public dspQuienPaga: string = ""
  public dspEstadoDescripcion: string = ""
}

@Component({
  selector: 'app-listado-detalle-servicio-propiedad',
  templateUrl: './listado-detalle-servicio-propiedad.component.html',
  styleUrls: ['./listado-detalle-servicio-propiedad.component.css'],
  providers: [DatePipe]
})

export class ListadoDetalleServicioPropiedadComponent implements OnInit {
  panelOpenState = false;
  vistaAgrupada = false
  vistaSimple = false
  vistaTabla = true
  lstRangoFecha = Genericos.lstRangoFecha
  data = false

  form: FormGroup;
  formBuilder: FormBuilder = new FormBuilder;
  expandeTodo: boolean = false;
  detalleServicioPropiedadGroupByServicioPropiedadResponse: DetalleServicioPropiedadGroupByServicioPropiedadResponse = new DetalleServicioPropiedadGroupByServicioPropiedadResponse;
  lstServicio!: ServicioDto[];
  lstEstadoDetalleServicioPropiedad!: BaseDto[];
  detallesAPagarVistaSimple: any = []
  detallesAPagarVistaAgrupada: any = []
  idDetalleServiciosIdEstados: IdDetalleServicioIdEstadoDto[] = []
  busquedaRealizda: boolean = false;
  lstQuienPaga!: BaseDto[];
  get f() { return this.form.controls; }
  dataSource = new MatTableDataSource<VistaServicios>();
  lstVistaServicios: VistaServicios[] = []
  selection = new SelectionModel<VistaServicios>(true, []);
  estadosDetalleServicioPropiedad!: typeof EstadosDetalleServicioPropiedad;
  @Input() idPropiedad: number = 0
  @Input() update: boolean = false
  displayedColumns: string[] = [];
  rangoFecha: number = RangoFechasValores.Bimestral
  checkedFiltroAvanzado: boolean = false;
  fechasPeriodoSeleccionadas: { desde: Moment | null, hasta: Moment | null } | null = null;
  resetHijo: boolean = false; // Propiedad para indicar el reset en el hijo
  @ViewChild(DateRangePickerComponent) dateRangePickerComponent!: DateRangePickerComponent;
  constructor(
    private detalleServicioPropiedadService: DetalleServicioPropiedadService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    private serviciosService: ServiciosService,
    private quienPagaService: QuienPagaService,
    private datepipe: DatePipe,
    private breakpointObserver: BreakpointObserver,
    private estadoDetalleServicioPropiedadService: EstadoDetalleServicioPropiedadService,
  ) {

    this.form = this.formBuilder.group({
      FechaDesde: [new Date((new Date().getTime() - RangoFechasValores.Bimestral)), Validators.compose([Validators.required,])],
      FechaHasta: [new Date(), Validators.compose([Validators.required,])],
      RangoFecha: [''],
      FechaPeriodoDesde: [''],
      FechaPeriodoHasta: [''],
      Servicio: [''],
      EstadoDetalleServicioPropiedad: [''],
      QuienPaga: [''],
    })


    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.displayedColumns = result.matches ?
        ["select", "descripcionServicio", "dspEstadoDescripcion", "dspImporte", "dspAccion"] :
        ["select", "descripcionServicio", "dspCuenta", "propiedadUbicacion", "periodo", "dspImporte", "dspQuienPaga", "dspEstadoDescripcion", "dspAccion"];
    });

  }

  @ViewChild(MatSelectionList) public matSelectListDetSerPro!: MatSelectionList;

  private paginator!: MatPaginator;
  private sort!: MatSort;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    if (this.paginator && this.sort) {
      this.applyFilter(null);
    }
  }

  applyFilter(event: any) {
    const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();
    this.dataSource.filter = filterValue;

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }

    // Personalizar el predicado de filtro para la ordenación
    this.dataSource.filterPredicate = (data, filter) => {
      const transformedData = (data.propiedadUbicacion + '').toLowerCase(); // Ajusta 'columnToFilter' según tus necesidades
      return transformedData.includes(filter);
    };
  }

  @ViewChild(MatAccordion) accordion!: MatAccordion;

  ngOnInit(): void {

    this.estadosDetalleServicioPropiedad = EstadosDetalleServicioPropiedad
    this.getEstadoDetalleServicioPropiedadService()
    this.getAllServicios();
    this.getAllQuienPaga();
    this.form.controls["RangoFecha"].setValue(RangoFechasValores.Bimestral)
    this.form.controls["EstadoDetalleServicioPropiedad"].setValue(0)
    this.form.controls["QuienPaga"].setValue(0)
    this.form.controls["Servicio"].setValue(0)
    if (this.idPropiedad != 0)
      this.buscarServicios()

    this.dataSource.sortingDataAccessor = (data: VistaServicios, sortHeaderId: string) => {
      switch (sortHeaderId) {
        case 'descripcionServicio':
          return data.descripcionServicio;
        case 'dspCuenta':
          return data.dspCuenta;
        case 'propiedadUbicacion':
          return data.propiedadUbicacion;
        case 'dspPeriodoMes':
          return data.dspPeriodoMes;
        case 'dspPeriodoAnio':
          return data.dspPeriodoAnio;
        case 'dspPeriodo':
          return data.dspPeriodo;
        case 'dspImporte':
          return data.dspImporte;
        case 'dspQuienPaga':
          return data.dspQuienPaga;
        case 'dspEstadoDescripcion':
          return data.dspEstadoDescripcion;
        case 'dspEstadoId':
          return data.dspEstadoId;
        case 'propiedadId':
          return data.propiedadId;
        case 'propiedadLocalidad':
          return data.propiedadLocalidad;
        case 'propiedadProvincia':
          return data.propiedadProvincia;
        case 'propiedadPiso':
          return data.propiedadPiso;
        case 'propiedadDepto':
          return data.propiedadDepto;
        default:
          return '';
      }
    };

    this.dataSource.sortData = (data, sort) => {
      const active = sort.active;
      const direction = sort.direction;
      if (!active || direction === '') {
        return data;
      }

      return data.sort((a, b) => {
        let valueA = this.dataSource.sortingDataAccessor(a, active) as string;
        let valueB = this.dataSource.sortingDataAccessor(b, active) as string;

        let comparatorResult = 0;
        if (active === 'dspCuenta') {
          comparatorResult = this.alphanumericSort(valueA, valueB);
        } else {
          comparatorResult = valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
        }

        return direction === 'asc' ? comparatorResult : -comparatorResult;
      });
    };

    this.dataSource.sort = this.sort;
  }





  filtrosAvanzados(event: any) {
    if (event.checked == true)
      this.checkedFiltroAvanzado = true
    else
      this.checkedFiltroAvanzado = false
    this.form.controls["FechaPeriodoDesde"].setValue('');
    this.form.controls["FechaPeriodoHasta"].setValue('');
  }

  clearFilter() {
    this.onBorrarFechas();
  }
  onBorrarFechas() {
    this.form.controls["FechaPeriodoDesde"].setValue('');
    this.form.controls["FechaPeriodoHasta"].setValue('');
    this.form.controls["FechaDesde"].setValue('');
    this.form.controls["FechaHasta"].setValue('');
  }

  alphanumericSort(a: string, b: string): number {
    return a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  selectRangoFecha(event: any) {
    this.rangoFecha = event.value
    if (this.rangoFecha == RangoFechasValores.Todos) {
      this.form.controls["FechaDesde"].setValue(new Date((new Date().getTime() - this.rangoFecha)))
      this.form.controls["FechaHasta"].setValue(new Date((new Date().getTime() + this.rangoFecha)))
    }
    else {
      this.form.controls["FechaDesde"].setValue(new Date((new Date().getTime() - this.rangoFecha)))
      this.form.controls["FechaHasta"].setValue(new Date())
    }

  }

  selectRangoFechaPeriodo(event: any) {
    this.rangoFecha = event.value;

    // Obtener la fecha actual
    const fechaActual = new Date();

    if (this.rangoFecha == RangoFechasValores.Todos) {
      // Establecer FechaPeriodoDesde como el primer día del mes correspondiente
      const fechaDesde = new Date(fechaActual.getFullYear(), fechaActual.getMonth() - this.rangoFecha, 1);
      const fechaHasta = new Date(fechaActual.getFullYear(), fechaActual.getMonth() + this.rangoFecha, 0); // Último día del mes siguiente

      this.form.controls["FechaPeriodoDesde"].setValue(this.formatFecha(fechaDesde));
      this.form.controls["FechaPeriodoHasta"].setValue(this.formatFecha(fechaHasta));
    } else {
      // Establecer FechaPeriodoDesde como el primer día del mes correspondiente
      const fechaDesde = new Date(fechaActual.getFullYear(), fechaActual.getMonth() - this.rangoFecha, 1);

      this.form.controls["FechaPeriodoDesde"].setValue(this.formatFecha(fechaDesde));
      this.form.controls["FechaPeriodoHasta"].setValue(this.formatFecha(fechaActual)); // Hasta hoy
    }
  }

  // Método para formatear la fecha como "MM/YYYY"
  private formatFecha(date: Date): string {
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Sumar 1 al mes (0-11)
    const year = date.getFullYear();
    return `${month}/${year}`;
  }

  //#region  Seleccion de Item
  selectDetalleServicioPropiedadVistaAgrupada(changeAgrupado: MatSelectionListChange) {
    if (changeAgrupado.option.selected == true)
      this.detallesAPagarVistaAgrupada.push(changeAgrupado.option.value)
    else {
      let index: number = this.detallesAPagarVistaAgrupada.indexOf(changeAgrupado.option.value);
      if (index !== -1) {
        this.detallesAPagarVistaAgrupada.splice(index, 1);
      }
    }
  }

  selectDetalleServicioPropiedadVistaSimple(changeSimple: MatSelectionListChange) {
    if (changeSimple.option.selected == true)
      this.detallesAPagarVistaSimple.push(changeSimple.option.value)
    else {
      let index: number = this.detallesAPagarVistaSimple.indexOf(changeSimple.option.value);
      if (index !== -1) {
        this.detallesAPagarVistaSimple.splice(index, 1);
      }
    }
  }

  expandeExpansionPanel(expande: boolean) {
    this.expandeTodo = expande
  }

  selectAll() {
    this.matSelectListDetSerPro.selectAll();
  }
  //#endregion

  //#region  Acciones
  changeEstadoDetalleServicioPropiedad() {
    while (this.idDetalleServiciosIdEstados.length) {
      this.idDetalleServiciosIdEstados.pop();
    }

    if (this.vistaAgrupada == true) {
      this.detallesAPagarVistaAgrupada.forEach((element: any) => {
        if (element.dspEstadoId == EstadosDetalleServicioPropiedad.Cobrado) {
          let idDetalleServicioIdEstadoDto = new IdDetalleServicioIdEstadoDto
          idDetalleServicioIdEstadoDto.id_DetalleServicioPropiedad = element.dspId
          idDetalleServicioIdEstadoDto.id_EstadoDetalleServicioPropiedad = EstadosDetalleServicioPropiedad.Pagado
          this.idDetalleServiciosIdEstados.push(idDetalleServicioIdEstadoDto)
        }
        if (element.dspEstadoId == EstadosDetalleServicioPropiedad.Deuda || element.dspEstadoId == EstadosDetalleServicioPropiedad.NoVencido) {
          let idDetalleServicioIdEstadoDto = new IdDetalleServicioIdEstadoDto
          idDetalleServicioIdEstadoDto.id_DetalleServicioPropiedad = element.dspId
          idDetalleServicioIdEstadoDto.id_EstadoDetalleServicioPropiedad = EstadosDetalleServicioPropiedad.PagadoSinCobro
          this.idDetalleServiciosIdEstados.push(idDetalleServicioIdEstadoDto)
        }
      });
    }

    if (this.vistaSimple == true) {
      this.detallesAPagarVistaSimple.forEach((element: any) => {
        if (element.dspEstadoId == EstadosDetalleServicioPropiedad.Cobrado) {
          let idDetalleServicioIdEstadoDto = new IdDetalleServicioIdEstadoDto
          idDetalleServicioIdEstadoDto.id_DetalleServicioPropiedad = element.dspId
          idDetalleServicioIdEstadoDto.id_EstadoDetalleServicioPropiedad = EstadosDetalleServicioPropiedad.Pagado
          this.idDetalleServiciosIdEstados.push(idDetalleServicioIdEstadoDto)
        }
        if (element.dspEstadoId == EstadosDetalleServicioPropiedad.Deuda || element.dspEstadoId == EstadosDetalleServicioPropiedad.NoVencido) {
          let idDetalleServicioIdEstadoDto = new IdDetalleServicioIdEstadoDto
          idDetalleServicioIdEstadoDto.id_DetalleServicioPropiedad = element.dspId
          idDetalleServicioIdEstadoDto.id_EstadoDetalleServicioPropiedad = EstadosDetalleServicioPropiedad.PagadoSinCobro
          this.idDetalleServiciosIdEstados.push(idDetalleServicioIdEstadoDto)
        }
      });

    }

    if (this.vistaTabla == true) {
      this.selection.selected.forEach((element: any) => {
        if (element.dspEstadoId == EstadosDetalleServicioPropiedad.Cobrado) {
          let idDetalleServicioIdEstadoDto = new IdDetalleServicioIdEstadoDto
          idDetalleServicioIdEstadoDto.id_DetalleServicioPropiedad = element.dspId
          idDetalleServicioIdEstadoDto.id_EstadoDetalleServicioPropiedad = EstadosDetalleServicioPropiedad.Pagado
          this.idDetalleServiciosIdEstados.push(idDetalleServicioIdEstadoDto)
        }
        if (element.dspEstadoId == EstadosDetalleServicioPropiedad.Deuda || element.dspEstadoId == EstadosDetalleServicioPropiedad.NoVencido) {
          let idDetalleServicioIdEstadoDto = new IdDetalleServicioIdEstadoDto
          idDetalleServicioIdEstadoDto.id_DetalleServicioPropiedad = element.dspId
          idDetalleServicioIdEstadoDto.id_EstadoDetalleServicioPropiedad = EstadosDetalleServicioPropiedad.PagadoSinCobro
          this.idDetalleServiciosIdEstados.push(idDetalleServicioIdEstadoDto)
        }
      });
    }

    this.detallesAPagarVistaSimple = []
    this.detallesAPagarVistaAgrupada = []
    this.selection.clear()

    if (!isFalsy(this.idDetalleServiciosIdEstados.length)) {
      let updateStatusRequest = new UpdateStatusRequest
      updateStatusRequest.idDetalleServiciosIdEstados = this.idDetalleServiciosIdEstados
      this.updateStatus(updateStatusRequest)
    }
    else {
      this.snackBar.showInfo("No se puede realizar un pago si el estado es diferente a Cobrado.", "Info");
    }
  }

  updateStatus(updateStatusRequest: UpdateStatusRequest) {
    this.detalleServicioPropiedadService.updatestatus(updateStatusRequest).subscribe(
      data => {
        this.spinner.hide()
        this.snackBar.showSuccess("Estados actualizados.", "Exito");
        this.buscarServicios()
      },
      error => {
        this.spinner.hide()
        this.snackBar.showError(error, "Error");
      }
    )
  }
  //#endregion

  //#region Servicios Basicos

  getEstadoDetalleServicioPropiedadService() {
    this.spinner.show("spBusquedaEstados")
    this.estadoDetalleServicioPropiedadService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spBusquedaEstados")
          this.lstEstadoDetalleServicioPropiedad = data
          this.lstEstadoDetalleServicioPropiedad.push({ id: 0, descripcion: "Todos" })
        },
        error => {
          this.spinner.hide("spBusquedaEstados")
          this.snackBar.showError(error, "Error");
        }
      )
  }

  updateStatusAutomatic() {
    this.spinner.show("spBusquedaServicios");
    this.detalleServicioPropiedadService.updateStatusAutomatic()
      .subscribe(
        data => {
          this.spinner.hide("spBusquedaServicios");
        },
        error => {
          this.spinner.hide("spBusquedaServicios");
          this.snackBar.showError(error, "Error");
        }
      )
  }

  getAllServicios() {
    this.spinner.show("spBusquedaServicios");
    this.serviciosService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spBusquedaServicios");
          this.lstServicio = data
          this.lstServicio.sort((a, b) => a.tipoServicio.descripcion.toString().padStart(9, "0").localeCompare(b.tipoServicio.descripcion.toString().padStart(9, "0")));

          let servicioDto = new ServicioDto()
          servicioDto.id = 0
          servicioDto.tipoServicio.descripcion = "Todos"
          this.lstServicio.push(servicioDto)
        },
        error => {
          this.spinner.hide("spBusquedaServicios");
          this.snackBar.showError(error, "Error");
        }
      )
  }

  getAllQuienPaga() {
    this.spinner.show("spBusquedaQuienPaga");
    this.quienPagaService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spBusquedaQuienPaga");
          this.lstQuienPaga = data

          let baseDto = new BaseDto()
          baseDto.id = 0
          baseDto.descripcion = "Todos"
          this.lstQuienPaga.push(baseDto)
        },
        error => {
          this.spinner.hide("spBusquedaQuienPaga");
          this.snackBar.showError(error, "Error");
        }
      )
  }


  openDialogEditarDetalleServicio(idDetalleServicioPropiedad: number, propiedadId: number) {
    let propiedadServicio = new PropiedadServicio
    propiedadServicio.idDetalleServicio = idDetalleServicioPropiedad
    propiedadServicio.idPropiedad = propiedadId
    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.data = propiedadServicio

    let dialogRef = this.dialog.open(DialogEditarDetalleServicioComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.data == true && result.event != AccionesDialog.Cancelar)
        this.buscarServicios()
    })
  }
  //#endregion

  //#region Generacion de Datos
  buscarServicios() {
    this.busquedaRealizda = true
    this.updateStatusAutomatic();
    let idEstadoDetalleServicioPropiedad = isFalsy(this.form.controls["EstadoDetalleServicioPropiedad"].value) ? null : this.form.controls["EstadoDetalleServicioPropiedad"].value
    let idServicio = isFalsy(this.form.controls["Servicio"].value) ? null : this.form.controls["Servicio"].value
    let idQuienPaga = isFalsy(this.form.controls["QuienPaga"].value) ? null : this.form.controls["QuienPaga"].value
    let fechaDesde = this.form.controls["FechaDesde"].value;
    let fechaHasta = this.form.controls["FechaHasta"].value;

    // Verificar si las fechas no son nulas antes de formatear
    if (fechaDesde) {
      fechaDesde = this.datepipe.transform(fechaDesde, 'yyyy-MM-dd') as string + ' 00:00:00';
    } else {
      fechaDesde = null; // o cualquier otro valor que quieras asignar si es nulo
    }

    if (fechaHasta) {
      fechaHasta = this.datepipe.transform(fechaHasta, 'yyyy-MM-dd') as string + ' 23:59:59';
    } else {
      fechaHasta = null; // o cualquier otro valor que quieras asignar si es nulo
    }

    let fechaPeriodoDesdeAño: number | null = null
    let fechaPeriodoDesdeMes: number | null = null
    let fechaPeriodoHastaAño: number | null = null
    let fechaPeriodoHastaMes: number | null = null

    const _fechaPeriodoDesde = this.form.controls["FechaPeriodoDesde"].value;
    if (_fechaPeriodoDesde) {
      fechaPeriodoDesdeAño = parseInt(new Date(_fechaPeriodoDesde).getFullYear().toString());
      fechaPeriodoDesdeMes = parseInt((new Date(_fechaPeriodoDesde).getMonth() + 1).toString());
    }

    const _fechaPeriodoHasta = this.form.controls["FechaPeriodoHasta"].value;

    if (_fechaPeriodoHasta) {
      fechaPeriodoHastaAño = parseInt((new Date(_fechaPeriodoHasta).getFullYear()).toString());
      fechaPeriodoHastaMes = parseInt((new Date(_fechaPeriodoHasta).getMonth() + 1).toString());
    }

    let pendientePago = null


    if (this.idPropiedad != 0) {
      this.getDetalleServicioPropiedadGroupByServicioPropiedad(fechaDesde, fechaHasta, idEstadoDetalleServicioPropiedad, idServicio, this.idPropiedad, idQuienPaga, fechaPeriodoDesdeAño, fechaPeriodoHastaAño, fechaPeriodoDesdeMes, fechaPeriodoHastaMes, pendientePago)
    }
    else
      this.getDetalleServicioPropiedadGroupByServicioPropiedad(fechaDesde, fechaHasta, idEstadoDetalleServicioPropiedad, idServicio, this.idPropiedad, idQuienPaga, fechaPeriodoDesdeAño, fechaPeriodoHastaAño, fechaPeriodoDesdeMes, fechaPeriodoHastaMes, pendientePago)
  }

  async getDetalleServicioPropiedadGroupByServicioPropiedad(dateFrom: string, dateTo: string, idEstadoDetalleServicioPropiedad: number, idServicio: number, idPropiedad: number, idQuienPaga: number, fechaPeriodoDesdeAño: number | null, fechaPeriodoHastaAño: number | null, fechaPeriodoDesdeMes: number | null, fechaPeriodoHastaMes: number | null, pendientePago: boolean | null) {
    this.spinner.show()
    this.detalleServicioPropiedadService.getDetalleServicioPropiedadGroupByServicioPropiedad(dateFrom, dateTo, idEstadoDetalleServicioPropiedad, idServicio, idPropiedad, idQuienPaga, fechaPeriodoDesdeAño, fechaPeriodoHastaAño, fechaPeriodoDesdeMes, fechaPeriodoHastaMes, pendientePago)
      .subscribe(
        async data => {
          this.spinner.hide()
          while (this.detalleServicioPropiedadGroupByServicioPropiedadResponse.servicios.length) {
            this.detalleServicioPropiedadGroupByServicioPropiedadResponse.servicios.pop();
          }

          this.detalleServicioPropiedadGroupByServicioPropiedadResponse = data
          await this.generarvistaTabla()
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        }
      )
  }

  generarvistaTabla() {
    this.spinner.show()
    while (this.lstVistaServicios.length) {
      this.lstVistaServicios.pop();
    }
    this.detalleServicioPropiedadGroupByServicioPropiedadResponse.servicios.forEach(
      servicio => {
        servicio.propiedades.forEach(
          propiedad => {
            propiedad.detallesServiciosPropiedad.forEach(
              detalleServicioPropiedad => {
                let vista = new VistaServicios
                vista.idServicio = servicio.id_Servicio
                vista.descripcionServicio = isFalsy(servicio.tipoServicio) ? "" : servicio.tipoServicio.descripcion
                vista.propiedadId = propiedad.id
                vista.propiedadUbicacion = propiedad.ubicacion
                vista.propiedadPiso = propiedad.piso
                vista.propiedadDepto = propiedad.departamento
                vista.propiedadLocalidad = isFalsy(propiedad.localidad) ? "" : propiedad.localidad.descripcion
                vista.propiedadProvincia = isFalsy(propiedad.provincia) ? "" : propiedad.provincia.descripcion
                vista.dspId = detalleServicioPropiedad.id
                vista.dspCuenta = detalleServicioPropiedad.nroCuenta
                vista.dspEstadoDescripcion = isFalsy(detalleServicioPropiedad.estado) ? "" : detalleServicioPropiedad.estado.descripcion
                vista.dspEstadoId = isFalsy(detalleServicioPropiedad.estado) ? 0 : detalleServicioPropiedad.estado.id
                vista.dspImporte = detalleServicioPropiedad.importe
                vista.dspPeriodo = detalleServicioPropiedad.periodo
                vista.dspPeriodoMes = detalleServicioPropiedad.periodoMes
                vista.dspPeriodoAnio = detalleServicioPropiedad.periodoAnio
                vista.dspQuienPaga = isFalsy(detalleServicioPropiedad.quienPaga) ? "" : detalleServicioPropiedad.quienPaga.descripcion

                this.lstVistaServicios.push(vista)

              }
            )
          }
        )

      }
    )

    this.lstVistaServicios.sort((a, b) => a.dspCuenta.toString().padStart(9, "0").localeCompare(b.dspCuenta.toString().padStart(9, "0")));
    this.dataSource.data = this.lstVistaServicios
    this.data = this.dataSource.data.length >= 1 ? true : false
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.spinner.hide()
  }
  //#endregion

  //#region Seleccion Item Tabla

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    let numSelected = this.selection.selected.length;
    let numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: VistaServicios): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }
  //#endregion

  openDialog(action: any, id: any) {
    let obj = { action: action, id: id };
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "30%",
      dialogConfig.autoFocus = false;
    dialogConfig.data = obj

    const dialogRef = this.dialog.open(DialogDeleteComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == AccionesDialog.Eliminar) {
        this.deleteRowData(result.data);
      }
    });
  }

  deleteRowData(id: number) {
    this.spinner.show()
    this.detalleServicioPropiedadService.delete(id)
      .subscribe(
        data => {
          this.spinner.hide()
          this.dataSource.data = this.dataSource.data.filter(x => {
            return x.id != id;
          });
          this.data = this.dataSource.data.length >= 1 ? true : false
          this.snackBar.showSuccess("Registro dado de baja correctamente.", "Exito");
          this.buscarServicios()
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError("El registro no se puede eliminar porque tiene referencias asignadas.", "Error");
        })

  }

  isFalsy(valor: any) {
    return isFalsy(valor)
  }

  trackByItems(index: number, item: any): any { return item; }

}

function alphanumericSort(arg0: string, arg1: string) {
  throw new Error('Function not implemented.');
}

