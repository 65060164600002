import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AccionesDialog } from '@models/base/identificadores.model';
import { GrupoTipoGarantiaInquilinoDto } from '@models/grupos-tipo-garantia-inquilino/grupoTipoGarantiaInquilinoDto.model';
import { InquilinoDto } from '@models/inquilino/inquilinoDto.model';
import { RenterDeliveredNecessaryGuaranteesResponse } from '@models/inquilino/renterDeliveredNecessaryGuaranteesResponse.model';
import { GruposTipoGarantiaInquilinoService } from '@services/grupos-tipo-garantia-inquilino/grupos-tipo-garantia-inquilino.service';
import { InquilinosService } from '@services/inquilinos/inquilinos.service';
import { PersonasService } from '@services/personas/personas.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { DialogGruposTipoGarantiaInquilinoComponent } from '@views/grupos-tipo-garantia-inquilino/dialog-grupos-tipo-garantia-inquilino/dialog-grupos-tipo-garantia-inquilino.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { isFalsy } from 'utility-types';

@Component({
  selector: 'app-estado-garantias-pendientes',
  templateUrl: './estado-garantias-pendientes.component.html',
  styleUrls: ['./estado-garantias-pendientes.component.css']
})
export class EstadoGarantiasPendientesComponent implements OnInit {

  ejecutado: boolean = false
  data = false
  renterDeliveredNecessaryGuaranteesResponse!: RenterDeliveredNecessaryGuaranteesResponse;
  grupoTipoGarantiaInquilinoDto!: GrupoTipoGarantiaInquilinoDto;
  displayedColumns: string[] = ['inquilino', 'grupoTipoGarantia', 'idGrupo', 'accion'];
  dataSource = new MatTableDataSource<GrupoTipoGarantiaInquilinoDto>();
  inquilinoDto!: InquilinoDto;
  constructor(public inquilinosService: InquilinosService,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private snackBar: SnackBarService,
    private inquilinoService: InquilinosService,

    private personasService: PersonasService,

    private router: Router,
    private gruposTipoGarantiaInquilinoService: GruposTipoGarantiaInquilinoService,) {
    this.inquilinosService.inquilinoDto.subscribe(data => {
      this.inquilinoDto = data
      if(this.inquilinoDto){
        this.getAllGruposTiposGarantiaInquilino()
        this.renterDeliveredNecessaryGuarantees(this.inquilinoDto.id)
      }

    });
  }


  ngOnInit() {
    this.inquilinosService.inquilinoDto.subscribe(data => {
      this.inquilinoDto = data
      if(this.inquilinoDto){
        this.getAllGruposTiposGarantiaInquilino()
        this.renterDeliveredNecessaryGuarantees(this.inquilinoDto.id)
      }
    });
  }
  ngOnChanges(){
    this.inquilinosService.inquilinoDto.subscribe(data => {
      this.inquilinoDto = data
      if(this.inquilinoDto){
        this.getAllGruposTiposGarantiaInquilino()
        this.renterDeliveredNecessaryGuarantees(this.inquilinoDto.id)
      }
    });
  }

  getAllGruposTiposGarantiaInquilino() {
    this.spinner.show()
    this.gruposTipoGarantiaInquilinoService.getAll()
      .subscribe(
        data => {
          this.spinner.hide()
          this.dataSource.data = data.filter(x => x.inquilino.id == this.inquilinoDto.id)

          this.data = this.dataSource.data.length >= 1 ? true : false
        },
        error => {
          this.snackBar.showError(error, "Error");
          this.spinner.hide()
        }
      )
  }

  editarPersona(persona: any) {
    this.getPersonaById(persona.id)
  }

  getPersonaById(idPersona: number) {
    this.spinner.show()
    if(!isFalsy(idPersona))
    this.personasService.getPersonaById(idPersona)
      .subscribe(
        data => {
          this.spinner.hide()
          this.personasService.setPersona(data)
          this.router.navigate(["/personas/editar"])
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        });
  }




  //Validar Garantias Inquilino
  renterDeliveredNecessaryGuarantees(idInquilino: number) {
    this.spinner.show("spCargarEstado")
    if(!isFalsy(idInquilino))
    this.inquilinosService.renterDeliveredNecessaryGuarantees(idInquilino)
      .subscribe(
        data => {
          this.spinner.hide("spCargarEstado")
          this.renterDeliveredNecessaryGuaranteesResponse = data
        },
        error => {
          this.spinner.hide("spCargarEstado")
        }
      )
  }

  openDialog(action: any, obj: { action?: any; inquilinoDto?: any; }) {
    obj.action = action;
    obj.inquilinoDto = this.inquilinoDto
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.data = obj
    dialogConfig.width = "35%"

    const dialogRef = this.dialog.open(DialogGruposTipoGarantiaInquilinoComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event != AccionesDialog.Cancelar)
        this.getAllGruposTiposGarantiaInquilino()
      this.renterDeliveredNecessaryGuarantees(this.inquilinoDto.id)
    });
  }

  isFalsy(valor: any) {
    return isFalsy(valor)
  }

  trackByItems(index: number, item: any): any { return item; }

}
