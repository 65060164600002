<div class="alert alert-info alert-dismissible fade show" role="alert">
    <strong>Recuerde que</strong> para cargar un nuevo contrato necesita tener previamente:
    <ol>
        <li>Actualizado los indices. <a [routerLink]="['/indicesajustecontrato']">Actualizar</a> </li>
        <li>Propiedad cargada con su respectivo propietario titular.</li>
        <li>Inquilino. En caso de tener garantias, verificar validaciones.</li>
    </ol>
    Para los contrato de tipo "Fijo" cargue los periodos necesarios.
    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<mat-stepper linear #stepper [orientation]="(stepperOrientation | async)!">
    <mat-step [stepControl]="datosPropiedadFormGroup" [editable]="true" [completed]="datosPropiedadFormGroup.valid">
        <form [formGroup]="datosPropiedadFormGroup">
            <ng-template matStepLabel>Datos propiedad</ng-template>
            <div class="row">
                <div class="col-lg-3">
                    <app-buscar-propiedad [idEstado]="idEstadoPropiedad" (eventEntity)="selectPropiedad($event)">
                    </app-buscar-propiedad>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-7">
                    <mat-card>
                        <ngx-spinner [zIndex]=100 name="spDatosPropiedad" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
                        </ngx-spinner>
                        <mat-card-subtitle class="example-headers-align mat-expansion-panel-header-description">Información Propiedad
                        </mat-card-subtitle>
                        <mat-card-content *ngIf="propiedad">
                            <div class="row">
                                <div class="col-lg-6">
                                    <input formControlName="PropiedadForm" hidden />
                                    <mat-label><b>Ubicación: </b></mat-label>
                                    {{propiedad.ubicacion | titlecase}}
                                    <span *ngIf="propiedad.piso"> {{propiedad.piso | uppercase}}</span>
                                    <span> </span>
                                    <span *ngIf="propiedad.departamento"> - {{propiedad.departamento | uppercase}}</span>

                                    <span>
                    <button matSuffix mat-icon-button matTooltip="Editar propiedad" *ngIf="propiedad.ubicacion!= ''"
                      (click)="editarPropiedad(propiedad.id)" color="primary">
                      <mat-icon>rate_review</mat-icon>
                    </button></span>

                                </div>
                                <div class="col-lg-6" *ngIf="propiedad.localidad">
                                    <mat-label><b>Localidad: </b></mat-label>
                                    <span>{{propiedad.localidad != undefined || null || "" ? propiedad.localidad.descripcion +" - "+
                    propiedad.localidad.provincia.descripcion : "" | titlecase}}</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6" *ngIf="propiedad.estadoPropiedad">

                                    <mat-label><b>Estado: </b></mat-label>
                                    <span>{{propiedad.estadoPropiedad != undefined ? propiedad.estadoPropiedad.descripcion : "" |
                    titlecase}}</span>
                                </div>
                                <div class="col-lg-6">

                                    <mat-label><b>Clase: </b></mat-label>
                                    <span>{{propiedad.propiedadClase != undefined ? propiedad.propiedadClase.descripcion : "" |
                    titlecase}}</span>
                                </div>
                            </div>
                        </mat-card-content>
                        <div class="no-data-table" *ngIf="!propiedad">
                            <span class="with-icon">
                <mat-icon>search_off</mat-icon>No se encontraron datos para mostrar.
              </span>
                        </div>
                    </mat-card>
                </div>
                <div class="col-lg-5">
                    <mat-card>
                        <ngx-spinner [zIndex]=100 name="spDatosPropietarios" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
                        </ngx-spinner>
                        <mat-card-subtitle>Información Propietarios</mat-card-subtitle>

                        <mat-card-content *ngIf="propiedad">
                            <mat-card-content *ngFor="let propietario of propietariosPropiedad ;let i=index;trackBy:trackByItems">
                                <mat-accordion class="example-headers-align">
                                    <mat-expansion-panel [expanded]='propietario.titular'>
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>
                                                <div class="header-mat-panel-title">
                                                    <span>{{propietario.persona != undefined ? propietario.persona.descripcion : "" | titlecase}}
                            <button mat-icon-button matTooltip="Editar propietario"
                              *ngIf="propietario.persona.descripcion != ''"
                              (click)="editarPersona(propietario.persona.id)" color="primary">
                              <mat-icon matListIcon>rate_review</mat-icon>
                            </button></span>
                                                </div>
                                            </mat-panel-title>
                                        </mat-expansion-panel-header>

                                        <div class="row" *ngIf="propietario.titular == true">
                                            <div class="col-lg-6">
                                                <mat-form-field>
                                                    <mat-label>Comisión</mat-label>
                                                    <span matPrefix>%&nbsp;</span>
                                                    <input matInput type="number" maxlength="50" formControlName="ComisionPropietario" />
                                                    <mat-error *ngIf="datProp.ComisionPropietario.errors?.required">Este campo es
                                                        <strong>requerido</strong>.
                                                    </mat-error>
                                                </mat-form-field>

                                            </div>
                                            <div class="col-lg-6" *ngIf="propietario.titular">
                                                <mat-form-field>
                                                    <mat-label>Tipo liquidación</mat-label>
                                                    <ngx-spinner name="spTiposLiquidacion" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
                                                    <mat-select placeholder="Seleccione opcion" formControlName="TiposLiquidacion">
                                                        <mat-option [value]="tipoLiquidacion" *ngFor="let tipoLiquidacion of lstTiposLiquidacion">
                                                            {{tipoLiquidacion.descripcion}}</mat-option>
                                                    </mat-select>
                                                    <mat-hint>Defecto: {{isFalsy(selectedTiposLiquidacion.descripcion) ? "" : selectedTiposLiquidacion.descripcion}}
                                                    </mat-hint>
                                                    <mat-error *ngIf="datProp.TiposLiquidacion.errors?.required">Este campo es
                                                        <strong>requerido</strong>.
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="row" *ngIf="propietario.titular == true">
                                            <div class="col-lg-6">
                                                <mat-form-field>
                                                    <mat-label>Forma de Pago</mat-label>
                                                    <ngx-spinner name="spFormaDePago" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
                                                    <mat-select placeholder="Seleccione opcion" formControlName="FormaPagoPropietario">
                                                        <mat-option [value]="formaPago" *ngFor="let formaPago of lstFormasPago">
                                                            {{formaPago.descripcion}}</mat-option>
                                                    </mat-select>
                                                    <mat-error *ngIf="datProp.FormaPagoPropietario.errors?.required">Este campo es
                                                        <strong>requerido</strong>.
                                                    </mat-error>
                                                    <!-- <span matSuffix> <mat-icon *ngIf="!showAlertCuentaContable" class="icon-error" matTooltip="Propietario sin cuenta contable asignada.">report_problem</mat-icon></span> -->

                                                </mat-form-field>

                                            </div>
                                            <!-- <div *ngIf="!showAlertCuentaContable" class="alert alert-danger alert-dismissible fade show" role="alert">
                                                <strong>Antes de continuar</strong> asigne una Cuenta Contable al propietario. De lo contrario, seleccione otra forma de pago.
                                                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                              <span aria-hidden="true">&times;</span>
                                            </button>
                                            </div> -->
                                        </div>
                                        <div class="row" *ngIf="propietario.titular != true">
                                            <div class="col-lg-6">
                                                <div class="row">
                                                    <div class="col-lg-6 montoscontrato">
                                                        <mat-label><b>Comisión: </b></mat-label>
                                                    </div>
                                                    <div class="col-lg-6 montoscontrato">
                                                        <span>{{propietario.comision}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6" *ngIf="propietario.tipoLiquidacion">
                                                <div class="row">
                                                    <div class="col-lg-6 montoscontrato">
                                                        <mat-label><b>Tipo liquidación: </b></mat-label>
                                                    </div>
                                                    <div class="col-lg-6 montoscontrato">
                                                        <span>{{propietario.tipoLiquidacion != undefined ? propietario.tipoLiquidacion.descripcion :
                              "" | titlecase}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </mat-expansion-panel>
                                </mat-accordion>
                            </mat-card-content>
                            <mat-card-content *ngIf="sinPropietario">
                                <span style="color: red;">Esta propiedad no tiene propietario o el mismo se encuentra dado de baja.</span>
                            </mat-card-content>
                        </mat-card-content>
                        <div class="no-data-table" *ngIf="!propiedad">
                            <span class="with-icon">
                <mat-icon>search_off</mat-icon>No se encontraron datos para mostrar.
              </span>
                        </div>
                    </mat-card>
                </div>
            </div>
            <br>
            <div>
                <button mat-button mat-stroked-button [disabled]="!datosPropiedadFormGroup.valid || !propiedad ||   isFalsy(propietariosPropiedad)" matStepperNext (click)="changeAcceptTerms()" color="accent">Siguiente</button>
            </div>
        </form>
    </mat-step>

    <mat-step [stepControl]="datosInquilinoFormGroup" [editable]="true" [completed]="datosInquilinoFormGroup.valid">
        <form [formGroup]="datosInquilinoFormGroup">
            <ng-template matStepLabel>Datos inquilino</ng-template>
            <div class="row">
                <div class="col-lg-3">
                    <app-buscar-persona-inquilino (eventEntity)="selectInquilino($event)">
                    </app-buscar-persona-inquilino>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-7">
                    <mat-card>
                        <ngx-spinner [zIndex]=100 name="spInquilino" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
                        </ngx-spinner>
                        <mat-card-subtitle>Información Inquilino</mat-card-subtitle>
                        <mat-card-content *ngIf="personaInquilino.id != 0">
                            <div class="row">
                                <div class="col-lg-6">
                                    <mat-label><b>Inquilino: </b> {{personaInquilino.nombreCompleto | titlecase}}</mat-label>
                                    <button mat-icon-button matTooltip="Editar inquilino" *ngIf="personaInquilino.nombreCompleto != ''" (click)="editarPersona(personaInquilino.id)" color="primary">
                    <mat-icon>rate_review</mat-icon>
                  </button>
                                </div>
                                <div class="col-lg-6">
                                    <mat-label><b>Nro. Documento: </b></mat-label>
                                    <span>{{personaInquilino.nroDocumento}}</span>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-lg-6">
                                    <mat-label><b>Telefono principal: </b></mat-label>
                                    <a *ngIf="personaInquilino.telefonoPrincial" href="tel:+54{{personaInquilino.telefonoPrincial}}">
                    {{personaInquilino.telefonoPrincial}} </a>
                                </div>
                                <div class="col-lg-6">
                                    <mat-label><b>Telefono alternativo: </b></mat-label>
                                    <a *ngIf="personaInquilino.telefonoAlternativo" href="tel:+54{{personaInquilino.telefonoAlternativo}}">
                    {{personaInquilino.telefonoAlternativo}} </a>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6">
                                    <mat-label><b>Correo: </b></mat-label>
                                    <a *ngIf="personaInquilino.correo" href="mailto:{{personaInquilino.correo}}">
                    {{personaInquilino.correo}} </a>
                                </div>
                            </div>
                        </mat-card-content>
                        <mat-card-content *ngIf="personaInquilino.id != 0">
                            <div class="row">
                                <div class="col-lg-6">
                                    <section class="example-section">
                                        <mat-checkbox #tooltip="matTooltip" matTooltipPosition="right" matTooltip="Habilita garantias de inquilino" (change)="checkGarantias($event.checked)" labelPosition="before" formControlName="garantias">Contrato con garantias?</mat-checkbox>
                                    </section>
                                </div>
                            </div>
                        </mat-card-content>
                        <div class="no-data-table" *ngIf="!personaInquilino || personaInquilino.id == 0">
                            <span class="with-icon">
                <mat-icon>search_off</mat-icon>No se encontraron datos para mostrar.
              </span>
                        </div>
                    </mat-card>

                </div>
                <div class="col-lg-5">
                    <mat-card>
                        <ngx-spinner [zIndex]=100 name="spGarantia" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
                        </ngx-spinner>
                        <mat-card-subtitle>Información Garantias</mat-card-subtitle>


                        <mat-card-content *ngIf="tieneGarantias">
                            <mat-card-content *ngIf="renterDeliveredNecessaryGuaranteesResponse != null || undefined">
                                <div class="alert alert-danger" role="alert" *ngIf="renterDeliveredNecessaryGuaranteesResponse.allDeviliveredGarantees == false">
                                    <strong>Garantias pendientes: </strong>
                                    <span *ngFor="let item of renterDeliveredNecessaryGuaranteesResponse.pendingDeliverGarantees;let i=index;trackBy:trackByItems">
                    {{item}} -</span>
                                </div>
                            </mat-card-content>
                            <mat-card-content *ngFor="let garantia of garantiasInquilino">
                                <mat-accordion class="example-headers-align">
                                    <mat-expansion-panel>
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>
                                                <div class="header-mat-panel-title">
                                                    <span> {{garantia.persona.descripcion | titlecase}} </span>
                                                    <button mat-icon-button matTooltip="Editar persona" *ngIf="garantia.persona.descripcion != ''" (click)="editarPersona(garantia.persona.id)" color="primary">
                            <mat-icon matListIcon>rate_review</mat-icon>
                          </button>
                                                </div>
                                            </mat-panel-title>
                                            <mat-panel-description>
                                            </mat-panel-description>
                                        </mat-expansion-panel-header>
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <div class="row">
                                                    <div class="col-lg-6 montoscontrato">
                                                        <mat-label><b>Tipo garantia: </b></mat-label>
                                                    </div>
                                                    <div class="col-lg-6 montoscontrato">
                                                        <span>{{isFalsy(garantia.tipoGarantia) ? "": garantia.tipoGarantia.descripcion |
                              titlecase}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="row">
                                                    <div class="col-lg-6 montoscontrato">
                                                        <mat-label><b>Estado: </b></mat-label>
                                                        <span class="badge text-white" [ngClass]="{'bg-success': garantia.activo==true,'bg-danger': garantia.activo!=true }">{{garantia.activo
                              == true ? 'Activo': "Inactivo" }}</span>
                                                    </div>
                                                    <div class="col-lg-6 montoscontrato">

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </mat-expansion-panel>
                                </mat-accordion>
                            </mat-card-content>
                            <mat-card-content *ngIf="garantiasInquilino.length == 0">
                                <div class="no-data-table">
                                    <span style="color: red;" class="with-icon">
                    <mat-icon>warning_amber</mat-icon>Este inquilino no tiene garantias o no estan activas.
                  </span>
                                </div>
                            </mat-card-content>
                        </mat-card-content>
                        <mat-card-content *ngIf="!tieneGarantias">
                            <div class="no-data-table">
                                <span class="with-icon">Contrato sin garantias.</span>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
            <br>
            <div>
                <button mat-button matStepperPrevious>Anterior</button>
                <button mat-button mat-stroked-button [disabled]="!datosInquilinoFormGroup.valid || !garantiasCorrectas" matStepperNext color="accent" (click)="changeAcceptTerms()">Siguiente</button>
            </div>
        </form>
    </mat-step>

    <mat-step [stepControl]="datosContratoFormGroup" [editable]="true" [completed]="datosContratoFormGroup.valid">
        <form [formGroup]="datosContratoFormGroup">
            <ng-template matStepLabel>Datos contrato</ng-template>
            <!-- <div class="row">
                <div class="col-lg-3">
                    <mat-form-field class="example-full-width">
                        <mat-label>Tomo</mat-label>
                        <input matInput name="Tomo" maxlength="10" type="text" formControlName="Tomo" />
                    </mat-form-field>
                </div>
                <div class="col-lg-3">
                    <mat-form-field class="example-full-width">
                        <mat-label>Folio</mat-label>
                        <input matInput name="Folio" maxlength="10" type="text" formControlName="Folio" />
                    </mat-form-field>
                </div>
                <div class="col-lg-3">
                    <mat-form-field class="example-full-width">
                        <mat-label>Numero</mat-label>
                        <input matInput name="Numero" maxlength="10" type="text" formControlName="Numero" />
                    </mat-form-field>
                </div>

            </div> -->
            <div class="row">

                <div class="col-lg-3">
                    <mat-form-field class="example-full-width">
                        <mat-label>Fecha comienzo</mat-label>
                        <input matInput name="FechaComienzo" type="date" formControlName="FechaComienzo" (change)="actualizaFechas()" />
                        <mat-error *ngIf="datCon.FechaComienzo.errors?.required">Este campo es <strong>requerido</strong>.
                        </mat-error>
                        <mat-error *ngIf="datCon.FechaComienzo.errors?.validarFechaComienzoContrato">Fecha incorrecta.</mat-error>


                    </mat-form-field>
                </div>
                <div class="col-lg-3">
                    <mat-form-field class="example-full-width">
                        <ngx-spinner [zIndex]=100 name="spTipoContrato" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
                        </ngx-spinner>
                        <mat-label>Tipo contrato</mat-label>
                        <mat-select formControlName="TipoContrato" (selectionChange)="selectTipoContrato($event)">
                            <mat-option *ngFor="let tipoContrato of lstTipoContrato;let i=index;trackBy:trackByItems" [value]="tipoContrato">{{tipoContrato.descripcion}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="datCon.TipoContrato.errors?.required">Este campo es <strong>requerido</strong>.
                        </mat-error>
                    </mat-form-field>
                </div>
                <!-- <div class="col-lg-3">
                    <mat-form-field class="example-full-width">
                        <mat-label>Duracion contraro</mat-label>
                        <mat-select formControlName="DuracionContrato" name="DuracionContrato" (selectionChange)="selectDuracionContrato($event)">
                            <mat-option value="3">6 Meses</mat-option>
                            <mat-option value="6">6 Meses</mat-option>
                            <mat-option value="12">12 Meses</mat-option>
                            <mat-option value="18">18 Meses</mat-option>
                            <mat-option value="24">24 Meses</mat-option>
                            <mat-option value="30">30 Meses</mat-option>
                            <mat-option value="36">36 Meses</mat-option>
                            <mat-option value="42">42 Meses</mat-option>
                            <mat-option value="48">48 Meses</mat-option>
                            <mat-option value="54">54 Meses</mat-option>
                            <mat-option value="60">60 Meses</mat-option>
                            <mat-option value="66">66 Meses</mat-option>
                            <mat-option value="72">72 Meses</mat-option>
                            <mat-option value="72">78 Meses</mat-option>
                        </mat-select>
                        <mat-error *ngIf="datCon.DuracionContrato.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                    </mat-form-field>
                </div> -->
                <div class="col-lg-3">
                    <mat-form-field class="example-full-width">
                        <mat-label>Duración contrato</mat-label>
                        <input matInput name="DuracionContrato" onkeypress="return event.charCode >= 1" min="1" type="number" (change)="selectDuracionContrato($event)" formControlName="DuracionContrato" />
                        <mat-error *ngIf="datCon.DuracionContrato.errors?.required">Este campo es <strong>requerido</strong>.
                        </mat-error>
                        <mat-error *ngIf="datCon.DuracionContrato.errors?.validarFechasContrato">Fecha incorrecta.</mat-error>
                        <mat-error *ngIf="datCon.DuracionContrato.errors?.duracionContratoExcede">Se exceden periodos cargados.
                        </mat-error>
                        <mat-hint>Meses</mat-hint>
                    </mat-form-field>

                </div>
                <div class="col-lg-3">
                    <mat-form-field class="example-full-width">
                        <mat-label>Fecha finalizacion</mat-label>
                        <input matInput name="FechaFinalizacion" type="date" (change)="setDuracionContrato()" formControlName="FechaFinalizacion" />
                        <mat-error *ngIf="datCon.FechaFinalizacion.errors?.required">Este campo es <strong>requerido</strong>.
                        </mat-error>
                        <mat-error *ngIf="datCon.FechaFinalizacion.errors?.validarFechasContrato">Fecha incorrecta.</mat-error>
                    </mat-form-field>

                </div>

            </div>
            <div class="row">
                <div class="col-lg-3">
                    <mat-form-field class="example-full-width">
                        <mat-label>Dia Vto. Pago</mat-label>
                        <input matInput name="DiaPago" onkeypress="return event.charCode >= 1" min="1" type="number" min="1" max="31" formControlName="DiaPago" />
                        <mat-error *ngIf="datCon.DiaPago.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-lg-3">
                  <mat-form-field class="example-full-width">
                    <ngx-spinner name="spBusquedaTipoAjusteContrato" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
                    <mat-label>Tipos de Ajuste</mat-label>
                    <mat-select placeholder="Seleccione opcion" formControlName="TiposAjusteContrato" (selectionChange)="selectTipoAjusteContrato($event)">
                      <mat-option [value]="tipoAjusteContrato" *ngFor="let tipoAjusteContrato of lstTiposAjusteContrato;let i=index;trackBy:trackByItems">
                        {{ tipoAjusteContrato.descripcion | titlecase }}({{ tipoAjusteContrato.acronimo | uppercase }})</mat-option>
                  </mat-select>
                  <mat-error *ngIf="datCon.TiposAjusteContrato.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-lg-3">
                    <mat-form-field class="example-full-width">
                        <mat-label>Frecuencia Ajuste</mat-label>
                        <input matInput name="FrecuenciaAjusteMensual" onkeypress="return event.charCode >= 0" min="0" type="number" formControlName="FrecuenciaAjusteMensual" />
                        <span matSuffix>
              <mat-icon color="primary" matTooltip="¿Cada cuanto meses se deben actualizar los ajustes de alquileres?">info</mat-icon>
            </span>
                        <mat-hint>Meses</mat-hint>
                    </mat-form-field>
                </div>
            </div>
            <br>
            <br>
            <br>
            <div>
                <button mat-button matStepperPrevious>Anterior</button>
                <button mat-button mat-stroked-button [disabled]="!datosContratoFormGroup.valid" (click)="changeAcceptTerms()" matStepperNext color="accent">Siguiente</button>
            </div>
        </form>
    </mat-step>

    <mat-step [stepControl]="montosContratoFormGroup" [editable]="true" [completed]="datosContratoFormGroup.valid">
        <form [formGroup]="montosContratoFormGroup">
            <ngx-spinner name="spMontosContrato" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
            </ngx-spinner>
            <ng-template matStepLabel>Montos contrato</ng-template>
            <br>
            <div class="row">
                <div class="col-lg-6">
                    <mat-card>

                        <mat-card-subtitle><b>Montos Honorarios</b></mat-card-subtitle>
                        <mat-card-content>
                            <div class="row">
                                <div class="col-lg-4">
                                    <mat-form-field class="example-full-width">
                                        <mat-label>Monto Honorarios</mat-label>
                                        <input matInput name="MontoHonorario" onkeypress="return event.charCode >= 0" min="0" type="number" formControlName="MontoHonorario" />
                                        <mat-error *ngIf="datMon.MontoHonorario.errors?.required">Este campo es <strong>requerido</strong>.
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-4">
                                    <mat-form-field class="example-full-width">
                                        <mat-label>Tipo Monto</mat-label>
                                        <mat-select formControlName="TipoMontoHonorario" (selectionChange)="selectTipoMontoHonorarios($event)">
                                            <mat-option *ngFor="let tipoMonto of lstTiposMonto;let i=index;trackBy:trackByItems" [value]="tipoMonto">{{tipoMonto.descripcion}}</mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="datMon.TipoMontoHonorario.errors?.required">Este campo es
                                            <strong>requerido</strong>.
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-4">
                                    <mat-form-field class="example-full-width">
                                        <mat-label>Plan de Pago</mat-label>
                                        <input matInput name="PlanPago" onkeypress="return event.charCode >= 0" min="0" type="number" formControlName="PlanPago" (change)="setPlanDePago()" />
                                        <mat-hint>Cantidad de cuotas</mat-hint>
                                        <mat-error *ngIf="datMon.PlanPago.errors?.planPagoExcedido">Excede duración contrato.
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
                <div class="col-lg-6">
                    <mat-card>
                        <mat-card-subtitle><b>Montos Punitorios</b></mat-card-subtitle>
                        <mat-card-content>
                            <div class="row">
                                <div class="col-lg-4">
                                    <mat-form-field class="example-full-width">
                                        <mat-label>Tipo Punitorios</mat-label>
                                        <mat-select formControlName="TipoPunitorio" (selectionChange)="selectTipoPunitorios($event)">
                                            <mat-option *ngFor="let tipoPunitorio of lstTiposPunitorio;let i=index;trackBy:trackByItems" [value]="tipoPunitorio">{{tipoPunitorio.descripcion}}</mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="datMon.TipoPunitorio.errors?.required">Este campo es <strong>requerido</strong>.
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-4">
                                    <mat-form-field class="example-full-width">
                                        <mat-label>Punitorios (por día)</mat-label>
                                        <input matInput name="MontoPunitorio" onkeypress="return event.charCode >= 0" min="0" type="number" formControlName="MontoPunitorio" />
                                        <mat-error *ngIf="datMon.MontoPunitorio.errors?.required">Este campo es <strong>requerido</strong>.
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-4">
                                    <mat-form-field class="example-full-width">
                                        <mat-label>Tipo Monto</mat-label>
                                        <mat-select formControlName="TipoMontoPunitorio" (selectionChange)="selectTipoMontoPunitorios($event)">
                                            <mat-option *ngFor="let tipoMonto of lstTiposMonto;let i=index;trackBy:trackByItems" [value]="tipoMonto">{{tipoMonto.descripcion}}</mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="datMon.TipoMontoPunitorio.errors?.required">Este campo es
                                            <strong>requerido</strong>.
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-lg-6">
                    <mat-card>
                        <mat-card-subtitle><b>Montos Sellado</b></mat-card-subtitle>
                        <mat-card-content>
                            <div class="row">
                                <div class="col-lg-4">
                                    <mat-form-field class="example-full-width">
                                        <mat-label>Tipo Sellado</mat-label>
                                        <mat-select formControlName="TipoSellado" (selectionChange)="selectTipoSellado($event)">
                                            <mat-option *ngFor="let tipoSellado of lstTiposSellado ;let i=index;trackBy:trackByItems" [value]="tipoSellado"> {{tipoSellado.descripcion}}</mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="datMon.TipoSellado.errors?.required">Este campo es <strong>requerido</strong>.
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-4">
                                    <mat-form-field class="example-full-width">
                                        <mat-label>Monto Sellado</mat-label>
                                        <input matInput name="MontoSellado" onkeypress="return event.charCode >= 0" min="0" type="number" formControlName="MontoSellado" />
                                        <mat-error *ngIf="datMon.MontoSellado.errors?.required">Este campo es <strong>requerido</strong>.
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-4">
                                    <mat-form-field class="example-full-width">
                                        <mat-label>Tipo Monto</mat-label>
                                        <mat-select formControlName="TipoMontoSellado" (selectionChange)="selectTipoMontoSellado($event)">
                                            <mat-option *ngFor="let tipoMonto of lstTiposMonto;let i=index;trackBy:trackByItems" [value]="tipoMonto">{{tipoMonto.descripcion}}</mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="datMon.TipoMontoSellado.errors?.required">Este campo es
                                            <strong>requerido</strong>.
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
            <br>

            <br>
            <div>
                <button mat-button matStepperPrevious>Anterior</button>
                <button mat-button mat-stroked-button [disabled]="!montosContratoFormGroup.valid" matStepperNext (click)="changeAcceptTerms()" color="accent">Siguiente</button>
            </div>
        </form>
    </mat-step>

    <mat-step [stepControl]="detalleMontosContratorFormGroup" [editable]="true" [completed]="detalleMontosContratorFormGroup.valid">
        <form [formGroup]="detalleMontosContratorFormGroup">
            <ng-template matStepLabel>Detalle Montos contrato</ng-template>
            <br>

            <div class="row">
                <div class="col-lg-12">
                    <mat-card>
                        <mat-card-subtitle>Detalle Montos</mat-card-subtitle>
                        <mat-card-content>
                            <button mat-raised-button (click)="openDialog()" color="primary">
                <mat-icon>add</mat-icon>
                Cargar periodo
              </button>
                            <br>
                            <br>
                            <div class="row">
                                <div class="col-lg-12">
                                    <mat-accordion>
                                        <mat-expansion-panel>
                                            <mat-expansion-panel-header>
                                                <mat-panel-title>
                                                    Detalle Montos Contrato
                                                </mat-panel-title>
                                            </mat-expansion-panel-header>
                                            <ngx-spinner [zIndex]=100 bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
                                            </ngx-spinner>
                                            <table mat-table [dataSource]="dataSource" matSort [hidden]=!data class="mat-elevation-z8">
                                                <ng-container matColumnDef="periodo">
                                                    <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Periodo </mat-header-cell>
                                                    <mat-cell mat-cell *matCellDef="let periodo">
                                                        {{periodo.periodoDesde}}-{{periodo.periodoHasta}} </mat-cell>
                                                </ng-container>
                                                <ng-container matColumnDef="montoAlquiler">
                                                    <mat-header-cell mat-header-cell *matHeaderCellDef> Monto alquiler </mat-header-cell>
                                                    <mat-cell mat-cell *matCellDef="let periodo"> {{periodo.montoAlquiler | currency}} </mat-cell>
                                                </ng-container>
                                                <ng-container matColumnDef="incremento">
                                                    <mat-header-cell mat-header-cell *matHeaderCellDef> Incremento </mat-header-cell>
                                                    <mat-cell mat-cell *matCellDef="let periodo"> {{periodo.incremento | currency}} </mat-cell>
                                                </ng-container>
                                                <ng-container matColumnDef="descuento">
                                                    <mat-header-cell mat-header-cell *matHeaderCellDef> Descuento </mat-header-cell>
                                                    <mat-cell mat-cell *matCellDef="let periodo"> {{periodo.descuento | currency}} </mat-cell>
                                                </ng-container>
                                                <ng-container matColumnDef="comision">
                                                    <mat-header-cell mat-header-cell *matHeaderCellDef> Comision </mat-header-cell>
                                                    <mat-cell mat-cell *matCellDef="let periodo"> {{periodo.comision | currency}} </mat-cell>
                                                </ng-container>
                                                <ng-container matColumnDef="subtotal">
                                                    <mat-header-cell mat-header-cell *matHeaderCellDef> Sub. Total </mat-header-cell>
                                                    <mat-cell mat-cell *matCellDef="let periodo"> {{periodo.subTotal | currency}} </mat-cell>
                                                </ng-container>


                                                <!-- Action Column -->
                                                <ng-container matColumnDef="accion">
                                                    <mat-header-cell mat-header-cell *matHeaderCellDef> Acción </mat-header-cell>
                                                    <mat-cell mat-cell *matCellDef="let periodo" class="action-link">
                                                        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                              <mat-icon>more_vert</mat-icon>
                            </button>
                                                        <mat-menu #menu="matMenu">
                                                            <button mat-menu-item (click)="eliminarPeriodo(periodo)">
                                <mat-icon>delete</mat-icon>
                                <span>Eliminar</span>
                              </button>
                                                        </mat-menu>
                                                    </mat-cell>
                                                </ng-container>


                                                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                                                <mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></mat-row>


                                            </table>
                                            <div class="no-data-table">
                                                <span class="with-icon" *ngIf="dataSource.data.length == 0">
                          <mat-icon>search_off</mat-icon>No se encontraron datos para mostrar.
                        </span>
                                            </div>
                                            <mat-paginator showFirstLastButtons [hidden]=!data class="paginator" [pageSize]="20" [pageSizeOptions]="[5, 10, 20,50,100,200]">
                                            </mat-paginator>
                                        </mat-expansion-panel>
                                    </mat-accordion>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col-lg-3">
                                    <span>Total Alquiler:</span>
                                </div>
                                <div class="col-lg-3">
                                    <span>{{totalMontoAlquiler | currency}}</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-3">
                                    <span>Comision:</span>
                                </div>
                                <div class="col-lg-3">
                                    <span>{{totalComision | currency}}</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-3">
                                    <span>Sellado:</span>
                                </div>
                                <div class="col-lg-3">
                                    <span>{{totalSellado | currency}}</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-3">
                                    <h2>Total</h2>
                                </div>
                                <div class="col-lg-3">
                                    <h2>{{total | currency}}</h2>
                                </div>
                            </div>
                            <mat-divider></mat-divider>
                            <br>
                            <div class="row">
                                <div class="col-lg-3">
                                    <span>Incrementos:</span>
                                </div>
                                <div class="col-lg-3">
                                    <span>{{totalIncrementos | currency}}</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-3">
                                    <span>Descuentos:</span>
                                </div>
                                <div class="col-lg-3">
                                    <span>{{totalDescuento | currency}}</span>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
            <br>
            <div>
                <button mat-button matStepperPrevious>Anterior</button>
                <button mat-button mat-stroked-button matStepperNext (click)="changeAcceptTerms()" color="accent">Siguiente</button>
            </div>
        </form>
    </mat-step>

    <mat-step>
        <br>
        <form [formGroup]="validatorFormGroup">

            <ng-template matStepLabel>Finalizar</ng-template>
            <p>Esta por finalizar la carga de un contrato.</p>
            <p>Tenga en cuenta de revisar todos los campos solicitados. Es muy importante que los datos se ingresen de manera correcta.
            </p>

            <br>
            <mat-slide-toggle (change)="showResumen($event)" formControlName="AcceptTerms">Contrato firmado por Inquilino y Propietario
            </mat-slide-toggle>
            <br>
            <br>
            <br>
        </form>
        <br>
        <div class="col-lg-12" *ngIf="isShowResumen">
            <div class="row">
                <!-- Propiedad -->
                <div class="col-lg-6">
                    <mat-card>
                        <mat-card-header>
                            <mat-card-title>Propiedad</mat-card-title>
                        </mat-card-header>
                        <mat-card-content *ngIf="propiedad">
                            <div class="row">
                                <div class="col-lg-6">
                                    <mat-label><b>Ubicación: </b></mat-label>
                                    <span>{{propiedad.ubicacion | titlecase}} {{propiedad.piso | titlecase}} {{propiedad.departamento | titlecase}}</span>
                                </div>
                                <div class="col-lg-6" *ngIf="propiedad.localidad">
                                    <mat-label><b>Localidad: </b></mat-label>
                                    <span>{{propiedad.localidad != undefined ? propiedad.localidad.descripcion +" - "+
                    propiedad.localidad.provincia.descripcion : "" | titlecase}}</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6" *ngIf="propiedad.estadoPropiedad">
                                    <mat-label><b>Estado: </b></mat-label>
                                    <span>{{propiedad.estadoPropiedad != undefined ? propiedad.estadoPropiedad.descripcion : "" |
                    titlecase}}</span>
                                </div>
                                <div class="col-lg-6">
                                    <mat-label><b>Clase: </b></mat-label>
                                    <span>{{propiedad.propiedadClase != undefined ? propiedad.propiedadClase.descripcion : "" |
                    titlecase}}</span>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>

                </div>
                <!-- Propietario -->
                <div class="col-lg-6">
                    <mat-card>
                        <mat-card-header>
                            <mat-card-title>Propietario</mat-card-title>
                        </mat-card-header>
                        <mat-card-content>
                            <div *ngFor="let propietario of propietariosPropiedad ;let i=index;trackBy:trackByItems">
                                <div class="row" *ngIf="propietario.titular == true">
                                    <div class="col-lg-6">
                                        <span><b>Propietario/a: </b> </span> {{propietario.persona.descripcion | titlecase}}
                                    </div>
                                    <div class="col-lg-6">
                                        <span><b>Tipo Liquidación: </b> </span> {{contratoResumen.tipoLiquidacion.descripcion}}
                                    </div>
                                </div>
                                <div class="row" *ngIf="propietario.titular == true">
                                  <div class="col-lg-6">
                                    <span><b>Comsión: </b> </span> {{contratoResumen.porcentajeComisionPropietario}}%
                                </div>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
            <div class="row">
                <!-- Inquilino -->
                <div class="col-lg-6">
                    <mat-card>
                        <mat-card-header>
                            <mat-card-title>Inquilino</mat-card-title>
                        </mat-card-header>
                        <mat-card-content>
                            <div class="row">
                                <div class="col-lg-6">
                                    <mat-label><b>Inquilino/a: </b> {{personaInquilino.nombreCompleto | titlecase}}</mat-label>
                                </div>
                                <div class="col-lg-6">
                                    <mat-label><b>Nro. Documento: </b></mat-label>
                                    <span>{{personaInquilino.nroDocumento}}</span>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-lg-6">
                                    <mat-label><b>Telefono principal: </b></mat-label>
                                    <a *ngIf="personaInquilino.telefonoPrincial" href="tel:+54{{personaInquilino.telefonoPrincial}}">
                    {{personaInquilino.telefonoPrincial}} </a>
                                </div>
                                <div class="col-lg-6">
                                    <mat-label><b>Telefono alternativo: </b></mat-label>
                                    <a *ngIf="personaInquilino.telefonoAlternativo" href="tel:+54{{personaInquilino.telefonoAlternativo}}">
                    {{personaInquilino.telefonoAlternativo}} </a>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6">
                                    <mat-label><b>Correo: </b></mat-label>
                                    <a *ngIf="personaInquilino.correo" href="mailto:{{personaInquilino.correo}}">
                    {{personaInquilino.correo}} </a>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
                <!-- Datos Contrato -->
                <div class="col-lg-6">
                    <mat-card>
                        <mat-card-header>
                            <mat-card-title>Datos Contrato</mat-card-title>
                        </mat-card-header>
                        <mat-card-content>
                            <div class="row">
                                <div class="col-lg-6">
                                    <span><b>Fecha comienzo:</b> </span> {{contratoResumen.fechaComienzo | date:"dd/MM/yyyy"}}
                                </div>
                                <div class="col-lg-6">
                                    <span><b>Fecha finalización: </b></span> {{contratoResumen.fechaVencimiento | date:"dd/MM/yyyy"}}
                                </div>
                            </div>
                            <div class="row">
                              <div class="col-lg-6">
                                <span><b>Tipo Ajuste:</b></span> {{contratoResumen.tipoAjusteContrato.acronimo | uppercase}}
                            </div>
                              <div class="col-lg-6">
                                    <span><b>Frecuencia ajuste:</b></span> {{contratoResumen.frecuenciaAjusteMensual}}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6">
                                  <span><b>Dia vto. pago:</b> </span> {{contratoResumen.diaVencimientoPago}}
                              </div>
                              <div class="col-lg-6">
                                <span><b>Tipo Contrato:</b></span> {{contratoResumen.tipoContrato.descripcion | titlecase}}
                            </div>
                            </div>

                        </mat-card-content>
                    </mat-card>

                </div>
            </div>
            <div class="row">
                <!-- Datos Contrato -->
                <!-- Montos Honorarios -->
                <div class="col-lg-6">
                    <mat-card>
                        <mat-card-header>
                            <mat-card-title>Montos Contrato</mat-card-title>
                            <mat-card-subtitle>Montos Honorarios</mat-card-subtitle>
                        </mat-card-header>
                        <mat-card-content>
                            <div class="row">
                                <div class="col-lg-6">
                                    <span><b>Monto Honorario:</b> </span> {{contratoResumen.montoAdministrativo}}
                                </div>
                                <div class="col-lg-6">
                                    <span><b>Tipo monto:</b> </span> {{contratoResumen.tipoMontoAdministrativo.descripcion}}
                                </div>
                            </div>
                            <div class="row">
                              <div class="col-lg-6">
                                <span><b>Plan de pago:</b> </span> {{contratoResumen.planDePago}}
                            </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
                <!-- Datos Contrato -->
                <!-- Montos Punitorio -->
                <div class="col-lg-6">
                    <mat-card>
                        <mat-card-header>
                            <mat-card-title>Montos Contrato</mat-card-title>
                            <mat-card-subtitle>Montos Punitorio</mat-card-subtitle>
                        </mat-card-header>
                        <mat-card-content>
                            <div class="row">
                                <div class="col-lg-6">
                                    <span><b>Tipo punitorio:</b> </span> {{contratoResumen.tipoPunitorio.descripcion}}
                                </div>
                                <div class="col-lg-6">
                                    <span><b>Monto punitorio:</b> </span> {{contratoResumen.montoPunitorio}}
                                </div>
                            </div>
                            <div class="row">
                              <div class="col-lg-6">
                                <span><b>Tipo monto punitorio:</b> </span> {{contratoResumen.tipoMontoPunitorio.descripcion}}
                            </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
            <div class="row">
                <!-- Datos Contrato -->
                <!-- Montos Honorarios -->
                <div class="col-lg-6">
                    <mat-card>
                        <mat-card-header>
                            <mat-card-title>Montos Contrato</mat-card-title>
                            <mat-card-subtitle>Montos Sellado</mat-card-subtitle>
                        </mat-card-header>
                        <mat-card-content>
                            <div class="row">
                                <div class="col-lg-6">
                                    <span><b>Tipo Sellado:</b> </span> {{contratoResumen.tipoSellado.descripcion}}
                                </div>
                                <div class="col-lg-6">
                                    <span><b>Monto sellado: </b></span> {{contratoResumen.montoSellado}}
                                </div>
                            </div>
                            <div class="row">
                              <div class="col-lg-6">
                                <span><b>Tipo de monto:</b> </span> {{contratoResumen.tipoMontoSellado.descripcion}}
                            </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>


        </div>

        <div>
            <button mat-button matStepperPrevious>Anterior</button>
            <button mat-button mat-stroked-button color="primary" *appRole="['Contratos.Crear']" (click)="createContrato()" [disabled]="dataSource.data.length == 0 || !datosPropiedadFormGroup.valid || !datosInquilinoFormGroup.valid || !datosContratoFormGroup.valid || !montosContratoFormGroup.valid || !detalleMontosContratorFormGroup.valid || !validatorFormGroup.valid || isLoading">
        <mat-icon>gavel</mat-icon>
        <span *ngIf="isLoading"  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        Agregar nuevo contrato
      </button>
        </div>
    </mat-step>

</mat-stepper>
