import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
  MatSnackBarRef
} from '@angular/material/snack-bar';
import { AccionesDialog, SnackbarClass } from '@models/base/identificadores.model';
import { SnackbarData } from '@models/base/snackbar-data.model';
import { ParametroSistemaValues } from '@models/parametros-sistema/parametrosSistemaValues.model';
import { SnackbarComponent } from 'src/app/views/snackbar/snackbar.component';
import { isFalsy } from 'utility-types';
import { ParametrosSistemaService } from '../parametro-sistema/parametros-sistema.service';

@Injectable()
export class SnackBarService {

  private snackBarConfig!: MatSnackBarConfig;
  private snackBarRef!: MatSnackBarRef<any>;
  private horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  private verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  private snackBarAutoHide = 5500;
  private durationInSeconds: number = 0;
  public mensaje!: string;
  public link!: string;
  public accion!: string;
  public nombreLink!: string;
  public icono!: string;
  parametroSistemaValues!: ParametroSistemaValues;

  constructor(
    private snackBar: MatSnackBar,
    private parametrosSistemaService: ParametrosSistemaService) {

    this.parametroSistemaValues = this.parametrosSistemaService.getParametrosSistemasValues()
    if (!isFalsy(this.parametroSistemaValues))
      this.snackBarAutoHide = parseInt(this.parametroSistemaValues.DuracionAlertas)

  }

/**
 * *Mensaje utilizado para mostrar un exito.
 * @param message Mensaje a mostrar como descripcion
 * @param action Nombre de la accion del mensaje
 */
  showSuccess(message: string, action: string) {
    let snackBarConfig = new MatSnackBarConfig();
    snackBarConfig.horizontalPosition = this.horizontalPosition;
    snackBarConfig.verticalPosition = this.verticalPosition;
    snackBarConfig.duration = this.snackBarAutoHide,0;
    snackBarConfig.panelClass = 'success-snackbar';
    //this.showTemplate(message,"","","Exito",snackBarConfig.duration ,SnackbarClass.success)
    this.snackBarRef = this.snackBar.open(message, "Exito", snackBarConfig);
  }

/**
 * *Mensaje utilizado para mostrar un error.
 * @param message Mensaje a mostrar como descripcion
 * @param action Nombre de la accion del mensaje
 */
  showError(message: string, action: string) {
    let snackBarConfig = new MatSnackBarConfig();
    snackBarConfig.horizontalPosition = this.horizontalPosition;
    snackBarConfig.verticalPosition = this.verticalPosition;
    snackBarConfig.duration = this.snackBarAutoHide,0;
    snackBarConfig.panelClass = 'error-snackbar';
    this.snackBarRef = this.snackBar.open(message, "Error", snackBarConfig);
  }

  /**
   *
   * @param mensaje  Mensaje a mostrar como descripcion
   * @param link Url del link
   * @param nombreLink Nombre a mostrar del en el link
   * @param accion Nombre de la accion del mensaje
   * @param duracion Duracón del mensaje en la pantalla
   * @param tipoNotificacion  Tipo de notificacion. Ej: SnackbarClass.success
   */
  showTemplate(mensaje: string, link: string, nombreLink: string, accion: string, duracion: number, tipoNotificacion: SnackbarClass) {

    let snackbarData = new SnackbarData();
    snackbarData.mensaje = mensaje
    snackbarData.link = link
    snackbarData.nombreLink = nombreLink
    snackbarData.accion = accion


    let snackBarConfig = new MatSnackBarConfig();
    snackBarConfig.horizontalPosition = this.horizontalPosition;
    snackBarConfig.verticalPosition = this.verticalPosition;
    switch (tipoNotificacion) {
      case SnackbarClass.error:
        snackbarData.icono = "error"
        break;
      case SnackbarClass.info:
        snackbarData.icono = "info"
        break;
      case SnackbarClass.warning:
        snackbarData.icono = "warning"
        break;
      case SnackbarClass.success:
        snackbarData.icono = "check_circle"
        break;

      default:
        break;
    }
    snackBarConfig.panelClass = tipoNotificacion + '-snackbar';
    snackBarConfig.data = snackbarData
    if (!isFalsy(duracion))
      snackBarConfig.duration = duracion, 0;
    else
      snackBarConfig.duration = this.snackBarAutoHide, 0;
    AccionesDialog


    this.mensaje = mensaje
    this.link = link
    this.accion = accion
    this.nombreLink = nombreLink
    this.snackBar.openFromComponent(SnackbarComponent, snackBarConfig);
  }

  /**
 * *Mensaje utilizado para mostrar una alerta.
 * @param message Mensaje a mostrar como descripcion
 * @param action Nombre de la accion del mensaje
 */
  showWarn(message: string, action: string) {
    let snackBarConfig = new MatSnackBarConfig();
    snackBarConfig.horizontalPosition = this.horizontalPosition;
    snackBarConfig.verticalPosition = this.verticalPosition;
    snackBarConfig.duration = this.snackBarAutoHide, 0;
    snackBarConfig.panelClass = 'warning-snackbar';
    this.snackBarRef = this.snackBar.open(message, "Alerta", snackBarConfig);
  }

  /**
 *
 * *Mensaje utilizado para mostrar una informacion.
 * @param message Mensaje a mostrar como descripcion
 * @param action Nombre de la accion del mensaje
 */
  showInfo(message: string, action: string) {
    let snackBarConfig = new MatSnackBarConfig();
    snackBarConfig.horizontalPosition = this.horizontalPosition;
    snackBarConfig.verticalPosition = this.verticalPosition;
    snackBarConfig.duration = this.snackBarAutoHide, 0;
    snackBarConfig.panelClass = 'info-snackbar';
    this.snackBarRef = this.snackBar.open(message, "Info", snackBarConfig);
  }


}
