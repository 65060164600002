<div class="row content">
    <div class="col-sm-2">
        <app-nav-panel-persona></app-nav-panel-persona>
    </div>
    <div class="col-sm-10">
        <mat-accordion class="example-headers-align" multi>
            <mat-expansion-panel [disabled]="false" [expanded]='true' [hideToggle]="false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <b>Datos Inquilino/a</b>
                    </mat-panel-title>
                    <mat-icon style="margin-right: 10px;">vpn_key</mat-icon>
                    <mat-panel-description>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                    <app-agrega-persona-inquilino (nuevoInquilino)="nuevoInquilino($event)" *ngIf="personaDetailDto.esInquilino == false"></app-agrega-persona-inquilino>
                    <h3 *ngIf="personaDetailDto.esInquilino == true">Esta persona ya es un inquilino/a</h3>
                </ng-template>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
