import { LiveAnnouncer } from '@angular/cdk/a11y';
import { BreakpointObserver } from '@angular/cdk/layout';
import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AuditoriaLogDto } from '@models/auditoriaLog/auditoriaLogDto.model';
import { AuditoriaLogFilter } from '@models/auditoriaLog/auditoriaLogFilter.model';
import { Genericos } from '@models/base/genericos.model';
import { RangoFechasValores } from '@models/base/identificadores.model';
import { AutoriaLogService } from '@services/auditoria-log/autoria-log.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { isFalsy } from 'utility-types';
import { DialogViewAuditorialogComponent } from '../dialog-view-auditorialog/dialog-view-auditorialog.component';

@Component({
  selector: 'listado-auditoria-log',
  templateUrl: './listado-auditoria-log.component.html',
  styleUrls: ['./listado-auditoria-log.component.css'],
  providers: [DatePipe]
})
export class ListadoAuditoriaLogComponent implements OnInit {

  lstRangoFecha = Genericos.lstRangoFecha
  displayedColumns: string[] = []
  dataSource = new MatTableDataSource<AuditoriaLogDto>();
  data: boolean = false;
  formBuilder: FormBuilder = new FormBuilder;
  form: FormGroup;

  rangoFecha: number = RangoFechasValores.Hoy // Hoy
  checkedFiltroAvanzado: boolean = false;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private autoriaLogService: AutoriaLogService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private _liveAnnouncer: LiveAnnouncer,
    public dialog: MatDialog,
    private datepipe: DatePipe,
  ) {
    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.displayedColumns = ['timestamp','ipAddress', 'id_Usuario','fullUrl', 'accion']
    });
    this.form = this.formBuilder.group({
      FechaDesde: [new Date((new Date().getTime())), Validators.compose([Validators.required,])],
      FechaHasta: [new Date(), Validators.compose([Validators.required,])],
      RangoFecha: [''],
      FullUrl: [''],
    })
  }


  private paginator!: MatPaginator;
  private sort!: MatSort;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    if (this.paginator && this.sort) {
      this.applyFilter(null);
    }
  }
  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  filtrosAvanzados(event: any) {
    if (event.checked == true)
      this.checkedFiltroAvanzado = true
    else
      this.checkedFiltroAvanzado = false

    this.getAllByFilter();

  }

  openDialog(action: any, obj: { action?: any; }) {
    obj.action = action;
    const dialogRef = this.dialog.open(DialogViewAuditorialogComponent, {
      width: '80%',
      data: obj,
      autoFocus: false
    });

  }


  getAllByFilter() {
    this.spinner.show()
    var auditoriaLogFilter = new AuditoriaLogFilter
    let fullUrl = this.form.controls["FullUrl"].value
    //Fijos
    let fechaEmisionDesde = this.datepipe.transform(this.form.controls["FechaDesde"].value, 'yyyy-MM-dd') as string;
    let fechaEmisionHasta = this.datepipe.transform(this.form.controls["FechaHasta"].value, 'yyyy-MM-dd') as string;

    // Ajustar la hora para la fecha desde
    fechaEmisionDesde = fechaEmisionDesde + ' 00:00:00';
    // Ajustar la hora para la fecha hasta
    fechaEmisionHasta = fechaEmisionHasta + ' 23:59:59';

    auditoriaLogFilter.fechaDesde = fechaEmisionDesde
    auditoriaLogFilter.fechaHasta = fechaEmisionHasta
    auditoriaLogFilter.fullUrl = fullUrl
    this.autoriaLogService.getAllByFilter(auditoriaLogFilter)
      .subscribe(
        data => {
          this.dataSource.data = data.sort((a, b) => new Date(a.timestamp).getTime() + new Date(b.timestamp).getTime());
          this.data = this.dataSource.data.length >= 1 ? true : false
          this.spinner.hide();
        },
        error => {
          this.spinner.hide();
          this.snackBar.showError(error, "Error");
        })
  }

  selectRangoFecha(event: any) {
    this.rangoFecha = event.value
    if (this.rangoFecha == RangoFechasValores.Todos) {
      this.form.controls["FechaDesde"].setValue(new Date((new Date().getTime() - this.rangoFecha)))
      this.form.controls["FechaHasta"].setValue(new Date((new Date().getTime() + this.rangoFecha)))
    }
    else
      this.form.controls["FechaDesde"].setValue(new Date((new Date().getTime() - this.rangoFecha)))
  }


  /** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  applyFilter(event: any) {
    let filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


  // openDialogUpdateMovimientoEliminar(id: number, accion: string) {
  //   let dialogConfig = new MatDialogConfig();
  //   dialogConfig.autoFocus = false;
  //   dialogConfig.data = { id: id, event: AccionesDialog.Eliminar };
  //   dialogConfig.width = "30%";
  //   let dialogRef = this.dialog.open(DialogDeleteComponent, dialogConfig);
  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result.event == AccionesDialog.Eliminar) {
  //       this.deleteMovimiento(result.data)
  //     }
  //   });
  // }


  clearFilter() {
  }
  isFalsy(valor: any) {
    return isFalsy(valor)
  }

  trackByItems(index: number, item: any): any { return item; }
}
