import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccionesDialog, RangoFechasValores } from '@models/base/identificadores.model';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { IndiceAjusteContratoService } from '@services/indice-ajuste-contrato/indiceAjusteContrato.service';
import { CreateIndiceAjusteContratoRequest } from '@models/indice-ajuste-contrato/createIndiceAjusteContratoRequest.model';
import { TipoAjusteContratoDto } from '@models/tipo-ajuste-contrato/tipoAjusteContratoDto.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TipoAjusteContratoService } from '@services/tipo-ajuste-contrato/tipoAjusteContrato.service';
import { isFalsy } from 'utility-types';
import { DatePipe } from '@angular/common';
import { IndiceAjusteContratoFilter } from '@models/indice-ajuste-contrato/indiceAjusteContratoFilter.model';
import { Genericos } from '@models/base/genericos.model';
import { IndiceAjusteContratoDto } from '@models/indice-ajuste-contrato/indiceAjusteContratoDto.model';
import { DialogIndiceAjusteContratoComponent } from '@views/indices-ajuste-contrato/dialog-indice-ajuste-contrato/dialog-indice-ajuste-contrato.component';
import { DialogWebICLReferenciaComponent } from '@views/indices-ajuste-contrato/dialog-web-iclreferencia/dialog-web-iclreferencia.component';
import { UpdateIndiceAjusteContratoRequest } from '@models/indice-ajuste-contrato/updateIndiceAjusteContratoRequest.model';

@Component({
  selector: 'app-indices-ajuste-contrato',
  templateUrl: './indices-ajuste-contrato.component.html',
  styleUrls: ['./indices-ajuste-contrato.component.css'],
  providers: [DatePipe],
})
export class IndicesAjusteContratoComponent implements OnInit {

  dataSource = new MatTableDataSource<IndiceAjusteContratoDto>();
  obj!: undefined
  displayedColumns: string[] = [];
  data = false
  lstRangoFecha = Genericos.lstRangoFecha
  form: FormGroup;
  formBuilder = new FormBuilder
  rangoFecha: number = RangoFechasValores.Hoy // Hoy

  @ViewChild(MatTable, { static: true }) table!: MatTable<IndiceAjusteContratoDto>;

  private paginator!: MatPaginator;
  private sort!: MatSort;
  lstTiposAjusteContrato!: TipoAjusteContratoDto[];
  indiceAjusteContratoFilter!: IndiceAjusteContratoFilter

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    if (this.paginator && this.sort) {
      this.applyFilter(null);
    }
  }

  constructor(
    public dialog: MatDialog,
    private indiceAjusteContratoService: IndiceAjusteContratoService,
    private snackBar: SnackBarService,
    private tipoAjusteContratoService: TipoAjusteContratoService,
    private spinner: NgxSpinnerService,
    private breakpointObserver: BreakpointObserver,
    private datepipe: DatePipe
  ) {

    this.form = this.formBuilder.group({
      TiposAjusteContrato: [''],
      FechaDesde: [new Date((new Date().getTime() - RangoFechasValores.Hoy)), Validators.compose([Validators.required,])],
      FechaHasta: [new Date(), Validators.compose([Validators.required,])],
    })

    breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.displayedColumns = result.matches ?
        ['fecha', 'valor', 'tipoAjusteContratoDto', 'accion'] :
        ['fecha', 'valor', 'tipoAjusteContratoDto', 'accion']
    });
  }


  ngOnInit() {
    this.getAllTipoAjusteContrato()
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getAllIndiceAjusteContratoByFilter(indiceAjusteContratoFilter: IndiceAjusteContratoFilter) {
    this.spinner.show()
    this.indiceAjusteContratoService.getAllByFilter(indiceAjusteContratoFilter)
      .subscribe(
        data => {
          this.spinner.hide();
          this.dataSource.data = data
          this.data = this.dataSource.data.length >= 1 ? true : false
        },
        error => {
          this.spinner.hide();
          this.snackBar.showError(error, "Error");
        }
      )
  }

  openDialog(action: any, obj: { action?: any; }) {
    obj.action = action;

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.data = obj
    dialogConfig.width = "70%"

    const dialogRef = this.dialog.open(DialogIndiceAjusteContratoComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == AccionesDialog.Agregar) {
        this.addRowData(result.data);
      } else if (result.event == AccionesDialog.Modificar) {
        this.updateRowData(result.data);
      } else if (result.event == AccionesDialog.Eliminar) {
        this.deleteRowData(result.data);
      }
    });
  }

  applyFilter(event: any) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  selectRangoFecha(event: any) {
    this.rangoFecha = event.value
    if (this.rangoFecha == RangoFechasValores.Todos) {
      this.form.controls["FechaDesde"].setValue(new Date((new Date().getTime() - this.rangoFecha)))
      this.form.controls["FechaHasta"].setValue(new Date((new Date().getTime() + this.rangoFecha)))
    }
    else {
      this.form.controls["FechaDesde"].setValue(new Date((new Date().getTime() - this.rangoFecha)))
      this.form.controls["FechaHasta"].setValue(new Date((new Date().getTime())))
    }
  }



  filtrar() {
    //Fijos
    let fechaDesde = this.datepipe.transform(this.form.controls["FechaDesde"].value, 'yyyy-MM-dd') as string;
    let fechaHasta = this.datepipe.transform(this.form.controls["FechaHasta"].value, 'yyyy-MM-dd') as string;
    // Ajustar la hora para la fecha desde
    fechaDesde = fechaDesde + ' 00:00:00';

    // Ajustar la hora para la fecha hasta
    fechaHasta = fechaHasta + ' 23:59:59';
    //Variables
    let idsTipoAjusteContrato = this.form.controls["TiposAjusteContrato"].value

    this.indiceAjusteContratoFilter = new IndiceAjusteContratoFilter
    this.indiceAjusteContratoFilter.fechaDesde = fechaDesde
    this.indiceAjusteContratoFilter.fechaHasta = fechaHasta
    this.indiceAjusteContratoFilter.idsTipoAjusteContrato = idsTipoAjusteContrato
    this.getAllIndiceAjusteContratoByFilter(this.indiceAjusteContratoFilter)

  }


  addRowData(row_obj: { fecha: string, valor: number, idTipoAjusteContrato: number, descripcion: string }) {
    this.spinner.show()
    let createIndiceAjusteContratoRequest = new CreateIndiceAjusteContratoRequest
    createIndiceAjusteContratoRequest.fecha = row_obj.fecha
    createIndiceAjusteContratoRequest.valor = row_obj.valor
    createIndiceAjusteContratoRequest.activo = true
    createIndiceAjusteContratoRequest.idTipoAjusteContrato = row_obj.idTipoAjusteContrato
    createIndiceAjusteContratoRequest.descripcion = row_obj.descripcion

    if (createIndiceAjusteContratoRequest) {
      this.spinner.show()
      this.indiceAjusteContratoService.create(createIndiceAjusteContratoRequest)
        .subscribe(
          data => {
            if (data.id >= 0) {
              this.filtrar()
              this.snackBar.showSuccess("Registro agregado correctamente.", "Exito");
            }
            else
              this.snackBar.showWarn("No se puede ingresar mas de un valor para la misma fecha.", "Exito");
            this.spinner.hide()

          },
          error => {
            this.spinner.hide()
            this.snackBar.showError(error, "Error");
          }
        )
    }
    else {
      this.spinner.hide()
      this.snackBar.showWarn("El registro ya existe", "Error");
    }

  }
  updateRowData(row_obj: { id: number; fecha: string, idTipoAjusteContrato: number, valor: number, descripcion: string }) {
    this.spinner.show()
    let updateIndiceAjusteContratoRequest = new UpdateIndiceAjusteContratoRequest
    updateIndiceAjusteContratoRequest.id = row_obj.id
    updateIndiceAjusteContratoRequest.fecha = row_obj.fecha
    updateIndiceAjusteContratoRequest.valor = row_obj.valor
    updateIndiceAjusteContratoRequest.idTipoAjusteContrato = row_obj.idTipoAjusteContrato
    updateIndiceAjusteContratoRequest.descripcion = row_obj.descripcion

    if (updateIndiceAjusteContratoRequest) {
      this.spinner.show()
      this.indiceAjusteContratoService.update(updateIndiceAjusteContratoRequest)
        .subscribe(
          data => {
            this.spinner.hide()
            this.snackBar.showSuccess("Registro actualizado correctamente.", "Exito");
            this.filtrar()
          },
          error => {
            this.spinner.hide()
            this.snackBar.showError(error, "Error");
          })
    }
    else {
      this.spinner.hide()
      this.snackBar.showSuccess("El registro ya existe", "Error");
    }
  }

  deleteRowData(row_obj: { id: number; }) {
    this.spinner.show()
    this.indiceAjusteContratoService.delete(row_obj.id)
      .subscribe(
        data => {
          this.spinner.hide()
          this.snackBar.showSuccess("Registro eliminado correctamente.", "Exito");
          this.getAllIndiceAjusteContratoByFilter(this.indiceAjusteContratoFilter)
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        })

  }

  openDialogWebIclReferencia() {
    const dialogRef = this.dialog.open(DialogWebICLReferenciaComponent);

    dialogRef.afterClosed().subscribe(result => {
    });
  }


  getAllTipoAjusteContrato() {
    this.spinner.show("spBusquedaTipoAjusteContrato")
    this.tipoAjusteContratoService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spBusquedaTipoAjusteContrato")
          this.lstTiposAjusteContrato = data.sort((a, b) => a.descripcion.toString().localeCompare(b.descripcion.toString()))
        },
        error => {
          this.spinner.hide("spBusquedaTipoAjusteContrato")
          this.snackBar.showError(error, "Error");
        }
      )
  }


  //#region Helper

  isFalsy(valor: any) {
    return isFalsy(valor)
  }

  trackByItems(index: number, item: any): any { return item; }
  //#endregion
}
