import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { BaseDto } from '@models/base/baseDto.model';
import { CreateKeyValueRequest } from '@models/base/createKeyValueRequest.model';
import { AccionesDialog } from '@models/base/identificadores.model';
import { UpdateKeyValueRequest } from '@models/base/updateKeyValueRequest.model';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { TiposMoviemientoService } from '@services/tipos-movimiento/tipos-movimiento.service';
import { DialogTipoMovimientoComponent } from '../dialog-tipo-movimiento/dialog-tipo-movimiento.component';
import { UpdateTipoMovimientoRequest } from '@models/tipos-movimiento/updateTipoMovimientoRequest.model';
import { CreateTipoMovimientoRequest } from '@models/tipos-movimiento/createTipoMovimientoRequest.model';

@Component({
  selector: 'app-tipos-movimiento',
  templateUrl: './tipos-movimiento.component.html',
  styleUrls: ['./tipos-movimiento.component.css']
})
export class TiposMovimientoComponent implements OnInit {

  data=false
  dataSource = new MatTableDataSource<BaseDto>();
  obj!: undefined
  displayedColumns: string[] = [];

  @ViewChild(MatTable, { static: true }) table!: MatTable<any>;

  private paginator!: MatPaginator;
  private sort!: MatSort;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    if (this.paginator && this.sort) {
      this.applyFilter(null);
    }
  }

  constructor(
    public dialog: MatDialog,
    private tiposMoviemientoService: TiposMoviemientoService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.displayedColumns = result.matches ?
        ['descripcion', 'accion'] :
        ['descripcion', 'accion'];
    });
  }


  ngOnInit() {
    this.getTiposMovimientos();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getTiposMovimientos() {
    this.spinner.show()
    this.tiposMoviemientoService.getAll()
      .subscribe(
        data => {
          this.spinner.hide()
          this.dataSource.data = data.sort((a, b) => a.descripcion.toString().localeCompare(b.descripcion.toString()))
          this.data = this.dataSource.data.length >= 1 ?  true : false
        },
        error => {
          this.snackBar.showError( error, "Error");
          this.spinner.hide()
        }
      )
  }

  openDialog(action: any, obj: { action?: any; }) {
    obj.action = action;

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.data = obj
    dialogConfig.width = "30%"
    const dialogRef = this.dialog.open(DialogTipoMovimientoComponent,dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == AccionesDialog.Agregar) {
        this.addRowData(result.data);
      } else if (result.event == AccionesDialog.Modificar) {
        this.updateRowData(result.data);
      } else if (result.event == AccionesDialog.Eliminar) {
        this.deleteRowData(result.data);
      }
    });
  }

  applyFilter(event: any) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


  addRowData(row_obj: { descripcion: any }) {
    this.spinner.show()
    let createTipoMovimientoRequest = new CreateTipoMovimientoRequest
    createTipoMovimientoRequest.descripcion = row_obj.descripcion

    let existe = false
    this.dataSource.data.forEach((element, index) => {
      if (element.descripcion === row_obj.descripcion)
        existe = true
    });

    if (createTipoMovimientoRequest && !existe) {
      this.tiposMoviemientoService.create(createTipoMovimientoRequest)
        .subscribe(
          data => {
            this.spinner.hide()
            this.dataSource.data.push({
              id: data.id,
              descripcion: row_obj.descripcion
            });
            this.dataSource.data = this.dataSource.data.filter((value: { id: number; descripcion: string }, key: any) => {
              return true;
            }).sort((a, b) => a.descripcion.toString().localeCompare(b.descripcion.toString()))

            this.data = this.dataSource.data.length >= 1 ?  true : false
            this.snackBar.showSuccess("Registro agregado correctamente.", "Exito");
          },
          error => {
            this.spinner.hide()
            this.snackBar.showError( error, "Error");
          }
        )
    }
    else {
      this.spinner.hide()
      this.snackBar.showWarn("El registro ya existe", "Error");
    }

  }
  updateRowData(row_obj: { id: number; descripcion: string }) {
    this.spinner.show()
    let updateTipoMovimientoRequest = new UpdateTipoMovimientoRequest
    updateTipoMovimientoRequest.id = row_obj.id
    updateTipoMovimientoRequest.descripcion = row_obj.descripcion

    let existe = false
    this.dataSource.data.forEach((element, index) => {
      if (element.descripcion === row_obj.descripcion)
        existe = true
    });
    if (updateTipoMovimientoRequest && !existe) {
      this.tiposMoviemientoService.update(updateTipoMovimientoRequest)
        .subscribe(
          data => {
            this.spinner.hide()
            this.dataSource.data = this.dataSource.data.filter((value: { id: number; descripcion: string }, key: any) => {
              if (value.id == row_obj.id) {
                value.descripcion = row_obj.descripcion;
              }
              return true;
            });
            this.data = this.dataSource.data.length >= 1 ?  true : false
            this.snackBar.showSuccess("Registro actualizado correctamente.", "Exito");
          },
          error => {
            this.spinner.hide()
            this.snackBar.showError( error, "Error");
          })
    }
    else
    {
      this.spinner.hide()
      this.snackBar.showWarn("El registro ya existe", "Error");
    }
  }

  deleteRowData(row_obj: { id: number; }) {
    this.dataSource.data = this.dataSource.data.filter((value: { id: number; }, key: any) => {
      return value.id != row_obj.id;
    });
    this.data = this.dataSource.data.length >= 1 ?  true : false
  }

}
