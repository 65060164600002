import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { BaseDto } from '@models/base/baseDto.model';
import { HistoricoPeriodoContratoDto } from '@models/historico-periodo-contrato/historicoPeriodoContratoDto.model';
import { HistoricosPeriodoContratoService } from '@services/historicos-periodos-contrato/historicos-periodo-contrato.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';


@Component({
  selector: 'app-listado-periodos-historicos',
  templateUrl: './listado-periodos-historicos.component.html',
  styleUrls: ['./listado-periodos-historicos.component.css']
})

export class ListadoPeriodosHistoricosComponent implements OnInit {

  @Input() procesado = false
  dataSource = new MatTableDataSource<HistoricoPeriodoContratoDto>();
  obj?: any
  @Input() idContrato: number = 0
  displayedColumns: string[] = [];
  data = false
  public contratoControl = new FormControl();

  @ViewChild(MatTable, { static: true }) table!: MatTable<any>;
  private paginator!: MatPaginator;
  private sort!: MatSort;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    if (this.paginator && this.sort) {
      this.applyFilter(null);
    }
  }

  listaContratos: BaseDto[] = [];
  public filteredContratos!: Observable<BaseDto[]>;

  constructor(
    public dialog: MatDialog,
    public historicosPeriodoContratoService: HistoricosPeriodoContratoService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private breakpointObserver: BreakpointObserver
  ) {

    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.displayedColumns = result.matches ?
        ["fechaProceso", "periodo", "contrato", "importeNuevo",] :
        ["fechaProceso", "periodo", "valorAjuste","tipoAjusteContrato", "contrato", "importeAnterior", "importeNuevo"];
    });
  }



  ngOnInit() {
    this.idContrato != 0 ? this.getAllByIdContrato(this.idContrato) : null

    this.dataSource.filterPredicate = function (data: HistoricoPeriodoContratoDto, filter: string): boolean {
      return data.contrato.descripcion.toLowerCase().includes(filter) || data.periodo.toString().includes(filter);
    };
  }

  ngOnChanges() {
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getAll() {
    this.spinner.show()
    this.historicosPeriodoContratoService.getAll()
      .subscribe(
        data => {
          this.spinner.hide()
          this.dataSource.data = data.sort((a, b) => b.fechaProceso.toString().localeCompare(a.fechaProceso.toString()))
          this.data = this.dataSource.data.length >= 1 ? true : false
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        }
      )
  }


  getAllByIdContrato(id: number) {
    this.spinner.show()
    this.historicosPeriodoContratoService.getAllByByIdContrato(id)
      .subscribe(
        data => {
          this.spinner.hide()
          this.dataSource.data = data
          this.data = this.dataSource.data.length >= 1 ? true : false
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        }
      )
  }

  applyFilter(event: any) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
