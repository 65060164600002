export class SubMenu{
  displayName!: string
  iconName!: string
  route!:string
}

export class Menu{
  displayName!: string
  iconName!: string
  submenues: SubMenu[] = []
  route!:string
}
