import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { PersonaSummaryDto } from '@models/personas/personaSummaryDto.mode';
import { PersonasService } from '@services/personas/personas.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { isFalsy } from 'utility-types';
import { AccionesDialog } from '@models/base/identificadores.model';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DialogDeletePersonaComponent } from '../dialog-delete-persona/dialog-delete-persona.component';
import { FormPersonaReactiveComponent } from '../form-persona-reactive/form-persona-reactive.component';
import { PersonaDto } from '@models/personas/personaDto.model';
import { PersonaDetailDto } from '@models/personas/personaDetailDto.model';
import { DatePipe } from '@angular/common';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { timeStamp } from 'console';
import { TiposDocumentoService } from '@services/tipos-documento/tipos-documento.service';
import { TipoDocumentoDto } from '@models/tipo-documento/tipoDocumentoDto.model';
import { BaseDto } from '@models/base/baseDto.model';

@Component({
  selector: 'app-listado-personas',
  templateUrl: './listado-personas.component.html',
  styleUrls: ['./listado-personas.component.css'],
  providers: [DatePipe],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ListadoPersonasComponent implements OnInit {
  data = false
  displayedColumns: string[] = [];
  dataSource = new MatTableDataSource<PersonaDto>()
  lstPersonaDto!: PersonaDto[]
  formBuilder: FormBuilder = new FormBuilder;
  form: FormGroup;
  tiposDocumentos!: TipoDocumentoDto[];
  localidad!: BaseDto | null;
  checkedFiltroAvanzado = false
  get f() { return this.form.controls; }
  constructor(
    private tiposDocumentoService: TiposDocumentoService,
    private personasService: PersonasService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private dialog: MatDialog,

    private datepipe: DatePipe,
  ) {
    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.displayedColumns = result.matches ?
        ["nombreCompleto", "tipoPersona", "accion"] :
        ["nombreCompleto", "tipoPersona", "documento", "correo", "estado", "accion"];
    });

    this.form = this.formBuilder.group({
      TiposDocumento: [''],
      TiposEstado: [''],
      TiposPersona: [''],
      NombreCompleto: [''],
      Correo: [''],
      NroDocumento: [''],
      Estado: [''],
      FechaAltaDesde: [''],
      FechaAltaHasta: [''],
    })
  }


  private paginator!: MatPaginator;
  private sort!: MatSort;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    if (this.paginator && this.sort) {
      this.applyFilter(null);
    }
  }

  ngOnInit(): void {
    this.getAllTipoDocumento();
  }

  applyFilter(event: any) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  filtrosAvanzados(event: any) {
    if (event.checked == true)
      this.checkedFiltroAvanzado = true
    else
      this.checkedFiltroAvanzado = false
  }


  getAllPersonas() {
    this.spinner.show()
    this.personasService.getAll()
      .subscribe(
        data => {
          this.spinner.hide();
          this.dataSource.data = data.sort((a, b) => a.nombreCompleto.toString().localeCompare(b.nombreCompleto.toString())) as PersonaDto[]
          this.lstPersonaDto = this.dataSource.data
          this.data = this.dataSource.data.length >= 1 ? true : false

          let estado = this.form.controls["TiposEstado"].value
          let tipoPersona = this.form.controls["TiposPersona"].value
          this.filterTiposEstado(estado)
          this.filterTipoPersonas(tipoPersona)
        },
        error => {
          this.spinner.hide();
          this.snackBar.showError(error, "Error");
        })
  }

  filterTipoPersonas(tipoPersona: string) {
    // this.dataSource.data = this.personas
    switch (tipoPersona) {
      case "Todos":
        break;
      case "Inquilinos":
        this.dataSource.data = this.dataSource.data.filter(x => x.esInquilino == true)
        break;
      case "Propietarios":
        this.dataSource.data = this.dataSource.data.filter(x => x.esPropietario == true)
        break;
      case "Garantias":
        this.dataSource.data = this.dataSource.data.filter(x => x.esGarante == true)
        break;
      default:
        break;
    }
  }

  filterTiposEstado(event: string) {
    // this.dataSource.data = this.personas
    switch (event) {
      case "Todos":
        break;
      case "Activos":
        this.dataSource.data = this.dataSource.data.filter(x => x.estado == false)
        break;
      case "Baja":
        this.dataSource.data = this.dataSource.data.filter(x => x.estado == true)
        break;
      default:
        break;
    }
  }

  filtrar() {
    let IdLocalidad = null
    let FechaBajaDesde = null
    let FechaBajaHasta = null
    let FechaAltaDesde = null
    let FechaAltaHasta = null

    if (this.checkedFiltroAvanzado) {

      IdLocalidad = isFalsy(this.localidad) ? null : this.localidad.id
      FechaAltaDesde = this.datepipe.transform(this.form.controls["FechaAltaDesde"].value, 'yyyy-MM-dd') as string;
      FechaAltaHasta = this.datepipe.transform(this.form.controls["FechaAltaHasta"].value, 'yyyy-MM-dd') as string;
      FechaBajaDesde = null
      FechaBajaHasta = null
    }


    let IdTipoDocumentos = this.form.controls["TiposDocumento"].value as number
    let NombreCompleto = this.form.controls["NombreCompleto"].value
    let Correo = this.form.controls["Correo"].value
    let NroDocumento = this.form.controls["NroDocumento"].value
    let Estado = this.form.controls["Estado"].value

    //Variables
    let idTipoDocumentos = isFalsy(IdTipoDocumentos) ? null : IdTipoDocumentos
    let nombreCompleto = isFalsy(NombreCompleto) ? null : NombreCompleto
    let correo = isFalsy(Correo) ? null : Correo
    let nroDocumento = isFalsy(NroDocumento) ? null : NroDocumento
    let estado = isFalsy(Estado) ? null : Estado
    let fechaAltaDesde = isFalsy(FechaAltaDesde) ? null : FechaAltaDesde
    let fechaAltaHasta = isFalsy(FechaAltaHasta) ? null : FechaAltaHasta
    let fechaBajaDesde = isFalsy(FechaBajaDesde) ? null : FechaBajaDesde
    let fechaBajaHasta = isFalsy(FechaBajaHasta) ? null : FechaBajaHasta
    let idLocalidad = isFalsy(IdLocalidad) ? null : IdLocalidad



    this.getAllByFilter(fechaAltaDesde, fechaBajaDesde, fechaBajaHasta, fechaAltaHasta, idLocalidad, idTipoDocumentos, nombreCompleto, nroDocumento, correo, estado)
  }

  getAllByFilter(fechaAltaDesde: string | null, fechaBajaDesde: string | null, fechaBajaHasta: string | null, fechaAltaHasta: string | null, idLocalidad: number | null, idTipoDocumentos: number | null, nombreCompleto: string | null, nroDocumento: string | null, correo: string | null, estado: boolean | null) {
    this.spinner.show()
    this.personasService.getAllByFilter(fechaAltaDesde, fechaBajaDesde, fechaBajaHasta, fechaAltaHasta, idLocalidad, idTipoDocumentos, nombreCompleto, nroDocumento, correo, estado)
      .subscribe(
        data => {
          this.spinner.hide();
          this.dataSource.data = data.sort((a, b) => a.nombreCompleto.toString().localeCompare(b.nombreCompleto.toString())) as PersonaDto[]
          this.lstPersonaDto = this.dataSource.data
          this.data = this.dataSource.data.length >= 1 ? true : false
          let tipoPersona = this.form.controls["TiposPersona"].value
          this.filterTipoPersonas(tipoPersona)
        },
        error => {
          this.spinner.hide();
          this.snackBar.showError(error, "Error");
        })
  }

  clearFilter() {
    this.form.controls["TiposDocumento"].setValue(null)
    this.form.controls["NombreCompleto"].setValue(null)
    this.form.controls["Correo"].setValue(null)
    this.form.controls["NroDocumento"].setValue(null)
    this.form.controls["Estado"].setValue(null)
    this.form.controls["FechaAltaDesde"].setValue(null)
    this.form.controls["FechaAltaHasta"].setValue(null)
    this.form.controls["TiposPersona"].setValue(null)
    this.localidad = null

  }

  editarPersona(personaDto: PersonaDto) {
    this.getPersonaById(personaDto.id)
  }

  seleccionaLocalidad(data: BaseDto) {
    this.localidad = data
  }

  openDialog(personaDto: PersonaDto): void {
    let personaDetailDto = new PersonaDetailDto
    personaDetailDto.id = personaDto.id
    personaDetailDto.nombreCompleto = personaDto.nombreCompleto
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.data = personaDetailDto

    const dialogRef = this.dialog.open(DialogDeletePersonaComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.event == AccionesDialog.Eliminar) {
        this.getAllPersonas()
      }
    });
  }

  reactivar(personaDto: PersonaDto): void {
    let personaDetailDto = new PersonaDetailDto
    personaDetailDto.id = personaDto.id
    personaDetailDto.nombreCompleto = personaDto.nombreCompleto
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.data = personaDetailDto

    const dialogRef = this.dialog.open(FormPersonaReactiveComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.event == AccionesDialog.Eliminar) {
        this.getAllPersonas()
      }
    });
  }

  getPersonaById(idPersona: number) {
    this.spinner.show("spPersona")
    this.personasService.getPersonaById(idPersona)
      .subscribe(
        data => {
          this.spinner.hide("spPersona")
          this.personasService.setPersona(data)
          this.router.navigate(["/personas/editar"])
        },
        error => {
          this.spinner.hide("spPersona")
          this.snackBar.showError(error, "Error");
        });
  }

  getAllTipoDocumento() {
    this.spinner.show("spTipoDocumento")
    this.tiposDocumentoService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spTipoDocumento")
          this.tiposDocumentos = data.sort((a, b) => a.descripcion.localeCompare(b.descripcion));
        },
        error => {
          this.spinner.hide("spTipoDocumento")
          this.snackBar.showError(error, "Error");
        });
  }

  isFalsy(valor: any) {
    return isFalsy(valor)
  }

  trackByItems(index: number, item: any): any { return item; }


}
