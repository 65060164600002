<ngx-spinner [zIndex]=100 name="spGarantia" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
</ngx-spinner>

<form [formGroup]="formGarantia">
    <div class="row">
        <div class=" col-lg-5">
            <app-buscar-persona-garante (eventEntity)="seleccionGarantia($event)"></app-buscar-persona-garante>
        </div>
        <div class=" col-lg-4" *ngIf="personaGarante">
            <p><b><span>Persona:</span></b> <a [routerLink]="['/personas/',personaGarante.id]"> {{personaGarante.descripcion | titlecase}}</a></p>
            <p><b><span>Nro. Documento:</span></b> {{personaGarante.nroDocumento | titlecase}}</p>
        </div>
        <div class=" col-lg-3">
            <button mat-raised-button *ngIf="garantiasInquilino.length != 0 && listGarantia.selectedOptions.selected.length > 0" (click)="asignarGarantia()" [disabled]="!formGarantia" color="primary"><mat-icon>person</mat-icon>
            Asignar garantia</button>
            <div class="with-icon" *ngIf="!conGarantias">
              <mat-icon class="padding-icon">warning</mat-icon> <span>Garante sin garantias</span>
            </div>
        </div>
    </div>
    <mat-divider></mat-divider>
    <div class="row">
        <mat-selection-list class="table" #listGarantia (selectionChange)="selectionChange($event.option)">
            <mat-list-option *ngFor="let garantia of garantiasInquilino;let i=index;trackBy:trackByItems" [value]="garantia.id">
                <div class="row">
                    <div class="col-3"><strong><span>Tipo Garantia:&nbsp; </span></strong> <span>{{isFalsy(garantia.tipoGarantia) ? "" : garantia.tipoGarantia.descripcion | titlecase}}&nbsp;&nbsp;</span></div>
                    <div class="col-1">
                        <strong *ngIf="garantia.tomo != '' || null || garantia.nroFolio != '' || null || garantia.numero != '' || null"><span matSuffix>Legajo</span> <mat-icon [matTooltip]="'Tomo: '+garantia.tomo   +  '\n Folio: ' + garantia.nroFolio +  '\n Número: ' + garantia.numero" [matTooltipClass]="'allow-cr'" matSuffix color="primary" class="icon-medium">info</mat-icon></strong>
                    </div>
                    <div class="col-3">
                        <strong><span *ngIf="garantia.razonSocial">Razon Social:&nbsp; </span></strong><span>{{garantia.razonSocial | titlecase}}&nbsp;&nbsp;</span>
                    </div>
                    <div class="col-3">
                        <strong><span *ngIf="garantia.cuitCuil"> Cuit/Cuil:&nbsp; </span></strong> <span>{{garantia.cuitCuil}}&nbsp;&nbsp;</span>
                    </div>
                    <div class="col-2">
                        <strong><span *ngIf="garantia.activo">  Estado:&nbsp; </span></strong><span *ngIf="garantia.activo" class="badge text-white" [ngClass]="{'bg-success': garantia.activo==true,'bg-danger': garantia.activo!=true }"><span>{{garantia.activo == true ? 'Validada': "Inactivo" }}</span></span>
                    </div>
                </div>

            </mat-list-option>
        </mat-selection-list>
    </div>
</form>
