<mat-tab-group>

    <mat-tab label="Recibos">
        <br>
        <app-listado-comprobantes-recibos></app-listado-comprobantes-recibos>
    </mat-tab>
    <mat-tab label="Liquidaciones">
        <br>
        <app-listado-comprobantes-liquidaciones></app-listado-comprobantes-liquidaciones>
    </mat-tab>
    <mat-tab label="Caja">
        <br>
        <app-listado-comprobantes-genericos></app-listado-comprobantes-genericos>
    </mat-tab>
    <mat-tab label="Acreditaciones">
        <br>
        <app-listado-acreditaciones></app-listado-acreditaciones>
    </mat-tab>
</mat-tab-group>