<div class="position-relative">
  <ngx-spinner [zIndex]=100 name="spMovimiento" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688"
    type="ball-atom" [fullScreen]="false">
  </ngx-spinner>
  <form [formGroup]="movimientoForm">
    <div class="alert alert-warning alert-dismissible fade show" role="alert">
      <strong>Atención!</strong> Siempre se debe seleccionar un <strong>Inquilino</strong> o un
      <strong>Propietario</strong> y un <strong>Contrato</strong> para asignar el movimiento correctamente.
      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="row">

      <div class="col-lg-3">
        <app-buscar-contrato cdkFocusInitial [width]="classContrato" (eventEntity)="selectContrato($event)">
        </app-buscar-contrato>
      </div>
      <div class="col-lg-1">
        <button mat-button (click)="verContrato()" [disabled]="contratoDetailDto == undefined || loading"
          style="position: relative;">
          <span *ngIf="!loading"> Ver </span>
          <mat-icon *ngIf="!loading">visibility</mat-icon>
          <mat-spinner *ngIf="loading" diameter="20"
            style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%);"></mat-spinner>
        </button>
      </div>
      <div class="col-lg-2">
        <mat-form-field>
          <ngx-spinner [zIndex]=100 name="spBusquedaTiposMovimiento" bdColor="rgba(255,255,255,0.8)" size="small"
            color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
          <mat-label for="TipoMovimiento">Tipo Movimiento</mat-label>
          <mat-select  placeholder="Seleccione opcion" formControlName="TipoMovimiento"
            (selectionChange)="onTipoMovimientoChange($event.value)">
            <mat-option [value]="tipoMovimiento.id"
              *ngFor="let tipoMovimiento of lstTiposMovimiento;let i=index;trackBy:trackByItems">
              {{tipoMovimiento.resta == true ? tipoMovimiento.descripcion + ' (-)' : tipoMovimiento.descripcion + '
              (+)'}}</mat-option>
          </mat-select>
          <mat-error *ngIf="f.TipoMovimiento.errors?.required">Este campo es <strong>requerido</strong>.
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-lg-2" *ngIf="showBonificaciones">
        <mat-form-field>
          <ngx-spinner [zIndex]=100 name="spBusquedaTiposBonificaciones" bdColor="rgba(255,255,255,0.8)" size="small"
            color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
          <mat-label for="Bonificaciones">Bonificaciones</mat-label>
          <mat-select placeholder="Seleccione opcion" formControlName="Bonificaciones" (selectionChange)="onTipoBonificacionChange($event.value)">
            <mat-option [value]="bonificacion" [matTooltip]="bonificacion.descripcion"
              *ngFor="let bonificacion of lstBonificaciones;let i=index;trackBy:trackByItems">
              {{bonificacion.detalle}} - {{isFalsy(bonificacion.tipoBonificacion) ? "" :
              bonificacion.tipoBonificacion.descripcion}}</mat-option>
          </mat-select>
          <mat-error *ngIf="f.Bonificaciones.errors?.required">Este campo es <strong>requerido</strong>.
          </mat-error>
        </mat-form-field>
      </div>
      <!-- <div class="col-lg-2">
        <mat-form-field>
          <mat-label>Mes y Año</mat-label>
          <input matInput [matDatepicker]="dp" formControlName="Periodo">
          <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
          <mat-datepicker #dp startView="multi-year" (yearSelected)="chosenYearHandler($event)"
            (monthSelected)="chosenMonthHandler($event, dp)" panelClass="example-month-picker">
          </mat-datepicker>
        </mat-form-field>
      </div> -->
      <div class="col-lg-2">
        <mat-form-field class="example-full-width">
          <span matPrefix>$ &nbsp;</span>
          <mat-label for="Importe">Importe </mat-label>
          <input type="text" mask="separator.2" thousandSeparator="," placeholder="Importe" formControlName="Importe"
            matInput>
          <mat-error *ngIf="f.Importe.errors?.required">Este campo es <strong>requerido</strong>.
          </mat-error>
        </mat-form-field>
      </div>
      <mat-form-field >
        <mat-label>Fecha</mat-label>
        <input matInput [matDatepicker]="picker" formControlName="FechaEmision">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error *ngIf="f.FechaEmision.errors?.required">Este campo es <strong>requerido</strong>.
        </mat-error>
      </mat-form-field>
    </div>
    <div class="row">
      <div class="col-lg-4">
        <app-buscar-persona-inquilino *ngIf="!inquilinoDto"
          (eventEntity)="selectInquilino($event)">
        </app-buscar-persona-inquilino>

        <div *ngIf="inquilinoDto" class="inquilino-info" [ngClass]="{'selected': movimientoForm.get('IsSelectedCheckInquilino')?.value}">
          <span>
            <b>Inquilino: </b> {{isFalsy(inquilinoDto) ? "" : inquilinoDto.persona.descripcion | titlecase}}
          </span>

          <div class="actions-container">
            <div class="close">
              <mat-icon mat-fab  *ngIf="!esMovimientoContrato" color="warn" (click)="selectInquilino(null)" matTooltip="Quitar"
                matTooltipPosition="below">close</mat-icon>
            </div>
            <div class="select">
              <mat-checkbox   matTooltip="Seleccionar" formControlName="IsSelectedCheckInquilino"></mat-checkbox>
            </div>
          </div>
        </div>
      </div>





      <div class="col-lg-4">
        <app-buscar-persona-propietaria *ngIf="!propietario"
          (eventEntity)="selectPropietario($event)" [showDocumento]="false">
        </app-buscar-persona-propietaria>

        <div *ngIf="propietario" class="propietario-info" [ngClass]="{'selected': movimientoForm.get('IsSelectedCheckPropietario')?.value}">

          <span><b>Propietario: </b>{{isFalsy(propietario) ? "" :propietario.descripcion | titlecase}}</span>

          <div class="actions-container">
            <div class="close">
              <mat-icon mat-fab *ngIf="!esMovimientoContrato"   color="warn" (click)="selectPropietario(null)" matTooltip="Quitar"
                matTooltipPosition="below">close</mat-icon>
            </div>
            <div class="select">
              <mat-checkbox   matTooltip="Seleccionar"
              formControlName="IsSelectedCheckPropietario"></mat-checkbox>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <app-buscar-propiedad *ngIf="!propiedad" (eventEntity)="selectPropiedad($event)"></app-buscar-propiedad>
        <div *ngIf="propiedad" class="propiedad-info" [ngClass]="{'selected': movimientoForm.get('IsSelectedCheckPropiedad')?.value}">
          <span><b>Propiedad: </b>{{isFalsy(propiedad) ? "": propiedad.descripcion | titlecase}}</span>

          <div class="actions-container">
            <div class="close">
              <mat-icon mat-fab *ngIf="!esMovimientoContrato"  color="warn" (click)="selectPropiedad(null)" matTooltip="Quitar"
                matTooltipPosition="below">close</mat-icon>
            </div>
            <div class="select">
              <mat-checkbox   matTooltip="Seleccionar" formControlName="IsSelectedCheckPropiedad"></mat-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-lg-12">
        <mat-form-field class="full-width-x100">
          <mat-label>Descripción</mat-label>
          <textarea matInput #message name="Descripcion" formControlName="Descripcion"></textarea>
          <mat-error *ngIf="f.Descripcion.errors?.maxlength">Supero el maximo de caracteres.</mat-error>
          <mat-hint align="start"><strong>Amplie información</strong> </mat-hint>
          <mat-hint align="end">{{message.value.length}} / 2000</mat-hint>
        </mat-form-field>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-lg-12">
        <button mat-button mat-dialog-close color="warn" (click)="closeDialog()">Cancelar</button>
        <button mat-raised-button color="primary" *appRole="['Movimientos.Crear']" [disabled]="!movimientoForm.valid || !isAnySelected()"
          (click)="onSubmit()">Agregar</button>
      </div>
    </div>

  </form>
</div>
