<app-acciones-contrato [idContrato]="contratoDetailDto.id"
  [contratoDetailDto]="contratoDetailDto"></app-acciones-contrato>
<mat-tab-group animationDuration="0ms">

  <!-- Detalle/Seguimiento -->
  <mat-tab class="position-relative">
    <ngx-spinner [zIndex]=100 name="spContrato" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688"
      type="ball-atom" [fullScreen]="false">
    </ngx-spinner>
    <ng-template mat-tab-label>
      <mat-icon class="example-tab-icon">zoom_in </mat-icon> &nbsp; Detalle/Seguimiento
    </ng-template>
    <form [formGroup]="detalleMontosContratorFormGroup" class="container-mat-card">
      <ng-template matStepLabel>Detalle Montos contrato</ng-template>
      <div class="row">
        <div class="col-lg-7">
          <mat-card *ngIf="contratoDetailDto">
            <mat-card-content>
              <div class="row">
                <div class="col-10">
                  <h2>Contrato nro.: {{contratoDetailDto.numeroContrato}}</h2>
                </div>
                <div class="col-2">
                  <h2 matTooltip="Estado del contrato.">
                    <div class="badge bg-success text-light"
                      *ngIf="contratoDetailDto.estadoContrato.descripcion == 'Activo'">
                      {{isFalsy(contratoDetailDto.estadoContrato) ? "" : contratoDetailDto.estadoContrato.descripcion}}
                    </div>
                    <div class="badge bg-danger text-light"
                      *ngIf="contratoDetailDto.estadoContrato.descripcion == 'Finalizado'">
                      {{isFalsy(contratoDetailDto.estadoContrato) ? "" :contratoDetailDto.estadoContrato.descripcion}}
                    </div>
                    <div class="badge bg-danger text-light"
                      *ngIf="contratoDetailDto.estadoContrato.descripcion == 'Rescindido'">
                      {{isFalsy(contratoDetailDto.estadoContrato) ? "" :contratoDetailDto.estadoContrato.descripcion}}
                    </div>
                  </h2>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div class="row">
                    <div class="col-12 montoscontrato">
                      <mat-label><b>Tipo Contrato: </b></mat-label><span>{{isFalsy(contratoDetailDto.tipoContrato) ? ""
                        : contratoDetailDto.tipoContrato.descripcion}}</span>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="row">
                    <div class="col-12 montoscontrato">
                      <mat-label><b>Fecha Comienzo: </b></mat-label><span
                        class="badge bg-success text-light">{{contratoDetailDto.fechaComienzo |
                        date:('dd/MM/yyyy')}}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">

                <div class="col-6">
                  <div class="row">
                    <div class="col-12 montoscontrato">
                      <mat-label><b>Dia Vto. Pago: </b></mat-label><span>{{contratoDetailDto.diaVencimientoPago}}</span>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="row">
                    <div class="col-12 montoscontrato">
                      <mat-label><b>Fecha Finalización: </b></mat-label><span
                        class="badge bg-danger text-light">{{contratoDetailDto.fechaVencimiento |
                        date:('dd/MM/yyyy')}}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div class="row">

                    <div class="col-12">
                      <mat-label><b>Tipo Ajuste:</b>
                      </mat-label><span> {{contratoDetailDto.tipoAjusteContrato?.descripcion}}
                        ({{contratoDetailDto.tipoAjusteContrato?.acronimo}}) </span>
                    </div>
                  </div>
                </div>
                <div class="col-6" *ngIf="contratoDetailDto.fechaResicion != '' ">
                  <div class="row">
                    <div class="col-12 montoscontrato">
                      <mat-label><b>Fecha Rescisión: </b></mat-label><span
                        class="badge bg-danger text-light">{{contratoDetailDto.fechaResicion |
                        date:('dd/MM/yyyy')}}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div class="row">
                    <div class="col-12 montoscontrato">
                      <mat-label><b>Frecuencia ajuste:</b>
                      </mat-label><span> {{contratoDetailDto.frecuenciaAjusteMensual}} <span
                          *ngIf="contratoDetailDto.frecuenciaAjusteMensual">meses</span></span>
                    </div>
                  </div>
                </div>
                <div class="col-6">

                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
        <div class="col-lg-5">
          <mat-card>
            <mat-card-content>
              <div class="row">
                <ngx-spinner [zIndex]="1" name="spInquilino" bdColor="rgba(255,255,255,0.8)" size="small"
                  color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
                <div class="col-12">
                  <mat-label><b>Inquilino:</b></mat-label> <span> {{personaInquilino.nombreCompleto | titlecase}}
                    <button mat-icon-button matTooltip="Editar inquilino" *ngIf="personaInquilino.nombreCompleto != ''"
                      (click)="editarPersona(personaInquilino.id)" color="primary">
                      <mat-icon>rate_review</mat-icon>
                    </button></span>
                </div>
              </div>
              <div class="row">
                <div class="col-12 montoscontrato">
                  <ngx-spinner [zIndex]="1" name="spDatosPropietarios" bdColor="rgba(255,255,255,0.8)" size="small"
                    color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
                  <mat-label><b>Propietario: </b>
                    <span
                      *ngFor="let propietario of propietariosPropiedad;let i=index;trackBy:trackByItems">{{propietario.persona.descripcion}}
                      <button mat-icon-button *ngIf="propietariosPropiedad.length > 0"
                        (click)="editarPersona(propietario.persona.id)" matTooltip="Editar propietario" color="primary">
                        <mat-icon>rate_review</mat-icon>
                      </button>
                    </span>
                  </mat-label>

                </div>
              </div>
              <div class="row">
                <div class="col-12 montoscontrato">
                  <ngx-spinner [zIndex]="1" name="spDatosPropiedad" bdColor="rgba(255,255,255,0.8)" size="small"
                    color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
                  <mat-label><b>Propiedad: </b></mat-label> <span>{{propiedad.ubicacion | titlecase}},&nbsp;
                    {{propiedad.localidad != undefined ? propiedad.localidad.descripcion: "" | titlecase}}
                    <button mat-icon-button matTooltip="Editar propiedad" *ngIf="propiedad.ubicacion != ''"
                      (click)="editarPropiedad(propiedad.id)" color="primary">
                      <mat-icon>rate_review</mat-icon>
                    </button></span>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </form>
  </mat-tab>

  <!-- Propiedad -->
  <mat-tab class="position-relative">
    <ngx-spinner [zIndex]=100 name="spDatosPropiedad" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688"
      type="ball-atom" [fullScreen]="false">
    </ngx-spinner>
    <ng-template mat-tab-label>
      <mat-icon class="example-tab-icon">roofing </mat-icon>&nbsp; Propiedad
    </ng-template>

    <div class="container-mat-card">
      <ng-template matStepLabel>Datos propiedad</ng-template>
      <div class="row" *ngIf="propiedad">
        <div class="col-6">
          <mat-card>

            <mat-card-subtitle>Información Propiedad</mat-card-subtitle>
            <mat-card-content>

              <div class="row">
                <div class="col-6" *ngIf="propiedad.estadoPropiedad">
                  <mat-label><b>Estado: </b></mat-label><span>{{isFalsy(propiedad.estadoPropiedad) ? "" :
                    propiedad.estadoPropiedad.descripcion| titlecase}}</span>
                </div>
                <div class="col-6">
                  <mat-label><b>Fecha alta: </b></mat-label> <span>{{propiedad.fechaAlta | date:'dd/MM/yyyy'}}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-6" *ngIf="propiedad.tipoPropiedad">
                  <mat-label><b>Tipo: </b></mat-label><span>{{isFalsy(propiedad.tipoPropiedad) ? "":
                    propiedad.tipoPropiedad.descripcion | titlecase}}</span>
                </div>
                <div class="col-6" *ngIf="propiedad.subtipoPropiedad">
                  <mat-label><b>Subtipo: </b></mat-label> <span>{{isFalsy(propiedad.subtipoPropiedad) ? "":
                    propiedad.subtipoPropiedad.descripcion| titlecase}}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <mat-label><b>Localidad: </b></mat-label><span>{{isFalsy(propiedad.localidad) ? "":
                    propiedad.localidad.descripcion| titlecase}}</span>
                </div>
                <div class="col-6" *ngIf="propiedad.estadoPropiedad">
                  <mat-label><b>Ubicación: </b></mat-label>
                  {{propiedad.ubicacion | titlecase}}
                  <span *ngIf="propiedad.piso">{{propiedad.piso | uppercase}}</span>
                  <span> </span>
                  <span *ngIf="propiedad.departamento"> {{isFalsy(propiedad.departamento)? "" : propiedad.departamento |
                    uppercase}}</span>

                  <span>
                    <button matSuffix mat-icon-button matTooltip="Editar propiedad" *ngIf="propiedad.ubicacion!= ''"
                      (click)="editarPropiedad(propiedad.id)" color="primary">
                      <mat-icon>rate_review</mat-icon>
                    </button></span>

                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
        <div class="col-6">
          <mat-card>
            <ngx-spinner [zIndex]=100 name="spDatosPropietarios" bdColor="rgba(255,255,255,0.8)" size="default"
              color="#009688" type="ball-atom" [fullScreen]="false">
            </ngx-spinner>
            <mat-card-subtitle>Información Propietarios</mat-card-subtitle>
            <mat-card-content *ngFor="let propietario of propietariosPropiedad ;let i=index;trackBy:trackByItems">
              <mat-accordion class="example-headers-align">
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <span>{{propietario.persona.descripcion | titlecase}}
                        <button mat-icon-button matTooltip="Editar persona"
                          *ngIf="propietario.persona.descripcion != ''"
                          (click)="editarPersona(propietario.persona.id)" color="primary">
                          <mat-icon>rate_review</mat-icon>
                        </button></span>
                    </mat-panel-title>
                    <mat-panel-description>
                      Propietario titular
                      <mat-icon *ngIf="propietario.titular == true">account_circle</mat-icon>
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <div class="row" *ngIf="propietario.titular == true">
                    <div class="col-5">
                      <div class="row">
                        <div class="col-12 montoscontrato">
                          <mat-label><b>Comisión: </b></mat-label>
                          <span>{{isFalsy(contratoDetailDto.porcentajeComisionPropietario) ? "" :
                            contratoDetailDto.porcentajeComisionPropietario}}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 montoscontrato">
                          <mat-label><b>Forma de Pago: </b></mat-label>
                          <span>{{isFalsy(contratoDetailDto.formaPagoPropietario) ? "":
                            contratoDetailDto.formaPagoPropietario.descripcion}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-7">
                      <div class="row">
                        <div class="col-12 montoscontrato">
                          <mat-label><b>Tipo liquidación: </b></mat-label>
                          <span>{{isFalsy(contratoDetailDto.tipoLiquidacion.descripcion) ? "" :
                            contratoDetailDto.tipoLiquidacion.descripcion | titlecase}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </mat-card-content>
            <mat-card-content *ngIf="propietariosPropiedad.length == 0">
              <span style="color: red;">Esta propiedad no tiene propietario!</span>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
  </mat-tab>

  <!-- Inquilino -->
  <mat-tab class="position-relative">
    <ngx-spinner [zIndex]=100 name="spInquilino" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688"
      type="ball-atom" [fullScreen]="false">
    </ngx-spinner>
    <ng-template mat-tab-label>
      <mat-icon class="example-tab-icon">vpn_key </mat-icon>&nbsp; Inquilino
    </ng-template>

    <div class="container-mat-card">
      <ng-template matStepLabel>Datos inquilino</ng-template>

      <div class="row">
        <div class="col-lg-7">
          <mat-card>
            <mat-card-subtitle>Información Inquilino</mat-card-subtitle>
            <mat-card-content *ngIf="personaInquilino">
              <div class="row">
                <div class="col-6">

                  <mat-label><b>Correo: </b></mat-label> <a *ngIf="personaInquilino.correo"
                    href="mailto:{{personaInquilino.correo | titlecase}}">
                    {{personaInquilino.correo}} </a>
                </div>
                <div class="col-6">
                  <mat-label><b>Nro. Documento: </b></mat-label><span>{{personaInquilino.nroDocumento}}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <mat-label><b>Telefono principal: </b></mat-label>
                  <a *ngIf="personaInquilino.telefonoPrincial" href="tel:+54{{personaInquilino.telefonoPrincial}}">
                    <mat-icon inline>call</mat-icon>+54{{personaInquilino.telefonoPrincial}}
                  </a>
                </div>
                <div class="col-6">
                  <mat-label><b>Telefono alternativo: </b></mat-label> <a *ngIf="personaInquilino.telefonoAlternativo"
                    href="tel:+54{{personaInquilino.telefonoAlternativo}}">
                    +54{{personaInquilino.telefonoAlternativo}} </a>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <mat-label><b>Nombre: </b></mat-label> {{personaInquilino.nombreCompleto | titlecase}}
                  <button mat-icon-button matTooltip="Editar persona" *ngIf="personaInquilino.nombreCompleto!= ''"
                    (click)="editarPersona(personaInquilino.id)" color="primary">
                    <mat-icon>rate_review</mat-icon>
                  </button>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
        <div class="col-lg-5">
          <mat-card>
            <ngx-spinner [zIndex]=100 name="spGarantia" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688"
              type="ball-atom" [fullScreen]="false">
            </ngx-spinner>
            <mat-card-subtitle>Información Garantias</mat-card-subtitle>
            <mat-card-content *ngIf="tieneGarantias">
              <mat-card-content *ngFor="let garantia of contratoDetailDto.garantias;let i=index;trackBy:trackByItems">
                <mat-accordion class="example-headers-align">
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <span>{{garantia.persona.descripcion | titlecase}}
                          <button mat-icon-button matTooltip="Editar persona" *ngIf="garantia.persona.descripcion != ''"
                            (click)="editarPersona(garantia.persona.id)" color="primary">
                            <mat-icon>rate_review</mat-icon>
                          </button></span>
                      </mat-panel-title>
                      <mat-panel-description>
                      </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div class="row">
                      <div class="col-6">
                        <mat-label><b>Tipo garantia: </b></mat-label><span>{{garantia.tipoGarantia.descripcion |
                          titlecase}}</span>
                      </div>
                      <div class="col-6">
                        <mat-label><b>Estado: </b></mat-label> <span class="badge text-white"
                          [ngClass]="{'bg-success': garantia.activo==true,'bg-danger': garantia.activo!=true }">{{garantia.activo
                          == true ? 'Activo': "Inactivo" }}</span>

                      </div>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>
              </mat-card-content>
              <mat-card-content *ngIf="contratoDetailDto.garantias.length == 0">
                <div class="no-data-table">
                  <span style="color: red;" class="with-icon">
                    <mat-icon>warning_amber</mat-icon>Este inquilino no tiene garantias o no estan activas.
                  </span>
                </div>
              </mat-card-content>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
  </mat-tab>

  <!-- Montos Contrato -->
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="example-tab-icon">attach_money</mat-icon>&nbsp; Montos Contrato
    </ng-template>

    <div class="container-mat-card">
      <ng-template matStepLabel>Datos inquilino</ng-template>

      <div class="row">

        <ngx-spinner [zIndex]=100 name="spContrato" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688"
          type="ball-atom" [fullScreen]="false">
        </ngx-spinner>
        <div class="col-lg-4">
          <mat-card>
            <mat-card-subtitle><b>Montos Honorarios</b></mat-card-subtitle>
            <mat-card-content>
              <div class="row">
                <div class="col">
                  <mat-label><b>Monto: </b> </mat-label><span>{{contratoDetailDto.montoAdministrativo}}</span>
                </div>
              </div>
              <div class="row">
                <div class="col" *ngIf="contratoDetailDto.tipoMontoAdministrativo">
                  <mat-label><b>Tipo Monto: </b></mat-label>
                  <span>{{contratoDetailDto.tipoMontoAdministrativo.descripcion}}</span>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <mat-label><b>Plan de Pagos: </b></mat-label><span>{{contratoDetailDto.planDePago}}</span>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
        <div class="col-lg-4">
          <mat-card>
            <mat-card-subtitle><b>Montos Punitorios</b></mat-card-subtitle>
            <mat-card-content>
              <div class="row">
                <div class="col" *ngIf="contratoDetailDto.tipoPunitorio">
                  <mat-label><b>Tipo Punitorio: </b></mat-label>
                  <span>{{contratoDetailDto.tipoPunitorio.descripcion}}</span>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <mat-label><b>Punitorios (por dia): </b></mat-label><span>{{contratoDetailDto.montoPunitorio}}</span>
                </div>
              </div>
              <div class="row">
                <div class="col" *ngIf="contratoDetailDto.tipoMontoPunitorio">
                  <mat-label><b>Tipo Monto: </b></mat-label>
                  <span>{{contratoDetailDto.tipoMontoPunitorio.descripcion}}</span>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
        <div class="col-lg-4">
          <mat-card>
            <mat-card-subtitle><b>Montos Sellado</b></mat-card-subtitle>
            <mat-card-content>
              <div class="row">
                <div class="col">
                  <mat-label><b>Tipo Sellado: </b></mat-label><span>{{contratoDetailDto.tipoSellado.descripcion}}</span>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <mat-label><b>Monto: </b></mat-label> <span>{{contratoDetailDto.montoSellado}}</span>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <mat-label><b>Tipo Monto: </b></mat-label>
                  <span>{{contratoDetailDto.tipoMontoSellado.descripcion}}</span>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
  </mat-tab>

  <!-- Detalle monto Contrato -->
  <mat-tab>
    <form [formGroup]="detalleMontosContratorFormGroup" class="container-mat-card">
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">account_balance </mat-icon>&nbsp; Detalle Montos Contrato
      </ng-template>

      <div class="row">
        <div class="col-12">
          <button mat-raised-button class="btn-margin" *appRole="['Contratos.Modificar']" (click)="openDialogNuevoPeriodoComponent(false)"
            color="primary" [disabled]="contratoDetailDto.estadoContrato.id != 1 || (contratoDetailDto.estadoContrato.id == 1 && contratoDetailDto.tipoAjusteContrato.id != 1)">
            <mat-icon>add</mat-icon>
            Cargar nuevos periodos
          </button>
          <button mat-stroked-button class="btn-margin" *appRole="['Contratos.Adendar']" (click)="openDialogAdendaContratoComponent(true)"
            color="primary" [disabled]="contratoDetailDto.estadoContrato.id != 1">
            <mat-icon>next_plan</mat-icon>
            Adendar contrato

          </button>

          <button mat-stroked-button class="btn-margin" matTooltip="Recargar periodos" (click)="getPeriodosContratoByIdContrato(contratoDetailDto.id)"
            color="primary" [disabled]="contratoDetailDto.estadoContrato.id != 1">
            <mat-icon>sync</mat-icon>
          </button>
          <br>
          <br>
          <div class="row">
            <div class="col-12">
              <mat-accordion class="example-headers-align">
                <ngx-spinner name="spPeriodosConstrato" [zIndex]=100 bdColor="rgba(255,255,255,0.8)" size="default"
                  color="#009688" type="ball-atom" [fullScreen]="false">
                </ngx-spinner>
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      Detalle Montos Contrato
                    </mat-panel-title>
                    <mat-panel-description>
                      Detalle de montos
                    </mat-panel-description>
                  </mat-expansion-panel-header>

                  <table mat-table [dataSource]="dataSource" matSort [hidden]=!data class="mat-elevation-z8">
                    <ng-container matColumnDef="periodo">
                      <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Periodo </mat-header-cell>
                      <mat-cell mat-cell *matCellDef="let periodo"> {{periodo.periodo}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="periodoFecha">
                      <mat-header-cell mat-header-cell *matHeaderCellDef> Fecha </mat-header-cell>
                      <mat-cell mat-cell *matCellDef="let periodo"> {{periodo.periodoMes}}/{{periodo.periodoAnio}}
                      </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="fechaPago">
                      <mat-header-cell mat-header-cell *matHeaderCellDef> Fecha Pago </mat-header-cell>
                      <mat-cell mat-cell *matCellDef="let periodo"> {{periodo.fechaPago == "0001-01-01T00:00:00" ? "":
                        periodo.fechaPago | date:"dd/MM/yyyy"}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="fechaLiquidacion">
                      <mat-header-cell mat-header-cell *matHeaderCellDef> Fecha Liquidación </mat-header-cell>
                      <mat-cell mat-cell *matCellDef="let periodo"> {{periodo.fechaLiquidacion == "0001-01-01T00:00:00"
                        ? "": periodo.fechaLiquidacion | date:"dd/MM/yyyy"}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="fechaAjuste">
                      <mat-header-cell mat-header-cell *matHeaderCellDef> Fecha Ajuste </mat-header-cell>
                      <mat-cell mat-cell *matCellDef="let periodo"> {{periodo.fechaAjuste == "0001-01-01T00:00:00"
                        ? "": periodo.fechaAjuste | date:"dd/MM/yyyy"}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="importe">
                      <mat-header-cell mat-header-cell *matHeaderCellDef> Impote </mat-header-cell>
                      <mat-cell mat-cell *matCellDef="let periodo"> {{periodo.importeAlquiler | currency}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="indiceAjusteContrato">
                      <mat-header-cell mat-header-cell *matHeaderCellDef> Tipo Ajuste </mat-header-cell>
                      <mat-cell mat-cell *matCellDef="let periodo"><span [matTooltip]="periodo.indiceAjusteContrato?.tipoAjusteContrato.descripcion | titlecase ">
                        {{periodo.indiceAjusteContrato?.tipoAjusteContrato.acronimo | uppercase}}
                      </span> </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="valorAjuste">
                      <mat-header-cell mat-header-cell *matHeaderCellDef> Valor Ajuste </mat-header-cell>
                      <mat-cell mat-cell *matCellDef="let periodo"><span>
                        {{periodo.valorAjuste | number:'1.3-3'}}
                      </span> </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="ajusteProcesado">
                      <mat-header-cell mat-header-cell *matHeaderCellDef>Procesado </mat-header-cell>
                      <mat-cell mat-cell *matCellDef="let periodo">
                        <mat-icon color="primary" *ngIf="periodo.ajusteProcesado == true">check_circle_outline</mat-icon>
                        <mat-icon color="warn" *ngIf="periodo.ajusteProcesado == false">highlight_off</mat-icon>
                      </mat-cell>
                    </ng-container>



                    <!-- Action Column -->
                    <ng-container matColumnDef="accion">
                      <mat-header-cell mat-header-cell *matHeaderCellDef> Acción </mat-header-cell>
                      <mat-cell mat-cell *matCellDef="let periodo" class="action-link">
                        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                          <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                          <button mat-menu-item (click)="openDialog(periodo)">
                            <mat-icon>visibility</mat-icon>
                            <span>Ver</span>
                          </button>
                          <button mat-menu-item (click)="eliminarPeriodo(periodo)">
                            <mat-icon>delete</mat-icon>
                            <span>Eliminar</span>
                          </button>
                        </mat-menu>
                      </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></mat-row>


                  </table>
                  <div class="no-data-table">
                    <span class="with-icon" *ngIf="dataSource.data.length == 0">
                      <mat-icon>search_off</mat-icon>No se encontraron datos para mostrar.
                    </span>
                  </div>
                  <mat-paginator showFirstLastButtons class="paginator" [pageSize]="50"
                    [pageSizeOptions]="[5, 10, 20,50,100,200]">
                  </mat-paginator>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </div>
          <br>
          <div class="row">
            <div class="col-6">
              <h2>Inicio contrato</h2>
              <div class="row">
                <div class="col-lg-3">
                  <span>Total Alquiler:</span>
                </div>
                <div class="col-lg-3">
                  <span>{{calcularTotalesIniciales('montoTotalContrato') | currency}}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-3">
                  <span>Comision:
                  </span>

                </div>
                <div class="col-lg-3">
                  <span>{{calcularTotalesIniciales('montoTotalComision') | currency}}
                    <mat-icon inline color="primary"
                      matTooltip="Comision calculada al inicio de contrato.">info</mat-icon>
                  </span>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-3">
                  <span>Sellado:
                  </span>

                </div>
                <div class="col-lg-3">
                  <span>{{calcularTotalesIniciales('montoTotalSellado') | currency}}
                    <mat-icon inline color="primary"
                      matTooltip="Sellado calculado al inicio de contrato.">info</mat-icon>
                  </span>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-3">
                  <h2>Total</h2>
                </div>
                <div class="col-lg-3">
                  <h2>{{calcularTotalesIniciales('montoTotal') | currency}}</h2>
                </div>
              </div>
            </div>
            <div class="col-6">
              <h2>Ultima actualización </h2>
              <div class="row">
                <div class="col-lg-3">
                  <span>Total Alquiler:</span>
                </div>
                <div class="col-lg-3">
                  <span>{{totalMontoAlquiler | currency}}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-3">
                  <span>Comision:
                  </span>

                </div>
                <div class="col-lg-3">
                  <span>{{totalComision | currency}}
                    <mat-icon inline color="primary" matTooltip="Ultima comision calculada.">info</mat-icon>
                  </span>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-3">
                  <span>Sellado:
                  </span>

                </div>
                <div class="col-lg-3">
                  <span>{{totalSellado | currency}}
                    <mat-icon inline color="primary" matTooltip="Ultimo sellado calculado.">info</mat-icon>
                  </span>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-3">
                  <h2>Total</h2>
                </div>
                <div class="col-lg-3">
                  <h2>{{total | currency}}</h2>
                </div>
              </div>
            </div>
          </div>

          <mat-divider></mat-divider>
          <br>
          <div class="row">
            <div class="col-lg-3" matTooltip="Descuentos desde el primer periodo.">
              <span>Total Descuentos:</span>
            </div>
            <div class="col-lg-3">
              <span> {{totalDescuento | currency}}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-3" matTooltip="Incrementos desde el primer periodo.">
              <span>Total Incrementos:</span>
            </div>
            <div class="col-lg-3">
              <span>{{totalIncrementos | currency}}</span>
            </div>
          </div>

        </div>
      </div>
    </form>
  </mat-tab>

</mat-tab-group>
